import React, { useState, useEffect, useContext } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Messages from "../../components/Messages/Messages"
import "video-react/dist/video-react.css"
import DynamicText from "../../components/TextComponents/DynamicText"
import SubmitReviewPopUp from "../../components/PopUps/SubmitReviewPopUp"
import { NotificationContext } from "../../contexts/notificationContext"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../@shadcn_components/ui/breadcrumb"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"
import { toJS } from "mobx"
import { ClockIcon, CalendarIcon, UserIcon } from "@heroicons/react/20/solid"
import ModuleAnalysisPageSkeleton from "../../components/ModuleAnalysisPage/skeletons"
import { GenericAPIRequest } from "../../api/helpers"

const ModuleAnalysisPage = () => {
  const navigate = useNavigate()
  const { triggerNotification } = useContext(NotificationContext)
  const { userStore, moduleListStoreV2, attemptListStore } = useStores()
  const { company, type, userId } = userStore.user || {}
  const { moduleId, attemptId, attemptedUserId } = useParams()
  const [videoUrl, setVideoUrl] = useState(null)
  const [viewType, setViewType] = useState("Transcript")
  const [searchBarInput, setSearchBarInput] = useState("")
  const [talkTrackPos, setTalkTrackPos] = useState(0)
  const [showSubmitReviewPopUp, setShowSubmitReviewPopUp] = useState(false)
  const [popUpLoading, setPopUpLoading] = useState(false)
  const [comments, setComments] = useState("")
  const [reviewSuccess, setReviewSuccess] = useState(true)
  const [reviewSubmited, setReviewSubmited] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)
  const [currentUserAttemptInfo, setCurrentUserAttemptInfo] = useState({
    conversationStart: 0,
    attemptDate: "",
    aiReport: "",
    rubricScore: "",
  })
  const [transcript, setTranscript] = useState([])
  const isManagerOrAdmin = type === "Manager" || type === "Admin"

  const moduleInfo = toJS(moduleListStoreV2.getModuleById(moduleId)) || {}

  useEffect(() => {
    const fetchModule = async () => {
      if (!moduleListStoreV2.getModuleById(moduleId)) {
        await moduleListStoreV2.getModule(company, moduleId, userId)
      }
    }

    const fetchAttempt = async () => {
      const currAttempt = attemptListStore.getSingleAttempt(attemptId)
      if (!currAttempt) {
        const fetchedAttempt = await attemptListStore.getSingleUserAttempt(moduleId, attemptedUserId, attemptId, userId)
        setCurrentUserAttemptInfo({
          attemptTime: fetchedAttempt.attemptTime,
          comments: fetchedAttempt.comments,
          displayName: fetchedAttempt.displayName,
          duration: fetchedAttempt.duration,
          gradingreport: fetchedAttempt.gradingreport,
          score: fetchedAttempt.score,
          status: fetchedAttempt.status,
          conversationStart: Date.parse(fetchedAttempt.transcript[0].start),
          attemptDate: new Date(fetchedAttempt.attemptTime).toDateString(),
          aiReport: fetchedAttempt.gradingreport.replace(/\|\|.*\|\|\n/gm, ""),
          rubricScore: fetchedAttempt.score,
        })
        setTranscript(fetchedAttempt.transcript)
        setVideoUrl(fetchedAttempt.videoUrl)
      } else {
        const fetchedTranscript = await attemptListStore.getAttemptTranscript(
          moduleId,
          attemptedUserId,
          attemptId,
          userId,
        )
        setCurrentUserAttemptInfo({
          ...currAttempt,
          conversationStart: Date.parse(fetchedTranscript[0].start),
          attemptDate: new Date(currAttempt.attemptTime).toDateString(),
          aiReport: currAttempt.gradingreport.replace(/\|\|.*\|\|\n/gm, ""),
          rubricScore: currAttempt.score,
        })
        setTranscript(fetchedTranscript)
        setVideoUrl(currAttempt.videoUrl)
      }
    }

    const fetchData = async () => {
      try {
        // Fetch only if necessary
        await Promise.all([fetchModule(), fetchAttempt()])
      } catch (error) {
        console.error("Error fetching data:", error)
      } finally {
        setPageLoading(false)
      }
    }
    fetchData()
  }, [])

  const fancyTimeFormat = duration => {
    const hrs = ~~(duration / 3600)
    const mins = ~~((duration % 3600) / 60)
    const secs = ~~duration % 60

    let ret = ""

    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "")
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "")
    ret += "" + secs

    return ret
  }

  const handleCommentChange = e => {
    setComments(e.target.value)
  }

  const handleSearchBarChange = e => {
    e.preventDefault()
    setSearchBarInput(e.target.value)
  }
  const submitReview = async () => {
    setPopUpLoading(true)
    try {
      await GenericAPIRequest(
        "ZenoApp",
        "post",
        "/submitReview",
        {},
        {},
        {
          user_id: userId,
          passed: reviewSuccess,
          comments,
          attemptedUserId,
          attemptId,
          moduleId,
        },
      )
      const status = reviewSuccess ? "Passed" : "Not Passed"
      attemptListStore.updateAttemptStatus(attemptId, status, comments)
      setReviewSubmited(true)
      triggerNotification("success", "Review submitted succesfully")
    } catch (e) {
      console.error("Error fetching usage data:", e)
      triggerNotification("error", "There was an error when submitting the review")
    } finally {
      setPopUpLoading(false)
      setShowSubmitReviewPopUp(false)
    }
  }

  const handleTimeUpdate = event => {
    setTalkTrackPos(event.target.currentTime)
  }

  const exitPopUp = () => {
    setShowSubmitReviewPopUp(false)
  }

  const submitReviewPopUp = showSubmitReviewPopUp ? (
    <div className={`frostedBackground is-visible`}>
      <SubmitReviewPopUp
        header="Submit Review"
        body1="Select whether the rep passed this module or try again"
        body2="Leave some feedback for your rep on what influenced your decision"
        rightButtonFunction={popUpLoading ? null : submitReview}
        rightButtonLabel={popUpLoading ? "Submitting..." : "Submit"}
        leftButtonFunction={exitPopUp}
        leftButtonLabel="Cancel"
        comments={comments}
        handleCommentChange={handleCommentChange}
        setReviewSuccess={setReviewSuccess}
        reviewSuccess={reviewSuccess}></SubmitReviewPopUp>
    </div>
  ) : null

  const handleSubmitReviewButtonClick = () => {
    setShowSubmitReviewPopUp(true)
  }

  const pageName = isManagerOrAdmin ? `${currentUserAttemptInfo.displayName}'s Attempt` : "My Attempt"
  const breadCrumbView = (
    <div className="flex flex-col items-start">
      <div className="mb-2">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href={"/inboxPage"}>Assignments</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink href={`/assignment/${moduleId}`}>{`${moduleInfo.displayName}`}</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{pageName}</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <div className="w-full flex flex-row justify-between items-center mb-4">
        <h1 className="text-2xl font-semibold">{moduleInfo.displayName}</h1>
        {isManagerOrAdmin && currentUserAttemptInfo.status === "Pending Review" && !reviewSubmited ? (
          <button
            type="button"
            onClick={handleSubmitReviewButtonClick}
            className="rounded-md bg-pareBlue px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            Start review
          </button>
        ) : isManagerOrAdmin ? (
          <button
            type="button"
            disabled
            className="rounded-md bg-gray-200 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            Review submitted
          </button>
        ) : null}
      </div>
    </div>
  )

  const cardsView = (
    <div className="flex mb-4">
      {isManagerOrAdmin ? (
        <div className="px-2 py-1 font-roboto flex font-medium text-black border border-gray-300 rounded-md mr-1 justify-center items-center">
          <UserIcon aria-hidden="true" className="h-6 w-6 mr-1 cursor-pointer" />
          <label>{currentUserAttemptInfo.displayName}</label>
        </div>
      ) : null}
      <div className="px-2 py-1 font-roboto flex font-medium text-black border border-gray-300 rounded-md mr-1 justify-center items-center">
        <CalendarIcon aria-hidden="true" className="h-6 w-6 mr-1 cursor-pointer" />
        <label>{currentUserAttemptInfo.attemptDate}</label>
      </div>
      <div className="px-2 py-1 font-roboto flex font-medium text-black border border-gray-300 rounded-md mr-1 justify-center items-center">
        <ClockIcon aria-hidden="true" className="h-6 w-6 mr-1 cursor-pointer" />
        <label>{fancyTimeFormat(currentUserAttemptInfo.duration)}</label>
      </div>
    </div>
  )

  const tabNames = [
    { name: "Transcript", key: "Transcript" },
    { name: "Scoring", key: "Scoring" },
  ]

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
  }

  const tabs = (
    <header className="border-b border-gray-200 px-6 mb-3">
      <div className="mt-3 sm:mt-4">
        {/* Mobile dropdown */}
        <div className="sm:hidden">
          <label htmlFor="current-tab" className="sr-only">
            Select a tab
          </label>
          <select
            id="current-tab"
            name="current-tab"
            defaultValue={tabNames.find(tab => tab.key === viewType).name}
            onChange={e => setViewType(tabNames.find(tab => tab.name === e.target.value).key)}
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
            {tabNames.map(tab => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>

        {/* Desktop tabs */}
        <div className="hidden sm:block">
          <nav className="-mb-px flex space-x-8">
            {tabNames.map(item => (
              <button
                key={item.name}
                onClick={() => setViewType(item.key)}
                aria-current={viewType === item.key ? "page" : undefined}
                className={classNames(
                  viewType === item.key
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium",
                )}>
                {item.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </header>
  )

  const videoView = (
    <div className="flex-1 aspect-[16/9] w-1/2 ">
      <video src={videoUrl} controls height="100%" width="100%" preload="auto" onTimeUpdate={handleTimeUpdate}></video>
    </div>
  )

  const messagesToDisplay = searchBarInput.length
    ? transcript.filter(x => x.text.match(new RegExp(`^.*${searchBarInput}.*`, "i")))
    : transcript.filter(x => x.text.trim() !== "")

  const transcriptView = (
    <div className="flex flex-1  flex-col 2xl:flex-row px-4 overflow-auto">
      <div className="flex flex-col px-2">
        <input
          type="text"
          placeholder="Search"
          className="w-full rounded-sm border-2 border-gray-400"
          value={searchBarInput}
          onChange={handleSearchBarChange}></input>
      </div>
      <div className="flex-1 overflow-auto">
        <Messages messages={messagesToDisplay} name={""} />
      </div>
    </div>
  )

  const heatmapView = (
    <div className="w-1/2 py-10">
      <div className="px-2">
        <p className="text-xl font-semibold">{`${currentUserAttemptInfo.displayName}`}</p>
        <div className="relative h-[2px] bg-gray-500 my-4">
          {transcript
            .filter(x => x.user === "user")
            .map(x => (
              <div
                key={`user-bar-${x.start}`}
                style={{
                  width: `${
                    ((Date.parse(x.end) - Date.parse(x.start)) / 1000 / currentUserAttemptInfo.duration) * 100
                  }%`,
                  height: "10px",
                  background: "#3045ff",
                  position: "absolute",
                  top: "-4px",
                  left: `${
                    ((Date.parse(x.start) - currentUserAttemptInfo.conversationStart) /
                      1000 /
                      currentUserAttemptInfo.duration) *
                    100
                  }%`,
                  borderRadius: "5px",
                }}></div>
            ))}
          <div
            style={{
              width: "3px",
              height: "20px",
              background: "black",
              position: "absolute",
              top: "-10px",
              left: `${
                (talkTrackPos / currentUserAttemptInfo.duration) * 100 <= 100
                  ? (talkTrackPos / currentUserAttemptInfo.duration) * 100
                  : 100
              }%`,
            }}></div>
        </div>
      </div>
      <div className="px-2">
        <p className="text-xl font-semibold">{`Prospect`}</p>
        <div className="relative h-[2px] bg-gray-500 my-4">
          {transcript
            .filter(x => x.user === "bot")
            .map(x => (
              <div
                key={`bot-bar-${x.start}`}
                style={{
                  width: `${
                    ((Date.parse(x.end) - Date.parse(x.start)) / 1000 / currentUserAttemptInfo.duration) * 100
                  }%`,
                  height: "10px",
                  background: "grey",
                  position: "absolute",
                  top: "-4px",
                  left: `${
                    ((Date.parse(x.start) - currentUserAttemptInfo.conversationStart) /
                      1000 /
                      currentUserAttemptInfo.duration) *
                    100
                  }%`,
                  borderRadius: "5px",
                }}></div>
            ))}
          <div
            style={{
              width: "3px",
              height: "20px",
              background: "black",
              position: "absolute",
              top: "-10px",
              left: `${
                (talkTrackPos / currentUserAttemptInfo.duration) * 100 <= 100
                  ? (talkTrackPos / currentUserAttemptInfo.duration) * 100
                  : 100
              }%`,
            }}></div>
        </div>
      </div>
    </div>
  )

  // "Overview" tab
  const overviewTab = (
    <div className="flex-1 flex flex-col">
      <div className="flex flex-row mb-4 h-full">
        {videoView}
        {transcriptView}
      </div>
      {heatmapView}
    </div>
  )

  // "Scoring" tab
  const scoringTab = (
    <div className="flex flex-1  flex-col 2xl:flex-row px-4 overflow-auto">
      <div className="flex flex-col items-center px-10 pt-12 ">
        <p className="text-3xl font-bold mb-3">{currentUserAttemptInfo.rubricScore}</p>
        <div className="text-md text-gray-300">Score</div>
      </div>

      <div className="whitespace-pre-line text-lg 2xl: text-xl text-slate-400">
        <DynamicText content={currentUserAttemptInfo.aiReport}></DynamicText>
      </div>
    </div>
  )

  if (pageLoading) {
    return <ModuleAnalysisPageSkeleton></ModuleAnalysisPageSkeleton>
  }

  return (
    <div className="w-full h-full flex flex-col px-8 2xl:px-20 pt-8">
      {breadCrumbView}
      {cardsView}
      <div class="relative h-4/5 border-t border-gray-200 border pb-6">
        <div class="absolute top-0 left-0 w-full h-1/2 flex-col flex">
          {videoView}
          {heatmapView}
        </div>

        <div class="absolute top-0 right-0 h-full w-1/2 border border-gray-200 flex flex-col">
          {tabs}
          {viewType === "Transcript" ? transcriptView : scoringTab}
        </div>
      </div>
      {submitReviewPopUp}
    </div>
  )
}

export default observer(ModuleAnalysisPage)
