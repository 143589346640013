'use client'

import { useState } from 'react'
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/react'
import {
  Bars3Icon,
  BellIcon
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import Avatar from "../Global/avatar"
import BackButton from "../Buttons/backButton"
import { useNavigate, useLocation } from "react-router-dom"
import { useStores } from "../../models/root-store"


const userNavigation = [
  // { name: 'Your profile', href: '#' },
  { name: 'Sign out' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const pareLogo = require("../../icons/PareLogo_new.png")

export default function AdminConsoleHeader() {

  const { pathname } = useLocation()
  const { userStore } = useStores()
  const { userId, name, company, profPicSignedUrl } = userStore.user || {}
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const navigate = useNavigate()
  const routeTable = {
    "Settings": "/adminConsole/settings",
    "Reporting": "/adminConsole/reporting",
    "Content": "/adminConsole/content"
  }
  const navigation = [
    { name: 'Content', current: routeTable["Content"] === pathname },
    { name: 'Settings', current: routeTable["Settings"] === pathname },
    { name: 'Reporting', current: routeTable["Reporting"] === pathname },
  ]

  const signOutFunction = async () => {
    await userStore.logout()
    navigate("/")
  }

  const handleProfileBannerClick = (option) => {
    if (option === "Sign out") {
      signOutFunction()
    }
  }
  const handleTabSwitch = (tabName) => {
    navigate(routeTable[tabName])
  }

  if (!Object.values(routeTable).includes(pathname)) {
    return <div></div>
  }

  return (
    <div className="sticky top-0 z-10 px-4 w-full h-16">
      <div className="flex h-16 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-0 lg:shadow-none">
        <button
          type="button"
          onClick={() => setSidebarOpen(true)}
          className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon aria-hidden="true" className="size-6" />
        </button>

        {/* Separator */}
        <div aria-hidden="true" className="h-6 w-px bg-gray-200 lg:hidden" />
        <div className="flex h-16 shrink-0 items-center">
          <img
            alt="Your Company"
            src={pareLogo}
            className="h-8 w-auto rounded-xl mr-8"
          />
          <BackButton buttonLabel="Back to home" buttonFunction={() => navigate("/homepage")}></BackButton>
        </div>
        <div className="flex flex-1 justify-center">
          <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8 ">
            {navigation.map((item) => (
              <div
                key={item.name}
                onClick={() => handleTabSwitch(item.name)}
                aria-current={item.current ? 'page' : undefined}
                className={classNames(
                  item.current
                    ? 'border-indigo-500 text-gray-900'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'inline-flex items-center border-b-2 px-1 pt-1 text-md font-medium cursor-pointer',
                )}
              >
                {item.name}
              </div>
            ))}
          </div>
        </div>
        <div className="flex items-center gap-x-4 lg:gap-x-6">
          {/* <button type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
            <span className="sr-only">View notifications</span>
            <BellIcon aria-hidden="true" className="size-6" />
          </button> */}

          {/* Separator */}
          <div aria-hidden="true" className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" />

          {/* Profile dropdown */}
          <Menu as="div" className="relative">
            <MenuButton className="-m-1.5 flex items-center p-1.5">
              <span className="sr-only">Open user menu</span>
              <Avatar name={name} profilePicture={profPicSignedUrl} size="lg" />
              <span className="hidden lg:flex lg:items-center">
                <span aria-hidden="true" className="ml-4 text-sm/6 font-semibold text-gray-900">
                  {name}
                </span>
                <ChevronDownIcon aria-hidden="true" className="ml-2 size-5 text-gray-400" />
              </span>
            </MenuButton>
            <MenuItems
              transition
              className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
              {userNavigation.map((item) => (
                <MenuItem key={item.name}>
                  <div
                    onClick={() => handleProfileBannerClick(item.name)}
                    className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50 data-[focus]:outline-none cursor-pointer"
                  >
                    {item.name}
                  </div>
                </MenuItem>
              ))}
            </MenuItems>
          </Menu>
        </div>
      </div>
    </div>
  )
}

