import React, { useState, useEffect } from 'react'
import UsageChart from "../usageChart"
import UsageSectionSkeleton from "../usageSectionSkeleton"
import { useStores } from "../../../models/root-store"
import { toJS } from "mobx"
import { CalendarDaysIcon } from "@heroicons/react/20/solid"



const UsageSection = ({ userInfo }) => {

  const { usageStore } = useStores()
  const [usageStats, setUsageStats] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {

    const fetchUsageStats = async () => {
      if (!usageStore.usageData) {
        await usageStore.getUsage(userInfo.company, userInfo.userId)
      }
      const usageData = toJS(usageStore.usageData)
      if (usageData) {

        const fullData = fillGaps(usageData.loginDistribution)
        setUsageStats({
          attemptCount: usageData.attemptCount,
          savedAttemptCount: usageData.savedAttemptCount,
          loginDistribution: fullData,
        })
      }
      setLoading(false)
    }

    fetchUsageStats()
  }, [])


  function fillGaps(logins) {

    const gapsNeeded = 90 - logins.length
    if (gapsNeeded > 0) {
      const placeholders = Array.from({ length: gapsNeeded }, () => ({
        timestamp: "N/A",
        value: '0',
      }));

      return [...placeholders, ...logins];
    }

    return logins
  }



  if (loading) {
    return (
      <div className=" max-w-7xl px-4 flex-1 ">
        <div class="flex flex-col items-start justify-between gap-x-8 gap-y-4 py-4 sm:flex-row sm:items-center rounded">
          <div>
            <div class="flex items-center gap-x-3">
              <h1 class="flex gap-x-3 text-lg leading-7">
                <span class="font-semibold text-grey">Usage</span>
              </h1>
            </div>
            <p class="mt-2 text-xs leading-6 text-gray-400">
              Usage of your organization
            </p>
          </div>
        </div>
        <UsageSectionSkeleton></UsageSectionSkeleton>
      </div>
    )

  }


  return (
    <div className=" max-w-7xl px-4 flex-1 ">
      <div class="flex flex-col items-start justify-between gap-x-8 gap-y-4 py-4 sm:flex-row sm:items-center rounded">
        <div>
          <div class="flex items-center gap-x-3">
            <h1 class="flex gap-x-3 text-lg leading-7">
              <span class="font-semibold text-grey">Usage</span>
            </h1>
          </div>
          <p class="mt-2 text-xs leading-6 text-gray-400">
            Usage of your organization
          </p>
        </div>
      </div>
      <div className="max-h-sm w-fit  shadow rounded items-center flex flex-row font-semibold text-gray-400">
        <CalendarDaysIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
        <h1 className="py-2 px-2"> Last 90 days</h1>
      </div>
      <div class="flex flex-col gap-x-8 gap-y-6 py-4 h-[70vh]">
        <div class="flex gap-x-4 flex-row max-h-md h-[15vh] ">
          <div class="flex flex-1 flex-col rounded shadow ">
            <h1 className="font-semibold text-grey text-base p-2">
              Total Attempts
            </h1>
            <h2 className="font-semibold text-grey text-4xl p-2 ">
              {usageStats.attemptCount || 0}
            </h2>
            <div>

            </div>
          </div>
          <div class="flex flex-1 flex-col rounded shadow">
            <h1 className="font-semibold text-grey text-base p-2">
              Total Recordings
            </h1>
            <h2 className="font-semibold text-grey text-4xl p-2">
              {usageStats.savedAttemptCount || 0}
            </h2>
          </div>
        </div>
        <div class="flex flex-1 flex-col rounded h-2/3 shadow">
          <h1 className="font-semibold text-grey text-base p-2">
            Daily Active Users
          </h1>
          <div className="font-semibold flex-grow w-full text-grey text-base p-2">
            <UsageChart data={usageStats.loginDistribution || []}></UsageChart>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UsageSection
