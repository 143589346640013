import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';

export const UpperMetricSkeleton = () => (
  <div className="w-full">
    <Skeleton height="15vh" />
  </div>
)

export const ChartSkeleton = () => (
  <div className="w-full">
    <Skeleton height="60vh" />
  </div>
)

export const UsageSectionSkeleton = () => (
  <div className="w-full h-[65vh] p-8 flex flex-col gap-4">

    <UpperMetricSkeleton />

    <ChartSkeleton />
  </div>
)

export default UsageSectionSkeleton;

