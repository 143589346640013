import React from "react"
import { InboxIcon, HomeIcon, HomeModernIcon, UsersIcon, ChatBubbleLeftIcon } from "@heroicons/react/20/solid"

export default function SideBarItem({
  label,
  icon,
  onClick,
  isShaded
}) {

  const iconsMap = {
    home: HomeIcon,
    team: UsersIcon,
    playground: ChatBubbleLeftIcon,
    inbox: InboxIcon,
  };

  const IconComponent = iconsMap[icon] || null;

  const navIcon = IconComponent ? (
    <IconComponent
      aria-hidden="true"
      className={`h-5 w-5 ${isShaded ? "text-pareBlue" : "text-gray300"} mr-1`}
    />
  ) : null;


  return (
    <>
      {isShaded ? <button class="group flex gap-x-3 rounded-md bg-gray-50 p-2 text-xs font-semibold leading-6 text-pareBlue mr-4 w-full" onClick={onClick}>
        {navIcon}
        <span className="hidden md:block">{label}</span>
      </button>
        : <button class="group flex gap-x-3 rounded-md p-2 text-xs hover:bg-gray-50 hover:text-pareBlue font-semibold leading-6 text-gray-700 mr-4 w-full" onClick={onClick}>
          {navIcon}
          <span className="hidden md:block ">{label}</span>
        </button>}
    </>
  )
}
