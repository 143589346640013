import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import Input from "../../tailwinds_components/components/input"; // Your custom input component
import logo from "../../tailwinds_components/images/logos/parelogo.svg";
import Alert from "../../tailwinds_components/components/alert"; // Your alert component
import { useStores } from "../../models/root-store"


export default function SignIn() {
  // Define the state variables
  const [email, setEmail] = useState("");
  const { userStore } = useStores()
  const [password, setPassword] = useState("");
  const [potentialUsername, setPotentialUsername] = useState("");
  const [resetPin, setResetPin] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetCodePopUp, setResetCodePopUp] = useState(false);
  const [passwordResetPopUp, setPasswordResetPopUp] = useState(false); // State for new password pop-up
  const [forgotPasswordPopUp, setForgotPasswordPopUp] = useState(false); // Fix this state
  const [alert, setAlert] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  // Function to handle login
  const handleLogin = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    try {
      await userStore.login(email, password)
      const user = userStore.user
      if (user) {
        navigate("/homepage")
      } else {
        setAlert({ type: "error", title: "Login Failed", messages: ["Login was unsuccessful, please re-enter your username and password"] })
      }
    } catch (err) {
      setAlert({ type: "error", title: "Login Failed", messages: [err.message] });
    } finally {
      setSubmitting(false);
    }
  };

  // Function to send reset code to email
  const sendResetPin = async () => {
    try {
      await Auth.forgotPassword(potentialUsername.toLowerCase());
      setForgotPasswordPopUp(false); // Close the forgot password pop-up
      setResetCodePopUp(true); // Show the reset code pop-up
    } catch (err) {
      setAlert({ type: "error", title: "Error", messages: [err.message] });
    }
  };

  // Function to submit the new password with the reset code
  // Function to submit the new password with the reset code
  const resetPassword = async () => {
    if (newPassword !== confirmPassword) {
      setAlert({ type: "error", title: "Passwords do not match", messages: ["Please make sure both passwords are the same."] });
      return;
    }

    console.log("Resetting password with code:", resetPin, "New Password:", newPassword);

    try {
      await Auth.forgotPasswordSubmit(potentialUsername.toLowerCase(), resetPin, newPassword);
      setPasswordResetPopUp(false);
      setResetCodePopUp(false);
      setAlert({ type: "success", title: "Password Reset Successfully", messages: ["Your password has been reset."] });
      console.log("Password reset successfully.");
    } catch (err) {
      setPasswordResetPopUp(false);
      setResetCodePopUp(false);
      setAlert({ type: "error", title: "Error", messages: [err.message] });
      console.error("Error resetting password:", err);
    }
  };


  const handleForgotPasswordClick = () => {
    setForgotPasswordPopUp(true); // Open the forgot password pop-up
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            alt="Pare"
            src={logo} // Your logo
            className="mx-auto h-10 w-auto"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          {/* Display alert */}
          {alert && <Alert type={alert.type} title={alert.title} messages={alert.messages} />}

          <form onSubmit={handleLogin} className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <Input
                  id="email"
                  name="email"
                  type="email"
                  required
                  placeholder="you@example.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                  Password
                </label>

              </div>
              <div className="mt-2">
                <Input
                  id="password"
                  name="password"
                  type="password"
                  required
                  placeholder=""
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              <div className="text-sm flex justify-end mt-4">
                <button
                  type="button"
                  className="font-semibold text-pareBlue hover:text-indigo-500 text-right"
                  onClick={handleForgotPasswordClick}
                >
                  Forgot password?
                  </button>

              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-pareBlue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                disabled={submitting}
              >
                {submitting ? "Signing in..." : "Sign in"}
              </button>
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            Not a member?{" "}
            <Link to="/waitlistRequestPage" className="font-semibold text-pareBlue hover:text-indigo-500">
              Join the Waitlist!
            </Link>
          </p>
        </div>
      </div>

      {/* Forgot Password Popup */}
      {forgotPasswordPopUp && (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-6 space-y-6">
            <h2 className="text-xl font-bold">Forgot Password?</h2>
            <p>Please enter your email to reset your password:</p>
            <Input
              id="potentialUsername"
              type="email"
              placeholder="Enter your email"
              value={potentialUsername}
              onChange={(e) => setPotentialUsername(e.target.value)}
            />
            <div className="flex justify-end space-x-4">
              <button onClick={() => setForgotPasswordPopUp(false)} className="text-gray-500 hover:text-gray-800">
                Cancel
              </button>
              <button onClick={sendResetPin} className="text-pareBlue hover:text-indigo-500">
                Send Reset Link
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Reset Code Popup */}
      {resetCodePopUp && (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-6 space-y-6">
            <h2 className="text-xl font-bold">Enter Reset Code</h2>
            <p>A reset code has been sent to your email. Enter the code and your new password below:</p>
            <Input
              id="resetPin"
              type="text"
              placeholder="Reset Code"
              value={resetPin}
              onChange={(e) => setResetPin(e.target.value)}
            />
            <Input
              id="newPassword"
              type="password"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <Input
              id="confirmPassword"
              type="password"
              placeholder="Confirm New Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <div className="flex justify-end space-x-4">
              <button onClick={resetPassword} className="text-pareBlue hover:text-indigo-500">
                Reset Password
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
