import React, { useEffect, useState, useMemo, useContext } from "react"
import { NotificationContext } from "../contexts/notificationContext"
import { GetAssignmentsByManager } from "../api/routes"
import { useStores } from "../models/root-store"
import { observer } from "mobx-react-lite"
import { InboxIcon } from "@heroicons/react/20/solid"
import DropdownSelector from "../components/LearningCenter/dropdown"
import ProgressionModal from "../components/LearningPath/progressionModal"
import { toJS } from "mobx"

export const AssignmentTypeLabels = {
  Activity: "Activity",
  SkillChallenge: "Skill Challenge",
  LearningPath: "Learning Path",
}

const Inbox = observer(() => {
  const { userStore, teamStore, moduleListStoreV2 } = useStores()
  const [assignments, setAssignments] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedManager, setSelectedManager] = useState(null)
  const [showProgressionModal, setShowProgressionModal] = useState(false)
  const [selectedAssignment, setSelectedAssignment] = useState(null)
  const [modulesForCurrentlySelectedAssignment, setModulesForCurrentlySelectedAssignment] = useState([])
  const [availableModules, setAvailableModules] = useState([])
  const [availableManagers, setAvailableManagers] = useState([])
  const isAdmin = userStore.user.type === "Admin"
  const isManager = userStore.user.type === "Manager"

  const { triggerNotification } = useContext(NotificationContext)

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        setLoading(true)

        // Fetch team if we have not already done so
        if (!teamStore.teamInfo) {
          await teamStore.getTeam(
            userStore.user.userId,
            userStore.user.type,
            userStore.user.company,
            userStore.user.userId,
          )
        }

        if (isAdmin) {
          managerId = availableManagers[0].userId || selectedManager?.userId
        } else if (isManager) {
          managerId = userStore.user.userId
        } else {
          managerId = userStore.user.managerId
        }

        // Fetch modules info at company scope if not already loaded
        if (!moduleListStoreV2.userModules) {
          await moduleListStoreV2.getModules(userStore.user.company, userStore.user.userId)
        }

        // Get the list of team members and managers
        const teamList = toJS(teamStore.teamInfo?.team) || []
        setAvailableManagers(teamList.filter(teamMember => teamMember.type === "Manager"))
        setAvailableModules(toJS(moduleListStoreV2.userModules?.moduleList) || [])
        console.log(
          teamList,
          teamList.filter(teamMember => teamMember.type === "Manager"),
        )

        let managerId
        if (isAdmin) {
          managerId = selectedManager?.userId || availableManagers[0]?.userId
        } else if (isManager) {
          managerId = userStore.user.userId
        } else {
          managerId = userStore.user.managerId
        }

        if (!managerId) {
          throw new Error("No manager selected to fetch assignments.")
        }

        const allAssignments = await GetAssignmentsByManager(managerId, userStore.user.userId)

        if (allAssignments.kind !== "ok") {
          throw new Error("Assignment fetch failed")
        }

        setAssignments(allAssignments.data || [])
      } catch (error) {
        console.error("Failed to fetch data:", error)
        triggerNotification("error", "Error fetching assignments")
      } finally {
        setLoading(false) // Hide loading state
      }
    }

    fetchAllData()
  }, [userStore.user, teamStore.teamInfo])

  // Group assignments into Kanban columns
  const groupedAssignments = useMemo(() => {
    return {
      Assigned: assignments.filter(assignment => ["Assigned", "In Progress", "Not Passed"].includes(assignment.status)),
      PendingReview: assignments.filter(assignment => assignment.status === "Pending Review"),
      Completed: assignments.filter(assignment => assignment.status === "Completed"),
    }
  }, [assignments])

  // Kanban column configurations
  const columnConfig = [
    { title: "Assigned", key: "Assigned", bgColor: "bg-blue-100", textColor: "text-blue-700" },
    { title: "Pending Review", key: "PendingReview", bgColor: "bg-yellow-100", textColor: "text-yellow-700" },
    { title: "Completed", key: "Completed", bgColor: "bg-green-100", textColor: "text-green-700" },
  ]

  const handleManagerChange = event => {
    const selected = availableManagers.find(manager => manager.userId === event.target.value)
    setSelectedManager(selected)
  }

  const handleAssignmentClick = assignment => {
    if (assignment.type === "LearningPath") {
      setModulesForCurrentlySelectedAssignment(
        availableModules.filter(module => Object.keys(assignment.progress.moduleStatuses).includes(module.activity_id)),
      )
      setSelectedAssignment(assignment)
      setShowProgressionModal(true)
    }
  }

  return (
    <div className="h-screen px-8">
      <div className="flex items-center justify-between">
        <div>
          <InboxIcon aria-hidden="true" className="h-5 w-4 text-pareBlue mr-1" />
          <h1 className="text-base font-semibold leading-6 text-gray-900">Inbox</h1>
        </div>
        {/* Admin Dropdown for Manager Selection */}
        {isAdmin && (
          <div className="flex justify-end mb-4">
            <DropdownSelector
              label="Select Manager"
              options={availableManagers.map(manager => ({
                value: manager.userId,
                label: manager.name,
              }))}
              value={selectedManager?.userId || ""}
              onChange={handleManagerChange}
            />
          </div>
        )}
      </div>

      {loading ? (
        <div className="text-center text-gray-500">Loading assignments...</div>
      ) : (
        <div className="grid lg:grid-cols-3 gap-5">
          {columnConfig.map(({ title, key, bgColor, textColor }) => (
            <div key={key} className="bg-white rounded px-4 py-2 shadow">
              {/* Column Header */}
              <div className="flex justify-between items-center mb-2">
                <h2 className={`${bgColor} ${textColor} text-sm px-2 py-1 rounded`}>{title}</h2>
                <p className="text-gray-400 text-sm">{groupedAssignments[key]?.length || 0}</p>
              </div>

              {/* Cards */}
              <div className="space-y-2">
                {groupedAssignments[key]?.map(assignment => (
                  <div
                    key={`${assignment.userId}-${assignment.displayName}`}
                    className="p-3 mb-2 border rounded shadow-sm bg-gray-50 hover:bg-gray-300 cursor-pointer"
                    onClick={() => handleAssignmentClick(assignment)}>
                    <h3 className="text-sm font-semibold text-gray-700">{assignment.displayName}</h3>
                    <p className="text-xs text-gray-500 mb-2">Assigned to: {assignment.userName}</p>
                    <p className="text-xs text-gray-500 mb-2">Due Date: {assignment.dueDate}</p>

                    {/* Type Tag */}
                    <span
                      className={`text-xs px-2 py-1 rounded mr-2 ${
                        assignment.type === "Activity"
                          ? "bg-green-200 text-green-800"
                          : assignment.type === "SkillChallenge"
                          ? "bg-purple-200 text-purple-800"
                          : assignment.type === "LearningPath"
                          ? "bg-yellow-200 text-yellow-800"
                          : "bg-gray-200 text-gray-800"
                      }`}>
                      {AssignmentTypeLabels[assignment.type]}
                    </span>

                    {/* Status Tag */}
                    <span
                      className={`text-xs px-2 py-1 rounded ${
                        assignment.status === "Assigned"
                          ? "bg-blue-200 text-blue-800"
                          : assignment.status === "In Progress"
                          ? "bg-purple-200 text-purple-800"
                          : assignment.status === "Not Passed"
                          ? "bg-red-200 text-red-800"
                          : assignment.status === "Pending Review"
                          ? "bg-yellow-200 text-yellow-800"
                          : assignment.status === "Completed"
                          ? "bg-green-200 text-green-800"
                          : "bg-gray-200 text-gray-800"
                      }`}>
                      {assignment.status}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}

      {showProgressionModal && selectedAssignment && (
        <ProgressionModal
          isOpen={showProgressionModal}
          onClose={() => setShowProgressionModal(false)}
          assignment={selectedAssignment}
          modules={availableModules}
        />
      )}
    </div>
  )
})

export default Inbox
