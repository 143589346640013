import React from "react"
import EmptyState from "../../components/Global/emptyState"

const LearningPathsEmptyPage = ({ setCurrentTab }) => {
  return (
    <div className="flex flex-col h-screen bg-gray-50">
      {/* Top Padding and Empty State */}
      <div className="flex-1 flex items-start justify-center px-4 pt-12">
        <EmptyState
          header="Learning Paths are not yet available"
          subHeader="We are rolling out this feature soon"
          buttonLabel="Back to Module Library"
          buttonFunction={() => setCurrentTab("moduleLibrary")}
          icon="learningpath"
        />
      </div>
    </div>
  )
}

export default LearningPathsEmptyPage
