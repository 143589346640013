import React, { useEffect, useState, useContext } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Tabs from "../../components/Global/tabs"
import ModuleLeaderboard from "../../components/ActivityTeamViewPage/moduleLeaderboard"
import { MediaPermissionsError, MediaPermissionsErrorType, requestMediaPermissions } from "mic-check"
import { NotificationContext } from "../../contexts/notificationContext"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"
import { toJS } from "mobx"
import AssignmentLandingPageSkeleton from "../../components/AssignmentLandingPage/skeletons"
import ModuleDetails from "../../components/ActivityTeamViewPage/moduleDetails"
import ModuleBreadcrumb from "../../components/ActivityTeamViewPage/moduleBreadcrumb"
import EmptyState from "../../components/Global/emptyState"
import PlayButton from "../../components/Buttons/playButton"
import { generateLeaderboardRankings } from "../../components/ActivityTeamViewPage/atvUtilities"
import { GetAssignmentsByTarget } from "../../api/routes"
import AttemptThumbnail from "../../components/AttemptThumbnail/AttemptThumbnail"
import DoubleOptionPopUp from "../../components/PopUps/DoubleOptionPopUp"

const AssignmentLandingPage = () => {
  const { moduleId } = useParams()
  const { triggerNotification } = useContext(NotificationContext)
  const { userStore, moduleListStoreV2, attemptListStore } = useStores()
  const { userId } = userStore.user || {}
  const [pageLoading, setPageLoading] = useState(true)
  const navigate = useNavigate()
  const [selectedTab, setSelectedTab] = useState("Overview")
  const [modulePendingReview, setModulePendingReview] = useState("Not Passed")
  const [leaderboardRankings, setLeaderboardRankings] = useState([])
  const [currentUserAssignment, setCurrentUserAssignment] = useState({})
  const [allAssignments, setAllAssignments] = useState([{}])
  const [startAttemptButtonClicked, setStartAttemtButtonClicked] = useState(false)
  const [attempts, setAttempts] = useState([])

  const moduleInfo = toJS(moduleListStoreV2.getModuleById(moduleId)) || {}

  useEffect(() => {
    const fetchModule = async () => {
      if (!moduleListStoreV2.getModuleById(moduleId)) {
        await moduleListStoreV2.getModule(userStore.user.company, moduleId, userStore.user.userId)
      }
    }

    const fetchAssignments = async () => {
      const assignments = await GetAssignmentsByTarget(moduleId, "Module", userId)
      const userAssignment = assignments.data.find(asn => asn.userId === userId) || {}
      setCurrentUserAssignment(userAssignment)

      setAllAssignments(assignments.data)
      const attempts = userAssignment.attempts
      if (attempts) {
        if (!attemptListStore.userAttempts) {
          await attemptListStore.adaptAttemptsToStore(attempts, true, userId)
        } else if (
          attemptListStore.isAllAttemptsByUserIdFetched(attempts) ||
          attemptListStore.isAttemptBeingProcessed()
        ) {
          await attemptListStore.adaptAttemptsToStore(attempts, false, userId)
        }
        const fetchedAttempts = toJS(attemptListStore.getAttempts(attempts))
        setAttempts(fetchedAttempts)
      } else {
        console.log("No attempts yet for this user")
      }

      // Handle leaderboard update
      const leaderboard = generateLeaderboardRankings(assignments.data)
      setLeaderboardRankings(leaderboard)

      // handle status updates
      setModulePendingReview(userAssignment.status || "Assigned")
    }

    const fetchData = async () => {
      try {
        await Promise.all[(fetchModule(), fetchAssignments())]
      } catch (error) {
        console.error("Error fetching data:", error)
      } finally {
        setPageLoading(false)
      }
    }
    fetchData()
  }, [])

  const checkPermissions = async () => {
    try {
      await requestMediaPermissions()
      return true
    } catch (err) {
      const { type, name, message } = err
      if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
        // browser does not have permission to access camera or microphone
        triggerNotification("error", name, message)
        return false
      } else if (type === MediaPermissionsErrorType.UserPermissionDenied) {
        // user didn't allow app to access camera or microphone
        triggerNotification("error", name, message)
        return false
      } else if (type === MediaPermissionsErrorType.CouldNotStartVideoSource) {
        // camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)
        // (mostly Windows specific problem)
        triggerNotification("error", name, message)
        return false
      } else {
        // not all error types are handled by this library
        triggerNotification("error", name, message)
        return false
      }
    }
  }

  const startNewAttempt = async () => {
    if (await checkPermissions()) {
      const moduleObj = moduleInfo
      const chatRoomPayload = {
        ...moduleObj,
        assigned: true,
      }
      navigate("/chatRoomPage", { state: chatRoomPayload })
    }
  }

  const handleStartNewAttemptClick = () => {
    setStartAttemtButtonClicked(true)
  }

  const attemptDisclaimerPopUp = startAttemptButtonClicked ? (
    <DoubleOptionPopUp
      header="You are about to jump in the roleplay"
      body={`If you are ready start the module, click start module to be sent to the meeting room\n\n NOTE: The voice you are about to here is AI-generated and not a human voice`}
      leftButtonLabel="Cancel"
      leftButtonFunction={() => setStartAttemtButtonClicked(false)}
      rightButtonFunction={startNewAttempt}
      rightButtonLabel="Start module"></DoubleOptionPopUp>
  ) : null

  const recordingsBoxContent =
    attempts.length == 0 ? (
      <div className="flex flex-col items-center mt-20">
        <EmptyState
          header="No Attempts"
          subHeader='Click "Start Module" to get started!'
          buttonLabel="Start module"
          buttonFunction={handleStartNewAttemptClick}></EmptyState>
      </div>
    ) : (
        <div className="w-full flex flex-row flex-wrap">
          {attempts
            .sort((a, b) => b.attemptTime - a.attemptTime)
            .map((x, i) => (
              <AttemptThumbnail
                key={x ?.attemptId || i}
                attempt={x}
                order={i + 1}
                attemptedUserId={userId}
                moduleId={moduleInfo.moduleId}
              />
            ))}
        </div>
      )

  const tabNames = [
    { name: "Overview", key: "Overview" },
    { name: "Attempts", key: "Attempts" },
    { name: "Leaderboard", key: "Leaderboard" },
  ]

  return (
    <>
      {pageLoading ? (
        <AssignmentLandingPageSkeleton></AssignmentLandingPageSkeleton>
      ) : (
          <div class="w-full h-full flex flex-col px-8 pt-6">
            <main>
              <header>
                <nav class="flex overflow-x-auto border-b border-white/10 py-4">
                  <ModuleBreadcrumb basePath="/inboxPage" baseLabel="Assignments" currentPage={moduleInfo.displayName} />
                </nav>

                <div class="flex flex-col items-start justify-between gap-x-8 gap-y-4 py-4 sm:flex-row sm:items-center rounded">
                  <div>
                    <div class="flex items-center gap-x-3">
                      <h1 class="flex gap-x-3 text-base leading-7">
                        <span class="font-semibold text-grey">{moduleInfo.displayName}</span>
                      </h1>
                    </div>
                    <p class="mt-2 text-xs leading-6 text-gray-400">{`${moduleInfo.displayName} Summary`}</p>
                  </div>
                  {modulePendingReview === "Not Passed" || modulePendingReview === "Assigned" ? (
                    <PlayButton buttonLabel="Start module" buttonFunction={handleStartNewAttemptClick}></PlayButton>
                  ) : modulePendingReview == "Passed" || modulePendingReview == "Completed" ? (
                    <button
                      disabled
                      class="order-first flex-none rounded-full bg-indigo-400/10 px-2 py-1 text-s font-medium text-indigo-400 ring-1 ring-inset ring-indigo-400/30 sm:order-none">
                      Passed
                  </button>
                  ) : null}
                </div>

                <div class="grid grid-cols-1 px-4 sm:grid-cols-2 lg:grid-cols-4 rounded">
                  <div class="border-t border-white/5  py-6">
                    <p class="text-sm font-medium leading-6 text-gray-400">Due Date</p>
                    <p class="mt-2 flex items-baseline gap-x-2">
                      <span class="text-3xl font-semibold tracking-tight text-grey">
                        {currentUserAssignment.dueDate || "None"}
                      </span>
                    </p>
                  </div>
                  <div class="border-t border-white/5 px-4 py-6 sm:border-l sm:px-6 lg:px-8">
                    <p class="text-sm font-medium leading-6 text-gray-400">Time Limit</p>
                    <p class="mt-2 flex items-baseline gap-x-2">
                      <span class="text-3xl font-semibold tracking-tight text-grey">{moduleInfo.timeLimit || "0"}</span>
                      <span class="text-sm text-gray-400">mins</span>
                    </p>
                  </div>
                  <div class="border-t border-white/5 px-4 py-6 sm:px-6 lg:border-l lg:px-8">
                    <p class="text-sm font-medium leading-6 text-gray-400"># of attempts</p>
                    <p class="mt-2 flex items-baseline gap-x-2">
                      <span class="text-3xl font-semibold tracking-tight text-grey">
                        {currentUserAssignment.attempts ?.length || 0}
                      </span>
                    </p>
                  </div>
                  <div class="border-t border-white/5 px-4 py-6 sm:border-l sm:px-6 lg:px-8">
                    <p class="text-sm font-medium leading-6 text-gray-400">Status</p>
                    <p class="mt-2 flex items-baseline gap-x-2">
                      {modulePendingReview === "Assigned"
                        || modulePendingReview === "In Progress"
                        || modulePendingReview === "Pending Review" ? (
                          <div class="flex-none rounded-full bg-yellow_light p-1 text-yellow">
                            <div class="h-3 w-3 rounded-full bg-current"></div>
                          </div>
                        ) : modulePendingReview === "Completed" ? (
                          <div class="flex-none rounded-full bg-green_light p-1 text-green">
                            <div class="h-3 w-3rounded-full bg-current"></div>
                          </div>
                        ) : (
                            <div class="flex-none rounded-full bg-red-400/10 p-1 text-red-400">
                              <div class="h-3 w-3 rounded-full bg-current"></div>
                            </div>
                          )}
                      <span class="text-3xl font-semibold tracking-tight text-grey">
                        {modulePendingReview === "In Progress" ? "Pending Review" : modulePendingReview}
                      </span>
                    </p>
                  </div>
                </div>
              </header>
              <div className="mb-4 mt-4 ">
                <Tabs tabNames={tabNames} selectedTab={selectedTab} setSelectedTab={setSelectedTab}></Tabs>
              </div>
              {selectedTab === "Overview" ? (
                <ModuleDetails moduleInfo={moduleInfo} />
              ) : selectedTab === "Attempts" ? (
                <>{recordingsBoxContent}</>
              ) : (
                    <ModuleLeaderboard leaderboardRankings={leaderboardRankings}></ModuleLeaderboard>
                  )}
            </main>
            {attemptDisclaimerPopUp}
          </div>
        )}
    </>
  )
}

export default observer(AssignmentLandingPage)
