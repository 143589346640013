import React from "react"
import { XMarkIcon } from "@heroicons/react/20/solid"
import ModalFeed from "./modalFeed"
import CardDescription from "../Global/cardDescription"

const ProgressionModal = ({ isOpen, onClose, assignment, modules }) => {
  if (!isOpen) return null

  // Map progress.moduleStatuses to the modules list
  const moduleStatuses = assignment.progress?.moduleStatuses || {}
  console.log(`module status map: ${JSON.stringify(moduleStatuses)}`)
  const combinedModules = modules.map(module => {
    console.log(module.moduleId) // change to moduleId after refaC
    const status = moduleStatuses[module.moduleId] || "Assigned" // same

    return {
      ...module,
      status, // Attach the status to each module
    }
  })

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      {/* Modal Container */}
      <div className="relative bg-white rounded-lg shadow-xl w-full max-w-5xl">
        {/* Modal Header */}
        <div className="bg-pareBlue py-4 px-6 rounded-t-lg">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-semibold text-white">{`Learning Path Progress - ${
              assignment.userName || "User"
            }`}</h2>
            <button onClick={onClose} className="text-indigo-200 hover:text-white">
              <XMarkIcon className="h-6 w-6" />
            </button>
          </div>
        </div>

        {/* Modal Content */}
        <div className="grid grid-cols-3 gap-4 p-6">
          {/* Left Section - Feed */}
          <div className="col-span-2 border-r border-gray-200 pr-4">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Modules</h3>
            <ModalFeed modules={combinedModules} />
          </div>

          {/* Right Section - Description Pane */}
          <div className="col-span-1">
            <CardDescription
              title={assignment.displayName || "Learning Path Details"}
              subtitle=""
              details={[
                { label: "Assigned To", value: assignment.userName },
                { label: "Due Date", value: new Date(assignment.dueDate).toLocaleDateString("en-US") },
                { label: "Assigned Date", value: new Date(assignment.assignedDate).toLocaleDateString("en-US") },
              ]}
            />
          </div>
        </div>

        {/* Footer */}
        <div className="rounded-md bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700 :bg-gray-30hover0">
          {" "}
          NOTE: user ability to complete learning paths is being rolled out the week of 1/13
        </div>
        <div className="flex justify-end border-t border-gray-200 px-6 py-3">
          <button
            onClick={onClose}
            className="rounded-md bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-300">
            Close
          </button>
        </div>
      </div>
    </div>
  )
}

export default ProgressionModal
