import React, { useState } from 'react'
import BuildButton from '../../Buttons/buildButton';
import AgentTable from "../agentTable"
import EmptyState from "../../Global/emptyState"
import { LockClosedIcon } from '@heroicons/react/20/solid'


const AgentsSection = ({ }) => {

  const [agentList, setAgentList] = useState([])

  return (
    <div className=" max-w-7xl px-4 flex-1">
      <div class="flex flex-col items-start justify-between gap-x-8 gap-y-4 py-4 sm:flex-row sm:items-center rounded">
        <div>
          <div class="flex items-center gap-x-3">
            <h1 class="flex gap-x-3 text-lg leading-7">
              <span class="font-semibold text-grey">Agents</span>
            </h1>
          </div>
          <p class="mt-2 text-xs leading-6 text-gray-400">
            Build AI sales enablement agents for each of your rep types
          </p>
        </div>
        <BuildButton
          buttonLabel="Build Agent"
          buttonFunction={null}></BuildButton>
      </div>
      {agentList.length === 0 ?
        <div class="flex relative top-20 flex-1 flex-col items-center justify-center gap-x-8 gap-y-4 py-4 sm:flex-row sm:items-center rounded">
          {/* <EmptyState
            header="No existing agents"
            subHeader="Build and design agents to work for you"
            buttonLabel="Build Agent"
            buttonFunction={null}
            buttonIcon="build"
            icon="agent"></EmptyState> */}
          <div className="text-center">
            <LockClosedIcon aria-hidden="true" className="mx-auto size-12 text-pareBlue" />
            <h3 className="mt-2 text-sm font-semibold text-gray-900">Agents coming soon!</h3>
          </div>
        </div>
        : <div class="flex w-2/3 flex-1 flex-col items-start justify-between gap-x-8 gap-y-4 py-4 sm:flex-row sm:items-center rounded">
          <AgentTable></AgentTable>
        </div>}


    </div>
  )
}

export default AgentsSection
