import React, { useEffect, useState, useContext } from "react"
import { Auth, API } from "aws-amplify"
import SingleOptionPopUp from "../../components/PopUps/SingleOptionPopUp"
import RubricEditPop from "../../components/PopUps/RubricEditPop"
import ModuleDetails from "../../components/ActivityTeamViewPage/moduleDetails"
import ModuleTable from "../../components/ActivityTeamViewPage/moduleTable"
import AssignmentModal from "../../components/LearningPath/assignmentModal"
import AssignedLearnersTable from "../../components/ActivityTeamViewPage/assignedLearnersTable"
import ModuleLeaderboard from "../../components/ActivityTeamViewPage/moduleLeaderboard"
import { grid } from "ldrs"
import { Tabs, TabsList, TabsTrigger } from "../../@shadcn_components/ui/tabs"
import { GetAssignmentsByTarget, UpdateAssignment } from "../../api/routes"
import {
  generateCompletionDistribution,
  generateLeaderboardRankings,
} from "../../components/ActivityTeamViewPage/atvUtilities"
import { NotificationContext } from "../../contexts/notificationContext"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"
import { toJS } from "mobx"
import ActivityTeamViewPageSkeleton from "../../components/ActivityTeamViewPage/skeletons"
import EmptyState from "../../components/Global/emptyState"
import AddButton from "../../components/Buttons/addButton"
import {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../@shadcn_components/ui/breadcrumb"

grid.register()

let alottedTime = null // TODO: typo?

const ModuleDetail = ({ setCurrentContentSubSection, moduleId }) => {
  const { triggerNotification } = useContext(NotificationContext)
  const { userStore, teamStore, moduleListStoreV2 } = useStores()
  const { userId, type, name, company } = userStore.user || {}
  const [leaderboardRankings, setLeaderboardRankings] = useState([])
  const [dueDate, setDueDate] = useState("")
  const [currentTab, setCurrentTab] = useState("Overview")
  const [completionDist, setCompletionDist] = useState({})
  const [pageLoading, setPageLoading] = useState(true)
  const [reminderSent, setReminderSent] = useState(false)
  const [showRubricPopUp, setRubricPopUp] = useState({ show: false, mode: null })
  const [assigned, setAssigned] = useState(false)
  const [showAssignModal, setShowAssignModal] = useState(false)
  const moduleInfo = toJS(moduleListStoreV2.getModuleById(moduleId)) || {}
  const [currentRubric, setCurrentRubric] = useState(moduleInfo.rubric || {})
  const [assignments, setAssignments] = useState([])
  const teamList = toJS(teamStore.teamInfo?.team) || []

  const handlePopUpDueDateChange = e => {
    setDueDate(e.target.value)
  }

  const handleDueDateChange = async (targetUserId, newDueDate) => {
    try {
      await UpdateAssignment(targetUserId, userId, "Module", moduleId, {
        dueDate: newDueDate,
      })
      setAssignments(prevAssignments => {
        const updatedArray = prevAssignments.map(assignment => {
          if (assignment.userId === targetUserId) {
            return { ...assignment, dueDate: newDueDate }
          }
          return assignment
        })
        const newCompletionDist = generateCompletionDistribution(updatedArray, overdueChecker)
        setCompletionDist(newCompletionDist)

        return updatedArray
      })
      triggerNotification("success", "Successfully changed due date", `Successfully modified due date to ${newDueDate}`)
    } catch (err) {
      triggerNotification("error", "Error changing due date", `Please retry changing due date`)
    }
  }

  const fetchTeam = async () => {
    if (!teamStore.teamInfo) {
      await teamStore.getTeam(userId, type, company, userId)
    }
  }

  // Fetch user modules if not already loaded
  const fetchModule = async () => {
    if (!moduleListStoreV2.getModuleById(moduleId)) {
      await moduleListStoreV2.getModule(userStore.user.company, moduleId, userStore.user.userId)
    }
  }

  const fetchAssignments = async () => {
    try {
      const allAssignments = await GetAssignmentsByTarget(moduleId, "Module", userId)
      const filteredAssignments =
        userStore.user.type == "Admin"
          ? allAssignments.data
          : allAssignments.data.filter(a => a.managerId == userStore.user.userId)

      setAssignments(filteredAssignments || [])

      const leaderboard = generateLeaderboardRankings(filteredAssignments)
      setLeaderboardRankings(leaderboard)

      const distribution = generateCompletionDistribution(filteredAssignments, overdueChecker)
      setCompletionDist(distribution)
    } catch (error) {
      triggerNotification("error", "Error fetching assignments", error.message)
    }
  }

  const handleAssign = () => setShowAssignModal(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([fetchTeam(), fetchModule(), fetchAssignments()])
        setAssigned(assignments.find(a => a.userId == userId && a.status != "In Progress"))

        // call assignemnts api, check if curr signed in user has assignment and status != in progress || pending
      } catch (error) {
        console.error("Error fetching data:", error)
      } finally {
        // Ensure page loading is set to false after both fetches or if already populated
        setPageLoading(false)
      }
    }

    fetchData()
  }, [pageLoading])

  const overdueChecker = dueDate => {
    const stringToDate = new Date(dueDate)
    const epochDueDate = stringToDate.getTime()
    const today = new Date().toDateString()
    const epochToday = new Date(today).getTime()
    return epochToday > epochDueDate
  }

  const sendReminder = async (e, name, email, testName, dueDate) => {
    e.stopPropagation()
    if (true) {
      setReminderSent(true)
      try {
        API.post("ZenoApp", "/sendReminder", {
          headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
          body: {
            user_id: userId,
            name,
            email,
            testName,
            dueDate,
          },
        })
        triggerNotification("success", "Successfully sent reminder", `Sent reminder to ${name}`)
      } catch (err) {
        triggerNotification("error", "Error sending reminder", `Please retry sending the reminder`)
      }
    } else {
      triggerNotification("error", "Reminder already sent", `Reminder was already sent to ${name}`)
    }
  }

  const closeRubricPopUp = () => setRubricPopUp({ show: false, mode: null })

  const updateRubric = async () => {
    const res = await moduleListStoreV2.updateModule(userStore.user.company, moduleInfo.moduleId, {
      user_id: userId,
      rubric: {
        id: moduleInfo.rubric.id || "",
        fileName: moduleInfo.rubric.fileName || "",
        content: currentRubric.trim(),
      },
    })
    if (res) {
      triggerNotification("success", "Update Successful", "Rubric has been successfully updated.")
    } else {
      triggerNotification("error", "Error updating rubric", "There was an issue updating the rubric. Please try again.")
    }
    closeRubricPopUp()
  }

  const rubricPopUp =
    showRubricPopUp.show && showRubricPopUp.mode === "view" ? (
      <SingleOptionPopUp
        header="Custom Rubric"
        body={moduleInfo.rubric.content}
        buttonLabel="Close"
        buttonFunction={closeRubricPopUp}></SingleOptionPopUp>
    ) : showRubricPopUp.show && showRubricPopUp.mode === "edit" ? (
      <RubricEditPop
        header="Custom Rubric"
        body={moduleInfo.rubric.content}
        leftButtonLabel="Cancel"
        leftButtonFunction={closeRubricPopUp}
        rightButtonLabel="Save"
        rightButtonFunction={updateRubric}
        setCurrentRubric={setCurrentRubric}></RubricEditPop>
    ) : null

  return (
    <>
      {pageLoading ? (
        <ActivityTeamViewPageSkeleton></ActivityTeamViewPageSkeleton>
      ) : (
        <div class="w-full h-full flex flex-col">
          <>
            <div className="mb-8">
              <div>
                <Breadcrumb>
                  <BreadcrumbList>
                    <BreadcrumbItem>
                      <div className="cursor-pointer" onClick={() => setCurrentContentSubSection("Modules")}>
                        Modules
                      </div>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                    <BreadcrumbItem>
                      <BreadcrumbPage>{moduleInfo.displayName}</BreadcrumbPage>
                    </BreadcrumbItem>
                  </BreadcrumbList>
                </Breadcrumb>
                <ModuleTable
                  moduleName={moduleInfo.displayName}
                  completionDist={completionDist}
                  onTestModuleClick={null}
                  assigned={assigned}
                  isAdminConsole={true}
                />
                <div class="flex flex-col items-start justify-between py-2 sm:flex-row sm:items-center rounded">
                  <div>
                    <Tabs
                      defaultValue={currentTab}
                      className="w-[100%]"
                      onValueChange={str => {
                        setCurrentTab(str)
                      }}>
                      <TabsList>
                        <TabsTrigger value="Overview">Overview</TabsTrigger>
                        <TabsTrigger value="Assigned Learners">Assigned learners</TabsTrigger>
                        <TabsTrigger value="Leaderboard">Leaderboard</TabsTrigger>
                      </TabsList>
                    </Tabs>
                  </div>
                  <AddButton buttonLabel="Assign Learners" buttonFunction={handleAssign}></AddButton>
                </div>
                {currentTab === "Overview" ? (
                  <ModuleDetails moduleInfo={moduleInfo} setRubricPopUp={setRubricPopUp} />
                ) : currentTab === "Assigned Learners" ? (
                  assignments.length !== 0 ? (
                    <AssignedLearnersTable
                      assignments={assignments}
                      getAttempts={null}
                      overdueChecker={overdueChecker}
                      handleDueDateChange={handleDueDateChange}
                      sendReminder={sendReminder}
                      testName={moduleInfo.displayName}
                      // come back and fix this
                      dueDate={moduleInfo.creationDate}
                      isAdminConsole={true}
                    />
                  ) : (
                    <EmptyState
                      header="No assigned learners"
                      subHeader="Assign learners to see their attempts"
                      buttonLabel="Assign"
                      buttonFunction={handleAssign}
                      icon="user"></EmptyState>
                  )
                ) : leaderboardRankings.length === 0 ? (
                  <EmptyState
                    header="No assigned learners"
                    subHeader="Assign learners to see leaderboard activity"
                    buttonLabel="Assign"
                    buttonFunction={handleAssign}
                    icon="user"></EmptyState>
                ) : (
                  <ModuleLeaderboard leaderboardRankings={leaderboardRankings}></ModuleLeaderboard>
                )}
              </div>
            </div>
          </>
          {rubricPopUp}
          {showAssignModal && (
            <AssignmentModal
              isOpen={showAssignModal}
              onClose={() => setShowAssignModal(false)}
              teamList={teamList}
              targetId={moduleId}
              targetType={moduleInfo.moduleType}
              targetAdditionalInfo={moduleInfo}
              assigningUserInfo={userStore.user}
              onAssignmentCompleted={fetchAssignments}
            />
          )}
        </div>
      )}
    </>
  )
}

export default observer(ModuleDetail)
