import React, { useState, useEffect } from "react"
import IndividualActivityBox from "../../components/IndividualActivityBox/IndividualActivityBox"
import { Tabs, TabsList, TabsTrigger } from "../../@shadcn_components/ui/tabs"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"
import { toJS } from "mobx"
import AssignmentsPageSkeleton from "../../components/AssignmentsPage/skeletons"
import { GetAssignmentsByManager, GetAssignmentsByTarget } from "../../api/routes" // Or your preferred assignment fetch
import { FlagIcon } from "@heroicons/react/20/solid"

const AssignmentsPage = observer(() => {
  const { userStore, moduleListStoreV2 } = useStores()
  const { userId, managerId, company } = userStore.user || {}

  // Page UI states
  const [pageLoading, setPageLoading] = useState(true)
  const [searchBarInput, setSearchBarInput] = useState("")
  const [selectedFilter, setSelectedFilter] = useState("All")

  // Data states
  const [modules, setModules] = useState([]) // Array of modules from MST
  const [assignments, setAssignments] = useState([]) // Array of assignment objects
  const [filteredModules, setFilteredModules] = useState([])

  // Simple helper to detect overdue
  const overdueChecker = dueDate => {
    if (!dueDate) return false
    const stringToDate = new Date(dueDate)
    const epochDueDate = stringToDate.getTime()
    const today = new Date().toDateString()
    const epochToday = new Date(today).getTime()
    return epochToday > epochDueDate
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setPageLoading(true)

        if (!moduleListStoreV2.userModules) {
          await moduleListStoreV2.getModules(company, userId)
        }
        const modList = toJS(moduleListStoreV2.userModules ?.moduleList) || []
        setModules(modList)

        const assignmentRes = await GetAssignmentsByManager(managerId, userId)
        if (assignmentRes.kind === "ok") {
          setAssignments(assignmentRes.data)
        } else {
          setAssignments([])
        }
      } catch (error) {
        console.error("Error fetching modules/assignments:", error)
      } finally {
        setPageLoading(false)
      }
    }

    fetchData()
  }, [company, userId, moduleListStoreV2])

  useEffect(() => {
    if (pageLoading) return

    const newFiltered = modules
      .map(m => {
        const assignment = assignments.find(a => a.moduleId === m.moduleId && a.userId === userId)
        console.log(JSON.stringify(assignment))
        if (!assignment) return null

        // Check search bar filter
        if (searchBarInput.length > 0) {
          const regex = new RegExp(`^${searchBarInput}.*`, "i")
          if (!m.displayName.match(regex)) return null
        }

        const isOverdue = overdueChecker(assignment.dueDate)
        const status = assignment.status

        if (selectedFilter === "All") {
          // pass
        } else if (selectedFilter === "Passed" && status !== "Passed") {
          return null
        } else if (selectedFilter === "Overdue" && !(isOverdue && (status === "Assigned" || status === "Incomplete"))) {
          return null
        } else if (selectedFilter === "Not Passed" && status !== "Not Passed") {
          return null
        } else if (selectedFilter === "Active" && !(status === "Assigned" || status === "Incomplete")) {
          return null
        } else if (selectedFilter === "In Review" && status !== "Pending Review") {
          return null
        }

        // MERGE the module + assignment info into one object
        return {
          module: m,
          userAssignment: assignment,
          allAssignments: assignments,
        }
      })
      .filter(Boolean) // remove any null results
      .sort((a, b) => b.creationDate - a.creationDate)

    setFilteredModules(newFiltered)
  }, [modules, assignments, selectedFilter, searchBarInput, pageLoading])

  if (pageLoading) {
    return <AssignmentsPageSkeleton />
  }

  return (
    <div className="w-full h-full flex flex-col px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <FlagIcon aria-hidden="true" className="h-5 w-4 text-pareBlue mr-1" />
          <h1 className="text-base font-semibold leading-6 text-gray-900">Module Library</h1>
          <p className="mt-2 text-sm text-gray-700">View and complete assigned role play modules</p>
        </div>
      </div>

      {/* Search bar input */}
      <div className="my-4">
        <input
          type="text"
          placeholder="Search modules..."
          value={searchBarInput}
          onChange={e => setSearchBarInput(e.target.value)}
          className="rounded border border-gray-300 px-3 py-2 text-sm w-[300px]"
        />
      </div>

      {/* Tabs / filters */}
      <Tabs defaultValue="All" className="w-[100%]" onValueChange={setSelectedFilter}>
        <TabsList>
          <TabsTrigger value="All">All</TabsTrigger>
          <TabsTrigger value="Active">Active</TabsTrigger>
          <TabsTrigger value="Passed">Passed</TabsTrigger>
          <TabsTrigger value="In Review">In Review</TabsTrigger>
          <TabsTrigger value="Overdue">Overdue</TabsTrigger>
          <TabsTrigger value="Not Passed">Not Passed</TabsTrigger>
        </TabsList>
      </Tabs>

      {/* Filtered Results */}
      {filteredModules.length === 0 ? (
        <div className="flex flex-col items-center mt-20">
          <p className="text-sm text-gray-500">No Modules Found</p>
          <p className="text-sm text-gray-500">Please wait for your manager to assign modules, or adjust filters.</p>
        </div>
      ) : (
          <div className="w-full h-full rounded-md flex flex-row flex-wrap content-start mt-4">
            {filteredModules.map(compositeModuleAndAssignment => (
              <IndividualActivityBox
                composite={compositeModuleAndAssignment}
                key={`activity_card_${compositeModuleAndAssignment.module.moduleId}`}
              />
            ))}
          </div>
        )}
    </div>
  )
})

export default AssignmentsPage
