"use client"

import { useState } from "react"
import { Dialog, DialogBackdrop, DialogPanel, TransitionChild } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/24/outline"
import {
  FolderIcon,
  UsersIcon,
  SparklesIcon,
  Square3Stack3DIcon,
  ShareIcon,
  PresentationChartBarIcon,
  PresentationChartLineIcon,
  EyeIcon,
} from "@heroicons/react/24/outline"

const pareLogo = require("../../icons/PareLogo_new.png")

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function AdminConsoleSidebar({ setCurrentSection, currentTab, sidebarOpen, setSidebarOpen }) {
  const [sidebarNavState, setSidebarNavState] = useState({
    Content: [{ name: "Folders", icon: FolderIcon, current: false }],
    Settings: [
      { name: "Agents", icon: SparklesIcon, current: true },
      { name: "Users", icon: UsersIcon, current: false },
      { name: "Organization", icon: Square3Stack3DIcon, current: false },
      { name: "Apps", icon: ShareIcon, current: false },
    ],
    Reporting: [
      { name: "Usage", icon: EyeIcon, current: true },
      { name: "Attributions", icon: PresentationChartBarIcon, current: false },
      { name: "Recommended Competencies", icon: PresentationChartLineIcon, current: false },
    ],
  })

  const handleNavSwitch = tabName => {
    const newSideBarState = sidebarNavState[currentTab].map(tab =>
      tab.name === tabName ? { ...tab, current: true } : { ...tab, current: false },
    )
    setSidebarNavState(prevState => ({
      ...prevState,
      [currentTab]: newSideBarState,
    }))

    setCurrentSection(tabName)
  }

  return (
    <>
      <Dialog open={sidebarOpen} onClose={setSidebarOpen} className="relative z-50 lg:hidden top-16">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
        />

        <div className="fixed inset-0 flex">
          <DialogPanel
            transition
            className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full">
            <TransitionChild>
              <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                <button type="button" onClick={() => setSidebarOpen(false)} className="-m-2.5 p-2.5">
                  <span className="sr-only">Close sidebar</span>
                  <XMarkIcon aria-hidden="true" className="size-6 text-white" />
                </button>
              </div>
            </TransitionChild>
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
              <div className="flex h-16 shrink-0 items-center">
                <img alt="Your Company" src={pareLogo} className="h-8 w-auto rounded-xl" />
              </div>
              <nav className="flex flex-1 flex-col">
                <ul role="list" className="flex flex-1 flex-col gap-y-7">
                  <li>
                    <ul role="list" className="-mx-2 space-y-1">
                      {sidebarNavState[currentTab].map(item => (
                        <li key={item.name}>
                          <div
                            onClick={() => handleNavSwitch(item.name)}
                            className={classNames(
                              item.current
                                ? "bg-gray-50 text-indigo-600"
                                : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                              "group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold cursor-pointer",
                            )}>
                            <item.icon
                              aria-hidden="true"
                              className={classNames(
                                item.current ? "text-indigo-600" : "text-gray-400 group-hover:text-indigo-600",
                                "size-6 shrink-0",
                              )}
                            />
                            {item.name}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </DialogPanel>
        </div>
      </Dialog>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:top-16 lg:fixed lg:inset-y-0  lg:flex lg:w-72 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
          <nav className="flex flex-1 flex-col relative top-6">
            <ul role="list" className="flex flex-1 flex-col gap-y-7 ">
              <li>
                <ul role="list" className="-mx-2 space-y-1">
                  {sidebarNavState[currentTab].map(item => (
                    <li key={item.name}>
                      <div
                        onClick={() => handleNavSwitch(item.name)}
                        className={classNames(
                          item.current
                            ? "bg-gray-50 text-indigo-600"
                            : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                          "group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold cursor-pointer",
                        )}>
                        <item.icon
                          aria-hidden="true"
                          className={classNames(
                            item.current ? "text-indigo-600" : "text-gray-400 group-hover:text-indigo-600",
                            "size-6 shrink-0",
                          )}
                        />
                        {item.name}
                      </div>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  )
}
