import { ExclamationCircleIcon, EyeIcon } from '@heroicons/react/20/solid';
import React, { useState } from "react";

export default function Input({
  id,
  label,
  name,
  type = 'text',
  placeholder,
  value,
  onChange,
  hasError = false,
  errorMessage = '',
  helpText = '',
  icon = null, // Pass the icon component, e.g., <EnvelopeIcon />
  iconPosition = 'left', // 'left' or 'right'
}) {
  const [showPassword, setShowPassword] = useState(false)
  const [showHelpText, setShowHelpText] = useState(false)

  return (
    <div>
      {/* Label */}
      {label && (
        <label htmlFor={id} className="block text-sm font-medium leading-6 text-gray-900">
          {label}
        </label>
      )}

      {/* Input with optional icon */}
      <div className={`relative mt-2 rounded-md shadow-sm flex flex-row items-center gap-x-2`}>
        {/* Icon positioning */}
        {icon && iconPosition === 'left' && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            {icon}
          </div>
        )}
        <input
          id={id}
          name={name}
          type={type === "password" && !showPassword ? "password" : 'text'}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          className={`block w-full rounded-md border-0 py-1.5 ${icon && iconPosition === 'left' ? 'pl-10' : ''} 
            ${icon && iconPosition === 'right' ? 'pr-10' : ''}
            text-gray-900 shadow-sm ring-1 ring-inset ${
            hasError
              ? 'ring-red-300 placeholder:text-red-300 focus:ring-red-500'
              : 'ring-gray-300 placeholder:text-gray-400 focus:ring-indigo-600'
            } sm:text-sm sm:leading-6`}
          aria-invalid={hasError}
          aria-describedby={hasError ? `${id}-error` : helpText ? `${id}-description` : undefined}
          onFocus={() => setShowHelpText(true)}
          onBlur={() => setShowHelpText(false)}
        />
        {/* Icon for right position */}
        {icon && iconPosition === 'right' && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            {icon}
          </div>
        )}
        {/* Error Icon */}
        {hasError && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon aria-hidden="true" className="h-5 w-5 text-red-500" />
          </div>
        )}
        {type === 'password' ? (showPassword ? <EyeIcon aria-hidden="true"
          className="h-5 w-4 text-pareBlue mr-1 cursor-pointer"
          onClick={() => setShowPassword(!showPassword)} />
          : <EyeIcon aria-hidden="true"
            className="h-5 w-4 text-black mr-1 cursor-pointer"
            onClick={() => setShowPassword(!showPassword)} />)
          : null}
      </div>

      {/* Error Message */}
      {hasError && errorMessage && (
        <p id={`${id}-error`} className="mt-2 text-sm text-red-600">
          {errorMessage}
        </p>
      )}

      {/* Help Text */}
      {showHelpText && !hasError && (
        <p id={`${id}-description`} className="mt-2 text-sm text-gray-500 break-words whitespace-pre-line">
          {helpText}
        </p>
      )}
    </div>
  );
}
