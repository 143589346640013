// LearningPathCard.jsx

import React from "react"
import { useNavigate } from "react-router-dom"
import { UserCircleIcon, ClipboardDocumentListIcon } from "@heroicons/react/20/solid"
import ActionDropdown from "./actionDropdown"

const LearningPathCard = ({ learningPath, onEdit, onDelete }) => {
  const navigate = useNavigate()

  const handleCardClick = () => {

    // navigate(`/learningPaths/${learningPath.learningPathId}`)
  }

  return (
    <div
      className="w-[280px] m-[10px] rounded-[5px] relative border border-transparent hover:border-blue-500 cursor-pointer overflow-hidden"
      onClick={handleCardClick}>
      <h2 className="sr-only">Learning Path Summary</h2>
      <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5 h-full flex flex-col">
        {/* Top Section: Title & Action Menu */}
        <div className="flex items-center justify-between px-6 h-[60px]">
          <span className="text-sm font-semibold text-gray-900 line-clamp-2">{learningPath.displayName}</span>
          <ActionDropdown
            actions={[
              { label: "Edit", onClick: () => onEdit(learningPath) },
              { label: "Delete", onClick: () => onDelete(learningPath.learningPathId) },
            ]}
          />
        </div>
        <div className="border-t border-gray-200" />

        {/* Middle Section: Created By & Modules */}
        <div className="px-6 h-[110px] flex flex-col justify-center gap-y-2">
          <div className="flex items-center gap-x-2">
            <UserCircleIcon aria-hidden="true" className="h-6 w-6 text-gray-400" />
            <span className="text-sm text-gray-500">Created by: {learningPath.createdBy || "Unknown"}</span>
          </div>
          <div className="flex items-center gap-x-2">
            <ClipboardDocumentListIcon aria-hidden="true" className="h-6 w-6 text-gray-400" />
            <span className="text-sm text-gray-500">
              Modules: {learningPath.moduleIds ? learningPath.moduleIds.length : 0}
            </span>
          </div>
        </div>
        <div className="border-t border-gray-200" />

        {/* Bottom Section: Description */}
        <div className="px-6 py-6 h-[110px] overflow-hidden">
          <p className="text-sm text-gray-700 line-clamp-3 overflow-hidden">
            {learningPath.description || "No description available."}
          </p>
        </div>
      </div>
    </div>
  )
}

export default LearningPathCard
