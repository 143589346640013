import { types, Instance, flow, SnapshotIn } from "mobx-state-tree"
import { getAttempt, getVideo, getAttemptsByUserId } from "../../api/routes"
import { toJS } from "mobx"

const TranscriptObjectFieldsModel = types
  .model("TranscriptObjectFields", {
    text: types.string,
    user: types.string,
    start: types.string,
    end: types.string,
  })

const AttemptFieldsModel = types
  .model("AttemptFields", {
    attemptId: types.string,
    attemptTime: types.number,
    comments: types.optional(types.string, ""),
    displayName: types.string,
    duration: types.number,
    gradingreport: types.optional(types.string, ""),
    isAnalysisDone: types.boolean,
    moduleId: types.string,
    recordingS3Location: types.string,
    score: types.number,
    status: types.string,
    videoUrl: types.maybeNull(types.string)
  })

const AttemptListFieldsModel = types
  .model("AttemptListFields", {
    attemptList: types.array(AttemptFieldsModel)
  })

export type AttemptListFieldsSnapshotIn = SnapshotIn<typeof AttemptListFieldsModel>
export type AttemptFieldsSnapshotIn = SnapshotIn<typeof AttemptFieldsModel>


export const AttemptListStoreModel = types
  .model("AttemptsStore", {
    userAttempts: types.maybeNull(AttemptListFieldsModel),
  })
  .actions(self => ({
    getSingleUserAttempt: flow(function* getSingleUserAttempt(targetId, targetUserId, attemptId, user_id) {
      try {
        const attemptInfoRes = yield getAttempt(targetId, targetUserId, attemptId, user_id)
        const attemptVideoInfo = [
          { userId: attemptInfoRes.data.userId, attemptId: attemptInfoRes.data.attemptId, recordingS3Location: attemptInfoRes.data.recordingS3Location }
        ]
        const videoRes = yield getVideo(JSON.stringify(attemptVideoInfo), user_id)
        attemptInfoRes.data['videoUrl'] = videoRes.data[attemptId]

        if (attemptInfoRes.kind === "ok") {
          return attemptInfoRes.data || {}
        }
      } catch (e) {
        console.log('ERROR', e)
        return false
      }
      console.log('ERROR 2')
      return false
    }),
    getAttemptTranscript: flow(function* getAttemptTranscript(targetId, targetUserId, attemptId, user_id) {
      try {
        const attemptInfoRes = yield getAttempt(targetId, targetUserId, attemptId, user_id)
        if (attemptInfoRes.kind === "ok") {
          return attemptInfoRes.data.transcript || []
        }
      } catch (e) {
        console.log('ERROR', e)
        return false
      }
      console.log('ERROR 2')
      return false
    }),
    getUserAttempts: flow(function* getUserAttempts(targetId, targetUserId, initial, user_id) {
      try {
        const attemptInfoRes = yield getAttemptsByUserId(targetId, targetUserId, user_id)
        const attemptVideoInfo = attemptInfoRes.data.map(attempt => {
          return { userId: attempt.userId, attemptId: attempt.attemptId, recordingS3Location: attempt.recordingS3Location }
        })

        const videoRes = yield getVideo(JSON.stringify(attemptVideoInfo), user_id)
        const attemptsWithVideo = attemptInfoRes.data.map(rep => {
          rep['videoUrl'] = videoRes.data[rep.attemptId]
          return rep
        })
        if (attemptInfoRes.kind === "ok") {
          if (initial) {
            self.userAttempts = AttemptListFieldsModel.create({
              attemptList: attemptsWithVideo.map(attempt => AttemptFieldsModel.create(attempt))
            })
          } else {
            self.userAttempts.attemptList.concat(attemptsWithVideo.map(attempt => AttemptFieldsModel.create(attempt)))
          }
          return true
        }
      } catch (e) {
        console.log('ERROR', e)
        return false
      }
      console.log('ERROR 2')
      return false
    }),
    adaptAttemptsToStore: flow(function* adaptAttemptsToStore(attempts, initial, user_id) {
      try {
        const attemptVideoInfo = attempts.map(attempt => {
          return { userId: attempt.userId, attemptId: attempt.attemptId, recordingS3Location: attempt.recordingS3Location }
        })
        console.log(attemptVideoInfo)

        const videoRes = yield getVideo(JSON.stringify(attemptVideoInfo), user_id)
        const attemptsWithVideo = attempts.map(rep => {
          rep['videoUrl'] = videoRes.data[rep.attemptId]
          return rep
        })
        if (videoRes.kind === "ok") {
          if (initial) {
            self.userAttempts = AttemptListFieldsModel.create({
              attemptList: attemptsWithVideo.map(attempt => AttemptFieldsModel.create(attempt))
            })
          } else {
            self.userAttempts.attemptList.concat(attemptsWithVideo.map(attempt => AttemptFieldsModel.create(attempt)))
          }
          return true
        }
      } catch (e) {
        console.log('ERROR', e)
        return false
      }
      console.log('ERROR 2')
      return false
    }),
    getSingleAttempt(attemptId: string) {
      if (!self.userAttempts) return null

      return self.userAttempts.attemptList.find(attempt => attempt.attemptId === attemptId) || null
    },
    getAttempts(attempts) {
      if (!self.userAttempts) return []

      const selectedAttemptIds = new Set(attempts.map(attempt => attempt.attemptId));
      const availableAttempts = toJS(self.userAttempts.attemptList)

      return availableAttempts.filter(attempt => selectedAttemptIds.has(attempt.attemptId))
    },
    isAttemptFetched(attemptId: string) {
      if (!self.userAttempts) return false

      return self.userAttempts.attemptList.find(attempt => attempt.attemptId === attemptId) || false
    },
    isAttemptBeingProcessed() {
      if (!self.userAttempts) return false

      return self.userAttempts.attemptList.find(attempt => attempt.isAnalysisDone === false) || false
    },
    isAllAttemptsByUserIdFetched(attempts) {
      if (!self.userAttempts) return false

      const fetchedAttempts = self.userAttempts.attemptList.reduce((acc, x) => {
        if (attempts.includes(x.attemptId)) {
          acc.push(x.attemptId)
        }
        return acc
      }, [])

      return attempts.length === fetchedAttempts.length || false;
    },
    updateAttemptStatus(attemptId: string, status: string, comments: string) {
      if (!self.userAttempts) return false

      const index = self.userAttempts.attemptList.findIndex(attempt => attempt.attemptId === attemptId)
      if (index === -1) return false;
      self.userAttempts.attemptList[index].status = status
      self.userAttempts.attemptList[index].comments = comments

      return true
    }
  }))


export type AttemptListStore = Instance<typeof AttemptListStoreModel>


