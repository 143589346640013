import React, { useState } from 'react'
import MyTeamPage from "../../../pages/MyTeamPage/MyTeamPage"


const UsersSection = ({ }) => {

  return (
    <div className=" max-w-7xl flex-1 px-4">
      <div class="flex flex-1 flex-col items-start justify-between gap-x-8 gap-y-4 py-4 sm:flex-row sm:items-center rounded">
        <MyTeamPage></MyTeamPage>
      </div>

    </div>
  )
}

export default UsersSection
