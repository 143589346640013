import React from "react"


const Tabs = ({ tabNames, setSelectedTab, selectedTab }) => {

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
  }
  return (<header className=" border-gray-200 mb-3">
    <div className="mt-3 sm:mt-4">
      {/* Mobile dropdown */}
      <div className="sm:hidden">
        <label htmlFor="current-tab" className="sr-only">
          Select a tab
            </label>
        <select
          id="current-tab"
          name="current-tab"
          defaultValue={tabNames.find(tab => tab.key === selectedTab).name}
          onChange={e => setSelectedTab(tabNames.find(tab => tab.name === e.target.value).key)}
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
          {tabNames.map(tab => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>

      {/* Desktop tabs */}
      <div className="hidden sm:block">
        <nav className="-mb-px flex space-x-8">
          {tabNames.map(item => (
            <button
              key={item.name}
              onClick={() => setSelectedTab(item.key)}
              aria-current={selectedTab === item.key ? "page" : undefined}
              className={classNames(
                selectedTab === item.key
                  ? "border-indigo-500 text-indigo-600"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium",
              )}>
              {item.name}
            </button>
          ))}
        </nav>
      </div>
    </div>
  </header>)

}

export default Tabs