import React, { useState, useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { NotificationContext } from "../../contexts/notificationContext"
import { toJS } from "mobx";
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"
import ManagerDevPageSkeleton from "../../components/ManagerDevPage/skeletons"
import PersonaBox from "../../components/PersonaBox/PersonaBox"
import PersonaCreationPopUp from "../../components/PersonaLibrary/PersonaCreationPopUp"
import PersonaPreview from "../../components/PersonaLibrary/PersonaPreview"
import AddButton from "../../components/Buttons/addButton"
import EmptyState from "../../components/Global/emptyState"
import { UserIcon } from "@heroicons/react/20/solid"



const PersonaLibraryPage = () => {

  const { triggerNotification } = useContext(NotificationContext)
  const { userStore, personaStore } = useStores()
  const { name, company, userId } = userStore.user || {}
  const [pageLoading, setPageLoading] = useState(true)
  const [searchBarInput, setSearchBarInput] = useState("")
  const [showCreationPopUp, setShowCreationPopUp] = useState(false)
  const [openPersonaPreview, setOpenPersonaPreview] = useState(false)
  const [selectedPersonaId, setSelectedPersonaId] = useState(null)
  const personaList = toJS(personaStore.userPersonas ?.personaList) || []


  useEffect(() => {
    const fetchPersonas = async () => {
      if (!personaStore.userPersonas) {
        const res = await personaStore.getPersonas(company, userId)
        if (res.kind === 'error') {
          triggerNotification("error", "Error when retrieving personas")
        }
      }
      setPageLoading(false)
    }

    fetchPersonas()
  }, [])

  const handleSearchBarChange = e => {
    e.preventDefault()
    setSearchBarInput(e.target.value)
  }


  const handleCreateNewPersonaClick = () => {
    setShowCreationPopUp(true)
  }



  const creationPopUp = showCreationPopUp ? (
    <PersonaCreationPopUp
      setShowCreationPopUp={setShowCreationPopUp}
      createdByName={name}
      company={company}
      userId={userId}></PersonaCreationPopUp>
  ) : null

  const personaPreview = <PersonaPreview
    showPersonaPreview={openPersonaPreview}
    setOpenPersonaPreview={setOpenPersonaPreview}
    isAdminConsole={true}
    selectedPersonaInfo={personaList.find(persona => persona.personaId === selectedPersonaId)}
  ></PersonaPreview>


  if (pageLoading) {
    return <ManagerDevPageSkeleton />
  }

  return (
    <div class="w-full">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <div class="flex flex-row">
            <UserIcon aria-hidden="true" className="h-5 w-4 text-pareBlue mr-1 mt-1" />
            <h1 className="font-semibold text-lg text-grey">Persona Library</h1>
          </div>
          <p className="mt-1 text-xs leading-6 text-gray-400">View, edit, and create personas</p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <AddButton buttonLabel="Create persona" buttonFunction={handleCreateNewPersonaClick}></AddButton>
        </div>
      </div>
      <div class="content-center align-items flex gap-5">
        <input
          type="text"
          placeholder="Search Personas..."
          className="rounded border border-gray-300 px-3 py-2 text-sm w-[300px] my-4"
          value={searchBarInput}
          onChange={handleSearchBarChange}></input>
      </div>
      {personaList.length == 0 ? (
        <div className="w-full h-[40%] flex flex-col items-center justify-center">
          <EmptyState
            header="No existing personas"
            subHeader="Build and design personas for reps to practice"
            buttonLabel="Create persona"
            buttonFunction={handleCreateNewPersonaClick}
            buttonIcon="build"
            icon="user"></EmptyState>
        </div>
      ) : (
          <div className="w-full h-full rounded-md shadow-sm flex flex-row flex-wrap gap-4">
            {searchBarInput.length > 0
              ? personaList
                .filter(x => x.displayName.match(new RegExp(`^${searchBarInput}.*`, "i")))
                .map((x, i) => (
                  <div key={i}>
                    <PersonaBox
                      personaInfo={x}
                      setOpenPersonaPreview={setOpenPersonaPreview}
                      setSelectedPersonaId={setSelectedPersonaId}></PersonaBox>
                  </div>
                ))
              : personaList.map((x, i) => (
                <div key={i}>
                  <PersonaBox
                    personaInfo={x}
                    setOpenPersonaPreview={setOpenPersonaPreview}
                    setSelectedPersonaId={setSelectedPersonaId}></PersonaBox>
                </div>
              ))}
          </div>
        )}
      {creationPopUp}
      {personaPreview}
    </div>
  )
}

export default observer(PersonaLibraryPage)
