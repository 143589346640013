import React from "react"
import { useNavigate } from "react-router-dom"
import "./IndividualActivityBox.css"
import { useStores } from "../../models/root-store"
import { FlagIcon, ClockIcon, CalendarDaysIcon, PhoneIcon } from "@heroicons/react/20/solid"

const IndividualActivityBox = ({ composite }) => {
  const { userStore } = useStores()
  const { userId } = userStore.user || {}
  let navigate = useNavigate()

  const toRecordingDetails = () => {
    navigate("/assignmentLandingPage", { state: composite }) // {module: Module, assignment: Assignment}
  }

  const status = composite.userAssignment.status
  const passedModule = status === "Passed"
  const failedAttempt = status === "Not Passed"
  const pendingReview = status === "Pending"
  const completedActivity = passedModule
  const analysisInProgress = status === "In Progress"
  const individualDueDate = composite.userAssignment.dueDate
  const friendlyFormatDate = individualDueDate ? `Due ${new Date(individualDueDate).toDateString()}` : "N/A"
  const stringToDate = new Date(individualDueDate)
  const epochDueDate = stringToDate.getTime()
  const today = new Date().toDateString()
  const epochToday = new Date(today).getTime()
  const overdue = epochDueDate < epochToday

  return (
    <div
      className="lg:col-start-3 lg:row-end-1 w-[280px] h-[280px] m-[10px] rounded-[5px] relative border border-grey hover:border-pareBlue cursor-pointer"
      onClick={toRecordingDetails}>
      <h2 className="sr-only">Summary</h2>
      <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5 h-full">
        <dl className="flex flex-wrap">
          <div className="flex flex-col pl-6 pt-4 pr-2">
            <FlagIcon aria-hidden="true" className="h-5 w-4 text-pareBlue mr-1" />
            <dt className="max-w-[180px] h-[30px] pt-2 break-words whitespace-normal text-sm font-semibold text-gray-900 line-clamp-2">
              {composite.module.displayName}
            </dt>
          </div>
          <div className="absolute right-0 top-0 self-end px-4 pt-4">
            <dt className="sr-only">Status</dt>
            {overdue && !analysisInProgress && !completedActivity && !pendingReview ? (
              <dd className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                Overdue
              </dd>
            ) : passedModule ? (
              <dd className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                Passed
              </dd>
            ) : pendingReview ? (
              <dd className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20">
                In Review
              </dd>
            ) : failedAttempt ? (
              <dd className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                Not Passed
              </dd>
            ) : (
              <dd className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-pareBlue ring-1 ring-inset ring-blue-600/20">
                Active
              </dd>
            )}
          </div>
          <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-2">
            <dt className="flex-none">
              <span className="sr-only">Client</span>
              <ClockIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
            </dt>
            <dd className="text-sm/6 text-gray-500">{`${composite.module.timeLimit} minutes`}</dd>
          </div>
          <div className="mt-4 flex w-full flex-none gap-x-4 px-6 overflow-hidden text-ellipsis">
            <dt className="flex-none">
              <span className="sr-only">Due date</span>
              <CalendarDaysIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
            </dt>
            <dd className="text-sm/6 text-gray-500">
              <div>{`${friendlyFormatDate}`}</div>
            </dd>
          </div>
          <div className="mt-4 flex w-full flex-none gap-x-4 px-6 text-ellipsis">
            <dt className="flex-none">
              <span className="sr-only">Due date</span>
              <PhoneIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
            </dt>
            <dd className="text-sm/6 text-gray-500 line-clamp-1">
              <p>{composite.module.callType}</p>
            </dd>
          </div>
        </dl>
        <div className="mt-6 border-t border-gray-900/5 px-6 flex justify-center items-center h-[50px]">
          {!analysisInProgress ? (
            <a href="#" className="text-sm/6 font-semibold text-pareBlue">
              Start{" "}
              <span aria-hidden="true" className="text-pareBlue">
                &rarr;
              </span>
            </a>
          ) : (
            <div className="w-full h-full text-sm/6 font-semibold text-pareBlue flex items-center justify-end">
              Analyzing...
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default IndividualActivityBox
