'use client'

import React, { useState, useContext, useEffect } from "react"
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { NotificationContext } from "../../contexts/notificationContext"
import { UserIcon } from '@heroicons/react/20/solid'
import { useStores } from "../../models/root-store"
import DoubleOptionPopUp from "../../components/PopUps/DoubleOptionPopUp"
import { CreatePersonaInstance } from "../../api/routes"
import { useNavigate } from "react-router-dom"



export default function PersonaPreview({
  showPersonaPreview,
  setOpenPersonaPreview,
  isAdminConsole,
  selectedPersonaInfo,
  callTypes,
  personaInstanceView,
  selectedPersonaInstanceInfo
}) {
  const { triggerNotification } = useContext(NotificationContext)
  const { userStore, personaStore } = useStores()
  const { name, userId } = userStore.user || {}
  const companyId = userStore.user.company
  const navigate = useNavigate()
  const [difficulty, setDifficulty] = useState("")
  const [repContextInput, setRepContextInput] = useState("")
  const [company, setCompany] = useState("")
  const [callType, setCallType] = useState("")
  const [editMode, setEditMode] = useState(false)
  const personaInfo = personaInstanceView ? selectedPersonaInstanceInfo : selectedPersonaInfo
  const [editedPersonaInfo, setEditedPersonaInfo] = useState({})
  const [saving, setSaving] = useState(false)
  const [startCallClicked, setStartCallClicked] = useState(false)
  const [generatingPersona, setGeneratingPersona] = useState(false)


  useEffect(() => {
    setEditedPersonaInfo(personaInfo)
  }, [showPersonaPreview])

  const handleStartCallClicked = async () => {
    setGeneratingPersona(true)
    if (personaInstanceView) {
      navigate(`/playgroundChatRoom/${selectedPersonaInstanceInfo.personaId}/${selectedPersonaInstanceInfo.instanceId}`)
    } else {
      const newPersonaInstance = {
        ...personaInfo,
        createdById: userId,
        creationDate: Date.now(),
        createdByName: name,
        repContext: repContextInput,
        difficulty,
        callType,
        company,
        userId,
        user_id: userId,
      }

      const res = await CreatePersonaInstance(newPersonaInstance)
      if (res.kind === 'ok') {
        navigate(`/playgroundChatRoom/${res.data.personaId}/${res.data.instanceId}`)
      } else {
        triggerNotification('error', "There was an error when generating the roleplay")
      }
    }

    setGeneratingPersona(false)
  }

  const attemptDisclaimerPopUp = startCallClicked ? (
    <DoubleOptionPopUp
      header="You are about to jump in the roleplay"
      body={`If you are ready start the module, click start module to be sent to the meeting room\n\n NOTE: The voice you are about to here is AI-generated and not a human voice`}
      leftButtonLabel="Cancel"
      leftButtonFunction={() => setStartCallClicked(false)}
      rightButtonFunction={null}
      rightButtonLabel="Start module"></DoubleOptionPopUp>
  ) : null


  const handleTitleChange = (e) => {
    setEditedPersonaInfo({ ...personaInfo, role: e.target.value })
  }

  const handlePersonalityChange = (e) => {
    setEditedPersonaInfo({ ...personaInfo, personality: e.target.value })
  }

  const handleBackgroundChange = (e) => {
    setEditedPersonaInfo({ ...personaInfo, background: e.target.value })
  }

  const handleAdditionalInfoChange = (e) => {
    setEditedPersonaInfo({ ...personaInfo, additionalInfo: e.target.value })
  }


  const updatePersona = async () => {
    const updatedFields = {
      ...editedPersonaInfo,
      user_id: userId
    }
    setSaving(true)
    const res = await personaStore.updatePersona(companyId, personaInfo.personaId, updatedFields)
    if (res) {
      triggerNotification('success', 'Successfully updated persona')
    } else {
      triggerNotification('error', 'There was an error when updating persona')
    }
    setSaving(false)
    setEditMode(false)
  }

  const readyToRunSim = (repContextInput.length > 15
    && difficulty !== ""
    && company.length > 1
    && callType !== "") || personaInstanceView

  const handleDifficultyChange = e => {
    setDifficulty(e.target.value)
  }
  const handleRepContextInputChange = e => {
    setRepContextInput(e.target.value)
  }
  const handleCompanyChange = e => {
    setCompany(e.target.value)
  }
  const handleCallTypeChange = e => {
    setCallType(e.target.value)
  }

  const handleEditCancel = () => {
    setEditMode(false)
    setEditedPersonaInfo(selectedPersonaInfo)
  }

  const handleDrawerOnClose = () => {
    setOpenPersonaPreview(false)
    setEditMode(false)
  }



  return (
    <Dialog open={showPersonaPreview} onClose={setOpenPersonaPreview} className="relative z-10">
      <div className="fixed inset-0" />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-2xl transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                <div className="px-4 py-6 sm:px-6">
                  <div className="flex items-start justify-between">
                    <DialogTitle className="text-base font-semibold text-gray-900">Persona Profile</DialogTitle>
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        onClick={handleDrawerOnClose}
                        className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-pareBlue"
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon aria-hidden="true" className="size-6" />
                      </button>
                    </div>
                  </div>
                </div>
                {/* Main */}
                <div className="divide-y divide-gray-200">
                  <div className="pb-6">
                    <div className="h-24 bg-pareBlue sm:h-20 lg:h-28" />
                    <div className="-mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6 lg:-mt-16">
                      <div>
                        <div className="-m-1 flex">
                          <div className="inline-flex overflow-hidden rounded-lg border-4 bg-white border-white">
                            <UserIcon className="mx-auto size-24 shrink-0 sm:size-40 lg:size-48 text-pareBlue shrink-0 rounded-full"></UserIcon>

                            {/* <img
                              alt=""
                              src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60"
                              className="size-24 shrink-0 sm:size-40 lg:size-48"
                            /> */}
                          </div>
                        </div>
                      </div>
                      <div className="mt-6 sm:ml-6 sm:flex-1">
                        <div>
                          <div className="flex items-center">
                            <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">{personaInfo ?.displayName || ""}</h3>
                            <span className="ml-2.5 inline-block size-2 shrink-0 rounded-full bg-green-400">
                              <span className="sr-only">Online</span>
                            </span>
                          </div>
                          {/* <p className="text-sm text-gray-500">@ashleyporter</p> */}
                        </div>
                        <div className="mt-5 flex flex-wrap space-y-3 sm:space-x-3 sm:space-y-0">
                          {isAdminConsole ? (editMode ?
                            <>
                              <button
                                type="button"
                                className="inline-flex w-full flex-1 items-center justify-center rounded-md bg-pareBlue px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-pareBlue"
                                onClick={saving ? null : updatePersona}>
                                {saving ? "Saving..." : "Save"}
                              </button>
                              <button
                                disabled={saving}
                                type="button"
                                className="inline-flex w-full flex-1 items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-red-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:gray-200"
                                onClick={handleEditCancel}>
                                Cancel
                            </button>
                            </> :
                            <button
                              type="button"
                              className="inline-flex w-full flex-1 items-center justify-center rounded-md bg-gray-100 px-3 py-2 text-sm font-semibold text-pareBlue shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200 border border-pareBlue"
                              onClick={() => setEditMode(true)}>
                              Edit
                        </button>)
                            : readyToRunSim ?
                              <button
                                disabled={generatingPersona}
                                type="button"
                                onClick={handleStartCallClicked}
                                className="inline-flex w-full flex-1 items-center justify-center rounded-md bg-pareBlue px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-pareBlue"
                              >
                                {generatingPersona ? "Calling..." : "Call"}
                              </button>
                              : <div
                                className="inline-flex w-full flex-1 items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 "
                              >
                                Fill out the information below to proceed
                            </div>}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-5 sm:px-0 sm:py-0">
                    <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                      <div className="sm:flex sm:px-6 sm:py-5">
                        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0 lg:w-48">Title</dt>
                        {editMode ?
                          <textarea
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-md sm:text-sm sm:leading-6 resize"
                            onChange={handleTitleChange}
                            value={editedPersonaInfo ?.role || ""}>
                            CRO
                            </textarea>
                          : <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">{personaInfo ?.role || ""}</dd>}
                      </div>
                      <div className="sm:flex sm:px-6 sm:py-5">
                        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0 lg:w-48">Personality</dt>
                        {editMode ?
                          <textarea
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-md sm:text-sm sm:leading-6 resize"
                            onChange={handlePersonalityChange}
                            value={editedPersonaInfo ?.personality || ""}>
                          </textarea>
                          : <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">{personaInfo ?.personality || ""}</dd>}
                      </div>
                      <div className="sm:flex sm:px-6 sm:py-5">
                        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0 lg:w-48">Background</dt>
                        {editMode ?
                          <textarea
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-md sm:text-sm sm:leading-6 resize"
                            onChange={handleBackgroundChange}
                            value={editedPersonaInfo ?.background || ""}>
                          </textarea>
                          : <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">{personaInfo ?.background || ""}</dd>}
                      </div>
                      <div className="sm:flex sm:px-6 sm:py-5">
                        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0 lg:w-48">Additional Info</dt>
                        {editMode ?
                          <textarea className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-md sm:text-sm sm:leading-6 resize"
                            onChange={handleAdditionalInfoChange}
                            value={editedPersonaInfo ?.additionalInfo || ""}>

                          </textarea>
                          : <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">{personaInfo ?.additionalInfo || "N/A"}</dd>}
                      </div>
                      {!isAdminConsole ? <>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0 lg:w-48">Difficulty</dt>
                          {personaInstanceView ?
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">{personaInfo ?.difficulty || ""}</dd>
                            : <select
                              className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline-2 focus:-outline-offset-2 focus:outline-pareBlue sm:text-sm/6"
                              onChange={handleDifficultyChange}
                            >
                              <option> </option>
                              <option>Easy Going</option>
                              <option>Intermediate</option>
                              <option>Challenging</option>
                            </select>}
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0 lg:w-48">Company Type</dt>
                          {personaInstanceView ?
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">{personaInfo ?.company || ""}</dd>
                            : <input
                              type="text"
                              onChange={handleCompanyChange}
                              className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-pareBlue"
                            />}
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0 lg:w-48">Call Type</dt>
                          {personaInstanceView ?
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">{personaInfo ?.callType || ""}</dd>
                            : <select
                              className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline-2 focus:-outline-offset-2 focus:outline-pareBlue sm:text-sm/6"
                              onChange={handleCallTypeChange}
                              value={callType || ''}
                            >
                              <option value={''} key={''}>{''}</option>
                              {callTypes.map(x => (
                                <option value={x} key={x}>{x}</option>
                              ))}

                            </select>}
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0 lg:w-48">Set the Scene</dt>
                          {personaInstanceView ?
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">{personaInfo ?.repContext || ""}</dd>
                            : <div class="mt-2 w-full">
                              <textarea
                                rows="4"
                                class="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-pareBlue sm:text-sm/6"
                                onChange={handleRepContextInputChange}
                                placeholder="Enter some context specific information to enhance the roleplay... (minimum of 15 characters)"></textarea>
                            </div>}
                        </div>
                      </> : null}
                    </dl>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
