import React, { useState, useEffect, useContext } from "react"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"
import { NotificationContext } from "../../contexts/notificationContext"
import { toJS } from "mobx";
import ManagerDevPageSkeleton from "../../components/ManagerDevPage/skeletons"
import PersonaBox from "../../components/PersonaBox/PersonaBox"
import PersonaInstanceBox from "../../components/PersonaInstanceBox/PersonaInstanceBox"
import PersonaPreview from "../../components/PersonaLibrary/PersonaPreview"
import EmptyState from "../../components/Global/emptyState"
import Tabs from "../../components/Global/tabs"



const PlaygroundPage = () => {
  const { triggerNotification } = useContext(NotificationContext)
  const { userStore, personaStore, organizationStore } = useStores()
  const { company, userId, name } = userStore.user || {}
  const [pageLoading, setPageLoading] = useState(true)
  const [searchBarInput, setSearchBarInput] = useState("")
  const [openPersonaPreview, setOpenPersonaPreview] = useState(false)
  const [selectedPersonaId, setSelectedPersonaId] = useState(null)
  const [selectedInstanceId, setSelectedInstanceId] = useState(null)
  const personaList = toJS(personaStore.userPersonas ?.personaList) || []
  const personaInstanceList = toJS(personaStore.userPersonas ?.personaInstanceList) || []
  const [callTypes, setCallTypes] = useState(['Discovery Call', 'Cold Call'])
  const [selectedTab, setSelectedTab] = useState("Personas")

  const tabNames = [
    { name: "Personas", key: "Personas" },
    { name: "Previous Attempts", key: "Previous Attempts" }
  ]


  useEffect(() => {
    const fetchOrgSettings = async () => {
      if (!organizationStore.organizationConfig) {
        await organizationStore.getOrgSettings(company, userId)
      }
      const orgSettings = toJS(organizationStore.organizationConfig)
      if (orgSettings) {
        setCallTypes(orgSettings.callTypes)
      }
    }

    const fetchPersonas = async () => {
      if (!personaStore.userPersonas) {
        const res = await personaStore.getPersonasAndInstancesByUserId(userId, company)
        if (!res) {
          triggerNotification("error", "Error when retrieving personas")
        }
      }
    }

    const fetchData = async () => {
      try {
        await Promise.all([fetchPersonas(), fetchOrgSettings()])

        // call assignemnts api, check if curr signed in user has assignment and status != in progress || pending
      } catch (error) {
        console.error("Error fetching data:", error)
      } finally {
        // Ensure page loading is set to false after both fetches or if already populated
        setPageLoading(false)
      }
    }

    fetchData()


  }, [])

  const handleSearchBarChange = e => {
    e.preventDefault()
    setSearchBarInput(e.target.value)
  }

  const personaPreview = <PersonaPreview
    showPersonaPreview={openPersonaPreview}
    setOpenPersonaPreview={setOpenPersonaPreview}
    isAdminConsole={false}
    callTypes={callTypes}
    personaInstanceView={selectedTab === "Previous Attempts"}
    selectedPersonaInfo={personaList.find(persona => persona.personaId === selectedPersonaId)}
    selectedPersonaInstanceInfo={personaInstanceList.find(pi => pi.instanceId === selectedInstanceId)}></PersonaPreview>


  if (pageLoading) {
    return <ManagerDevPageSkeleton />
  }

  return (
    <div class="w-full h-full flex flex-col px-8 py-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="font-semibold text-lg text-grey">Playground</h1>
          <p className="mt-2 text-xs leading-6 text-gray-400">View and enagage with personas</p>
        </div>
      </div>
      <Tabs tabNames={tabNames} selectedTab={selectedTab} setSelectedTab={setSelectedTab} ></Tabs>
      <div class="content-center align-items flex gap-5">
        <input
          type="text"
          placeholder={selectedTab === "Personas" ? "Search personas..." : "Search previous attempts"}
          className="rounded border border-gray-300 px-3 py-2 text-sm w-[300px] my-4"
          value={searchBarInput}
          onChange={handleSearchBarChange}></input>
      </div>
      {personaList.length == 0 ? (
        <div className="w-full h-[40%] flex flex-col items-center justify-center">
          <EmptyState
            header="No existing personas"
            subHeader="No personas available until they've been created"
            buttonLabel="Create persona"
            buttonFunction={null}
            buttonIcon="build"
            icon="user"></EmptyState>
        </div>
      ) : (
          <>
            {selectedTab === "Personas" ?
              <div className="w-full h-full rounded-md shadow-sm flex flex-row flex-wrap gap-4">
                {searchBarInput.length > 0
                  ? personaList
                    .filter(x => x.displayName.match(new RegExp(`^${searchBarInput}.*`, "i")))
                    .map((x, i) => (
                      <div key={i}>
                        <PersonaBox
                          personaInfo={x}
                          setOpenPersonaPreview={setOpenPersonaPreview}
                          setSelectedPersonaId={setSelectedPersonaId}></PersonaBox>
                      </div>
                    ))
                  : personaList.map((x, i) => (
                    <div key={i}>
                      <PersonaBox
                        personaInfo={x}
                        setOpenPersonaPreview={setOpenPersonaPreview}
                        setSelectedPersonaId={setSelectedPersonaId}></PersonaBox>
                    </div>
                  ))}
              </div>
              : <div className="w-full h-full rounded-md shadow-sm flex flex-row flex-wrap gap-4">
                {searchBarInput.length > 0
                  ? personaInstanceList
                    .filter(x => x.displayName.match(new RegExp(`^${searchBarInput}.*`, "i")))
                    .map((x, i) => (
                      <div key={i}>
                        <PersonaInstanceBox
                          personaInstanceInfo={x}
                          setOpenPersonaPreview={setOpenPersonaPreview}
                          setSelectedInstanceId={setSelectedInstanceId}></PersonaInstanceBox>
                      </div>
                    ))
                  : personaInstanceList.map((x, i) => (
                    <div key={i}>
                      <PersonaInstanceBox
                        personaInstanceInfo={x}
                        setOpenPersonaPreview={setOpenPersonaPreview}
                        setSelectedInstanceId={setSelectedInstanceId}></PersonaInstanceBox>
                    </div>
                  ))}
              </div>}
          </>
        )}
      {personaPreview}
    </div>
  )
}

export default observer(PlaygroundPage)
