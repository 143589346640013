import React from "react"

const CardDescription = ({ title, subtitle, details }) => {
  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="px-4 py-6 sm:px-6">
        <h3 className="text-base font-semibold text-gray-900">{title}</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">{subtitle}</p>
      </div>
      <div className="border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          {details.map((item, index) => (
            <div key={index} className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">{item.label}</dt>
              <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
                {item.isDate
                  ? (() => {
                      const parsedDate = new Date(item.value)
                      return isNaN(parsedDate.getTime()) || parsedDate.getFullYear() < 2020
                        ? "Unknown"
                        : parsedDate.toDateString()
                    })()
                  : item.value}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}

export default CardDescription
