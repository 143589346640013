import React from "react"
import ActionsMenuDropdown from "../ActionsMenuDropdown/ActionsMenuDropdown"
import { FlagIcon, UserCircleIcon, PhoneIcon } from "@heroicons/react/20/solid"

const ActivityBox = ({ moduleInfo, handleMenuPopUp }) => {

  const deleteConversationObj = {
    popUpType: "delete",
    moduleId: moduleInfo.moduleId,
    displayName: moduleInfo.displayName,
  }
  const renameConversationObj = {
    popUpType: "rename",
    moduleId: moduleInfo.moduleId,
    displayName: moduleInfo.displayName,
  }
  const deleteActivity = () => {
    handleMenuPopUp(deleteConversationObj)
  }
  const renameActivity = () => {
    handleMenuPopUp(renameConversationObj)
  }

  return (
    <div className="lg:col-start-3 lg:row-end-1 w-[280px]  m-[10px] rounded-[5px] relative border border-transparent hover:border-pareBlue cursor-pointer">

      <h2 className="sr-only">Summary</h2>
      <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
        <dl className="flex flex-wrap">
          <div className="flex flex-col px-6 pt-4">
            <dt className="max-w-[180px] break-words whitespace-normal text-sm font-semibold text-gray-900 line-clamp-1">
              {moduleInfo.displayName}
            </dt>
          </div>
          <div className="absolute right-0 top-0 self-end px-6 pt-4">
            <dt className="sr-only">Status</dt>
            <ActionsMenuDropdown
              options={[
                { value: 1, label: "Delete" },
                { value: 2, label: "Rename" },
              ]}
              deleteAction={deleteActivity}
              renameAction={renameActivity}></ActionsMenuDropdown>
          </div>
          <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
            <dt className="flex-none">
              <span className="sr-only">Client</span>
              <UserCircleIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
            </dt>
            <dd className="text-sm text-gray-500 line-clamp-1">Created By: {moduleInfo.createdByName}</dd>
          </div>
          <div className="mt-4 flex w-full flex-none gap-x-4 px-6 overflow-hidden text-ellipsis">
            <dt className="flex-none">
              <span className="sr-only">Due date</span>
              <PhoneIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
            </dt>
            <dd className="text-sm text-gray-500 line-clamp-1 ">
              <div>{moduleInfo.callType}</div>
            </dd>
          </div>
          {/* <div className="mt-4 flex w-full flex-none gap-x-4 px-6 overflow-hidden text-ellipsis">
            <dt className="flex-none">
              <span className="sr-only">Due date</span>
              <FingerPrintIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
            </dt>
            <dd className="text-sm/6 text-gray-500">
              <div >{`Created By Manager Bob`}</div>
            </dd>
          </div> */}
        </dl>
        <div className="mt-6 border-t border-gray-900/5 px-6 py-6">
          <div>
            <label className="absolute left-[10%] top-[88%] w-[86%] text-[0.725rem] font-normal">
              Status:{" "}
              <span className="font-medium text-[#3045ff]">
                {moduleInfo.isCreationDone ? "Creation Complete" : "Creation in Process"}
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ActivityBox
