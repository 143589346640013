import React, { useState } from 'react'
import Integrations from "../../Settings/integrations"


const AppsSection = ({ }) => {

  return (
    <div className="max-w-7xl flex-1 px-4">
      <div class="flex flex-col items-start justify-between gap-x-8 gap-y-4 py-4 sm:flex-row sm:items-center rounded">
        <div>
          <div class="flex items-center gap-x-3">
            <h1 class="flex gap-x-3 text-base leading-7">
              <span class="font-semibold text-lg text-grey">Apps</span>
            </h1>
          </div>
          <p class="mt-2 text-xs leading-6 text-gray-400">
            Integrate your applications for a better experience
          </p>
        </div>
      </div>
      <Integrations></Integrations>
    </div>
  )
}

export default AppsSection
