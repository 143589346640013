import React, { useState, useContext } from "react"
import { MultiSelect } from "react-multi-select-component"
import { AddAssignments } from "../../../src/api/routes"
import { NotificationContext } from "../../contexts/notificationContext"

// Assign Modal Component
const AssignmentModal = ({
  isOpen,
  onClose,
  teamList,
  targetId,
  targetType,
  targetAdditionalInfo,
  assigningUserInfo,
  onAssignmentCompleted,
}) => {
  const [selectedReps, setSelectedReps] = useState([])
  const [dueDate, setDueDate] = useState("")
  const [loading, setLoading] = useState(false)
  const { triggerNotification } = useContext(NotificationContext)

  if (!isOpen) return null

  // Filter and map the team list to generate options
  const filteredRepList = teamList
    .filter(member => member.type !== "Admin") // Exclude Admins
    .map(member => ({
      label: member.name || member.userEmail,
      value: member.userId || member.userEmail,
      role: member.type,
      email: member.userEmail,
      managerName: member.managerName,
    }))

  // Sort options by managerName alphabetically
  const sortedRepOptions = [...filteredRepList].sort((a, b) => {
    if (a.managerName === "UNASSIGNED" && b.managerName !== "UNASSIGNED") return 1
    if (b.managerName === "UNASSIGNED" && a.managerName !== "UNASSIGNED") return -1
    return a.managerName.localeCompare(b.managerName)
  })

  const handleSave = async () => {
    if (selectedReps.length === 0) {
      triggerNotification("error", "Please select at least one user to assign.")
      return
    }

    if (!dueDate) {
      triggerNotification("error", "Please set a due date.")
      return
    }

    if (Date.parse(dueDate) < Date.now()) {
      triggerNotification("error", "Due date must be at least a day from now.")
      return
    }

    setLoading(true)

    // Create assignments
    const assignments = selectedReps.map(rep => {
      const teamMember = teamList.find(member => member.userId === rep.value || member.userEmail === rep.value)
      const moduleStatuses = targetAdditionalInfo.moduleIds
        ? targetAdditionalInfo.moduleIds.reduce((statuses, moduleId) => {
            statuses[moduleId] = "Assigned" // Default status is "Assigned"
            return statuses
          }, {})
        : undefined

      return {
        userId: teamMember.userId,
        userName: teamMember.name || teamMember.userEmail,
        userEmail: teamMember.userEmail,
        managerId: teamMember.managerId,
        companyId: assigningUserInfo.company,
        targetId,
        description: targetAdditionalInfo.description,
        progress: moduleStatuses ? { moduleStatuses } : undefined,
        type: targetType,
        displayName: targetAdditionalInfo.displayName,
        assignedDate: new Date().toISOString(),
        assignedBy: assigningUserInfo.name,
        dueDate,
        user_id: assigningUserInfo.userId,
      }
    })

    console.log(`ASSIGNMENT DUE DATE: ${assignments[0].dueDate} ASSIGNMENT CD: ${assignments[0].assignedDate}`)

    try {
      const response = await AddAssignments(assignments)
      if (response.kind != "ok") {
        triggerNotification("error", "Failed to add assignments. Please try again.")
      } else {
        if (onAssignmentCompleted) {
          await onAssignmentCompleted()
        }
        triggerNotification("Success", "Assignments successfully added.")
      }
      onClose()
    } catch (error) {
      console.error("Error adding assignments:", error)
      triggerNotification("Error", "Failed to add assignments. Please try again.")
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      {/* Modal container */}
      <div className="bg-white rounded-lg shadow-lg max-w-md w-full p-6 relative">
        {/* Modal header */}
        <h2 className="text-lg font-semibold text-gray-900 mb-4">
          Assign {targetAdditionalInfo.moduleType ?? "Learning Path"}
        </h2>
        <p className="text-sm text-gray-600 mb-6">
          Select reps and set a due date for the {targetAdditionalInfo.moduleType ?? "Learning Path"}
        </p>

        {/* MultiSelect Dropdown */}
        <div className="mb-4">
          <label htmlFor="reps" className="block text-sm font-medium text-gray-700 mb-2">
            Select Reps
          </label>
          <MultiSelect
            options={sortedRepOptions}
            value={selectedReps}
            onChange={setSelectedReps}
            labelledBy="Select Reps"
            className="border rounded-md focus:ring-indigo-500 focus:border-indigo-500"
            ItemRenderer={({ checked, option, onClick }) => (
              <div className="flex items-center justify-between p-2 cursor-pointer hover:bg-gray-100" onClick={onClick}>
                <div className="flex items-center">
                  <input type="checkbox" readOnly checked={checked} className="mr-2 h-4 w-4 border-gray-300 rounded" />
                  <span className="text-sm font-medium text-gray-900">{option.label}</span>
                </div>
                <div className="text-xs text-gray-500 text-right">
                  {option.label !== "Select All" && (
                    <div className="text-xs text-gray-500 text-right">
                      {option.role || "Role not specified"}
                      {option.role !== "Admin" &&
                        option.role !== "Manager" &&
                        option.managerName &&
                        (option.managerName === "UNASSIGNED"
                          ? " | Not yet assigned"
                          : ` | ${option.managerName}'s Team`)}
                      <br />
                      {option.email || "Email not available"}
                    </div>
                  )}
                </div>
              </div>
            )}
            styles={{
              menu: provided => ({
                ...provided,
                zIndex: 9999,
                maxHeight: "150px",
                overflowY: "auto",
              }),
            }}
          />
        </div>

        {/* Due Date Input */}
        <div className="mb-6">
          <label htmlFor="due-date" className="block text-sm font-medium text-gray-700 mb-2">
            Due Date
          </label>
          <input
            id="due-date"
            type="date"
            value={dueDate}
            onChange={e => setDueDate(e.target.value)}
            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        {/* Modal Actions */}
        <div className="flex justify-end space-x-4">
          <button
            type="button"
            className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300"
            onClick={onClose}
            disabled={loading}>
            Cancel
          </button>
          <button
            type="button"
            className="px-4 py-2 bg-pareBlue text-white rounded-md hover:bg-indigo-700"
            onClick={handleSave}
            disabled={loading}>
            {loading ? "Assigning..." : "Assign"}
          </button>
        </div>
      </div>
    </div>
  )
}

export default AssignmentModal
