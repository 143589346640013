import { Auth, API } from "aws-amplify"

// Helper function that wraps some of the reused logic in API requests
export async function APIQueryBuilder(route: string, params?: Record<string, any>) {
  const token = (await Auth.currentSession()).getAccessToken().getJwtToken()
  const response = await API.get("ZenoApp", route, {
    headers: { Authorization: `Bearer ${token}` },
    queryStringParameters: params || {},
  })
  return response
}

// Genericized function for all API request methods across various APIs. Defaults to ZenoApp
export async function GenericAPIRequest(
  api: string = "ZenoApp",
  method: "get" | "post" | "put" | "delete",
  route: string,
  pathParams: Record<string, any> = {},
  queryParams: Record<string, any> = {},
  body: any = null,
) {
  const token = (await Auth.currentSession()).getAccessToken().getJwtToken()

  // Replace path parameters in the route
  let path = route
  for (const [key, value] of Object.entries(pathParams)) {
    path = path.replace(`{${key}}`, encodeURIComponent(value))
  }

  // Set up the request options
  const init: any = {
    headers: { Authorization: `Bearer ${token}` },
    queryStringParameters: queryParams,
  }

  // Include body for POST and PUT requests
  if (body && (method === "post" || method === "put" || method === "delete")) {
    init.body = body
  }

  // Make the API request based on the method
  switch (method.toLowerCase()) {
    case "get":
      return await API.get(api, path, init)
    case "post":
      return await API.post(api, path, init)
    case "put":
      return await API.put(api, path, init)
    case "delete":
      return await API.del(api, path, init)
    default:
      throw new Error(`Unsupported method: ${method}`)
  }
}