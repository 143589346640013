import React, { useState, useEffect, useContext } from "react"
import LearningPathCard from "./learningPathCard"
import CreateEditLearningPathModal from "./learningPathModal"
import ConfirmDeleteModal from "../Global/confirmDeleteModal"
import ManagerDevPageSkeleton from "../../components/ManagerDevPage/skeletons"
import { NotificationContext } from "../../contexts/notificationContext"
import { observer } from "mobx-react-lite"
import { toJS } from "mobx"
import { useStores } from "../../models/root-store"
import { RectangleStackIcon } from "@heroicons/react/20/solid"
import EmptyState from "../Global/emptyState"
import CreateButton from "../../components/Buttons/createButton"

const LearningPaths = observer(({ setLearningPathId, setCurrentContentSubSection }) => {
  const { userStore, learningPathStore } = useStores()
  const [showModal, setShowModal] = useState(false)
  const [selectedLearningPath, setSelectedLearningPath] = useState(null)
  const [searchQuery, setSearchQuery] = useState("")
  const [loading, setLoading] = useState(true)
  const learningPaths = toJS(learningPathStore.userLearningPaths ?.learningPathList) || []

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [learningPathToDelete, setLearningPathToDelete] = useState(null)

  const { triggerNotification } = useContext(NotificationContext)

  // Fetch learning paths on component mount
  useEffect(() => {
    const fetchLearningPaths = async () => {
      setLoading(true)
      const success = await learningPathStore.getLearningPaths(userStore.user.company, userStore.user.userId)
      setLoading(false)
      if (!success) {
        triggerNotification("Error", "Failed to fetch learning paths.")
      }
    }

    if (!learningPathStore.userLearningPaths ?.learningPathList) {
      fetchLearningPaths()
    } else {
      setLoading(false)
    }
  }, [learningPathStore, userStore.user.company, userStore.user.userId, triggerNotification])

  const handleCreate = () => {
    setSelectedLearningPath(null)
    setShowModal(true)
  }

  const handleEdit = learningPath => {
    setSelectedLearningPath(learningPath)
    setShowModal(true)
  }

  const handleDelete = learningPath => {
    setLearningPathToDelete(learningPath)
    setShowDeleteModal(true)
  }

  const confirmDelete = async () => {
    const learningPathId = learningPathToDelete.learningPathId
    setShowDeleteModal(false)
    const success = await learningPathStore.deleteLearningPath(
      userStore.user.company,
      learningPathId,
      userStore.user.userId,
    )
    if (success) {
      triggerNotification("Delete Successful", "Learning path deleted successfully.")
    } else {
      triggerNotification("Error", "An error occurred while deleting the learning path.")
    }
    setLearningPathToDelete(null)
  }

  const closeDeleteModal = () => {
    setShowDeleteModal(false)
    setLearningPathToDelete(null)
  }

  const filteredLearningPaths = learningPaths.filter(lp =>
    lp.displayName.toLowerCase().includes(searchQuery.toLowerCase()),
  )

  const handleLearningPathClick = (learningPathId) => {
    setLearningPathId(learningPathId)
    setCurrentContentSubSection("LearningPathDetail")
  }

  // reuse managerDev skeleton - same format
  if (loading) {
    return <ManagerDevPageSkeleton />
  }

  return (
    <div className=" w-full">
      <div className="flex items-center justify-between mb-6">
        <div>
          <div className="flex flex-row">
            <RectangleStackIcon aria-hidden="true" className="h-5 w-4 text-pareBlue mr-1 mt-1" />
            <h1 className="font-semibold text-lg text-grey">Learning Paths</h1>
          </div>
          <p className="mt-1 text-xs leading-6 text-gray-400">Connect multiple modules to form learning paths</p>
        </div>
        <CreateButton buttonLabel="Create learning path" buttonFunction={handleCreate}></CreateButton>
      </div>

      <div className="flex items-center mb-6">
        <input
          type="text"
          placeholder="Search learning paths..."
          className="mb-8 rounded border-gray-300 w-[500px] h-[35px] text-sm"
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
        />
      </div>

      {filteredLearningPaths.length === 0 ? (
        <div className="mt-20">
          <EmptyState
            header="No existing learning paths"
            subHeader="Build, design, and publish a learning path for your team when you’re ready"
            buttonLabel="Create learning path"
            buttonFunction={handleCreate}
            icon="learningpath"></EmptyState>
        </div>
      ) : (
          <div className="w-full h-full rounded-md shadow-sm flex flex-row flex-wrap">
            {filteredLearningPaths.map(learningPath => (
              <div key={learningPath.learningPathId} onClick={() => handleLearningPathClick(learningPath.learningPathId)}>
                <LearningPathCard
                  key={learningPath.learningPathId}
                  learningPath={learningPath}
                  onEdit={() => handleEdit(learningPath)}
                  onDelete={() => handleDelete(learningPath)}
                />
              </div>
            ))}
          </div>
        )}

      {showModal && (
        <CreateEditLearningPathModal
          onClose={() => setShowModal(false)}
          learningPath={selectedLearningPath}
          onSave={() => setShowModal(false)}
        />
      )}

      {showDeleteModal && learningPathToDelete && (
        <ConfirmDeleteModal
          isOpen={showDeleteModal}
          onClose={closeDeleteModal}
          onConfirm={confirmDelete}
          title="Delete Learning Path"
          message={`Are you sure you want to delete the learning path "${learningPathToDelete.displayName}"? This action cannot be undone.`}
        />
      )}
    </div>
  )
})

export default LearningPaths
