import React, { useState, useEffect } from 'react'
import RadarChart from "../LearningCenter/radarChart";
import BarChart from "../LearningCenter/barChart";
import DropdownSelector from "../LearningCenter/dropdown";
import RviChart from "./rviChart"

export default function OverviewTab({
  radarChartLabels,
  selectedUserEmail,
  selectedUserRadarData,
  radarTopPerformerData,
  subCompetencyOptions,
  selectedSubCompetency,
  handleSubCompetencyChange,
  barChartLabels,
  barChartData,
  barTopPerformerData
}) {


  return (
    <div className="px-4 py-5 sm:px-0 sm:py-0">
      <dl className="space-y-8 sm:space-y-0">
        <h2 className="text-md md:text-lg font-semibold leading-6 text-gray-900 mb-4 mt-4 px-4">
          RVI Trend
        </h2>
        <div className="sm:flex sm:px-6 sm:py-5">
          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
            <RviChart
              data={null}
              userData={null}
              label={"RVI"}
              fieldKey={"value"} />
          </dd>
        </div>
        <h2 className="text-md md:text-lg font-semibold leading-6 text-gray-900 mb-4 px-4">
          Top Performer Resemblance
        </h2>
        <div className="sm:flex sm:px-6 sm:py-5">
          <dt className="text-4xl flex items-center justify-center font-medium text-gray-500 sm:w-40 sm:shrink-0 lg:w-48">
            <div class="w-32 h-32 flex items-center justify-center rounded-full border-4 border-gray-500 bg-transparent">
              N/A
          </div>


          </dt>
          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
            {/* Radar Chart Section with Title */}
            <div className="w-full p-4 border border-gray-300 rounded-md">

              <RadarChart
                labels={radarChartLabels}
                selectedUserEmail={selectedUserEmail}
                selectedUserData={selectedUserRadarData}
                topPerformerData={radarTopPerformerData}
              />
            </div>
          </dd>
        </div>
        <h2 className="text-md md:text-lg font-semibold leading-6 text-gray-900 mb-4 px-4">
          Sub-Competencies
        </h2>
        <div className="sm:flex sm:px-6 sm:py-5">
          <div className="w-full p-4 border border-gray-300 rounded-md">
            <div className="flex justify-between items-center mb-4">

              {/* Hide dropdown on smaller screens, display it below the title */}
              <div className="">
                <DropdownSelector
                  label=""
                  options={subCompetencyOptions} // Pass the list of options
                  value={selectedSubCompetency}
                  onChange={handleSubCompetencyChange}
                />
              </div>
            </div>
            <BarChart
              labels={barChartLabels}
              data={barChartData}
              topPerformerData={barTopPerformerData}
            />
          </div>
        </div>
      </dl>
    </div>
  )
}
