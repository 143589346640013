import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';

// Top Section Skeleton (5% of viewport)
export const HeaderSectionSkeleton = () => (
  <div className="mb-2 w-full">
    <Skeleton height="10vh" />
  </div>
);

// Middle Section Skeleton (15% of viewport)
export const BodySectionSkeleton = () => (
  <div className="w-full">
    <Skeleton height="70vh" />
  </div>
);

export const ModuleAnalysisPageSkeleton = () => (
  <div className="w-full h-screen p-8 flex flex-col gap-4">
    {/* Top Section Skeleton (5% of viewport) */}
    <HeaderSectionSkeleton />

    {/* Middle Section Skeleton (15% of viewport) */}
    <BodySectionSkeleton />

  </div>
);

export default ModuleAnalysisPageSkeleton;

