import { APIQueryBuilder, GenericAPIRequest } from "./helpers"
import {
  GetProfilePictureFromS3Response,
  GetTeamProfilePicturesResponse,
  GetUserInfoResponse,
  GetSalesTeamResponse,
  GetModuleListResponse,
  GetAttemptResponse,
  GetAttemptListResponse,
  GetLearningPathsResponse,
  GenericResponseError,
  GenericNoDataReturnedResponse,
  GetUsageDataResponse,
  GetOrgSettingsConfigResponse,
  AssignmentResponse,
  ModuleV2Response,
  PersonaResponse,
} from "./api.types"
import { LearningPathFieldsSnapshotIn } from "../models/lp-store/lp-store"
import { ModuleListFieldsSnapshotIn } from "../models/module-store/module-store"
import { Assignment, Attempt } from "./nonStoreTypes"
import { ModuleListV2FieldsSnapshotIn, ModuleV2FieldsSnapshotIn } from "../models/module-store/module-store-v2"
import { PersonaFieldsSnapshotIn } from "../models/persona-store/persona-store"

export async function getUserInfo(): Promise<GetUserInfoResponse> {
  try {
    const res = await APIQueryBuilder("/getUserInfo")
    return { kind: "ok", data: res.userInfo }
  } catch (e) {
    return { kind: "error" }
  }
}

export async function getProfilePictureFromS3(user_id: string): Promise<GetProfilePictureFromS3Response> {
  try {
    const res = await APIQueryBuilder("/getProfilePicture", { user_id })
    return { kind: "ok", data: res }
  } catch (e) {
    return { kind: "error" }
  }
}

export async function getTeamProfilePictures(userIds, user_id: string): Promise<GetTeamProfilePicturesResponse> {
  try {
    const stringyfiedUserIds = userIds.join(",")
    const res = await APIQueryBuilder("/getTeamProfilePictures", { stringyfiedUserIds, user_id })
    return { kind: "ok", data: res }
  } catch (e) {
    return { kind: "error" }
  }
}

export async function getVideo(attempts, user_id: string): Promise<GetProfilePictureFromS3Response> {
  try {
    const res = await APIQueryBuilder("/getVideo", { attempts, user_id })
    return { kind: "ok", data: res }
  } catch (e) {
    return { kind: "error" }
  }
}

export async function getSalesTeam(managerId, userType, company, user_id): Promise<GetSalesTeamResponse> {
  try {
    const res = await APIQueryBuilder("/getTeamData", { userType, company, user_id })
    const team = res.teamList
    const inviteeList = res.inviteeList

    return { kind: "ok", data: { managerId, team, inviteeList } }
  } catch (e) {
    return { kind: "error" }
  }
}

export async function getAssignedModulesInfo(company, user_id): Promise<GetModuleListResponse> {
  try {
    const res = await APIQueryBuilder("/getAssignedModules", { company, user_id })
    return { kind: "ok", data: res.involvedActivities }
  } catch (e) {
    return { kind: "error" }
  }
}

export async function getManagerModulesInfo(userType, company, user_id): Promise<GetModuleListResponse> {
  try {
    const res = await APIQueryBuilder("/getManagerActivities", { userType, company, user_id })

    return { kind: "ok", data: res.managerActivities }
  } catch (e) {
    return { kind: "error" }
  }
}

// export async function getUserRecordingInfo(activityId, user_id): Promise<GetRecordingListResponse> {
//   try {
//     const res = await APIQueryBuilder("/getUserRecordings", { activityId, user_id })
//     return { kind: "ok", data: res }
//   } catch (e) {
//     return { kind: "error" }
//   }
// }

// export async function getModuleAttemptsInfo(activityId: string, user_id: string, attemptId: string): Promise<GetRecordingListResponse> {
//   try {
//     const res = await APIQueryBuilder("/getModuleAttempts", { activityId, user_id, attemptId })
//     return { kind: "ok", data: res }
//   } catch (e) {
//     return { kind: "error" }
//   }
// }

export async function getAttemptsByUserId(
  targetId: string,
  targetUserId: string,
  user_id: string,
): Promise<GetAttemptListResponse | GenericResponseError> {
  try {

    const res = await GenericAPIRequest(
      "ZenoApp",
      "get",
      "/assignments/attempts/target/{targetId}/targetUserId/{targetUserId}",
      { targetId, targetUserId },
      { user_id },
    )
    return { kind: "ok", data: res }
  } catch (e) {
    console.error("Error fetching usage data:", e)
    return { kind: "error", error: e }
  }
}

export async function getAttempt(
  targetId: string,
  targetUserId: string,
  attemptId: string,
  user_id: string,
): Promise<GetAttemptResponse | GenericResponseError> {
  try {
    const res = await GenericAPIRequest(
      "ZenoApp",
      "get",
      "/assignments/attempts/target/{targetId}/targetUserId/{targetUserId}/attempt/{attemptId}",
      { targetId, targetUserId, attemptId },
      { user_id },
    )
    return { kind: "ok", data: res }
  } catch (e) {
    console.error("Error fetching usage data:", e)
    return { kind: "error", error: e }
  }
}

export async function getUsageData(
  company: string,
  user_id: string,
): Promise<GetUsageDataResponse | GenericResponseError> {
  try {
    const res = await GenericAPIRequest(
      "ZenoApp",
      "get",
      "/usageStats/{company}",
      { company },
      { user_id, company },
    )
    return { kind: "ok", data: res }
  } catch (e) {
    console.error("Error fetching usage data:", e)
    return { kind: "error", error: e }
  }
}

export async function getOrgSettingsConfig(
  company: string,
  user_id: string,
): Promise<GetOrgSettingsConfigResponse | GenericResponseError> {
  try {

    const res = await GenericAPIRequest(
      "ZenoApp",
      "get",
      "/orgSettings/{company}",
      { company },
      { user_id },
    )

    return { kind: "ok", data: res }
  } catch (e) {
    console.error("Error fetching org settings:", e)
    return { kind: "error", error: e }
  }
}

export async function GetLearningPaths(
  companyId: string,
  user_id: string,
): Promise<GetLearningPathsResponse | GenericResponseError> {
  try {
    const res = await GenericAPIRequest(
      "ZenoApp",
      "get",
      "/learningPath/{companyId}",
      { companyId },
      { user_id: user_id },
    )
    return { kind: "ok", data: res }
  } catch (e) {
    console.error("Error fetching learning paths:", e)
    return { kind: "error", error: e }
  }
}

export async function GetLearningPath(
  companyId: string,
  learningPathId: string,
  user_id: string,
): Promise<GetLearningPathsResponse | GenericResponseError> {
  try {
    const res = await GenericAPIRequest(
      "ZenoApp",
      "get",
      "/learningPath/{companyId}/{learningPathId}",
      { companyId, learningPathId },
      { user_id },
    )
    return { kind: "ok", data: res }
  } catch (e) {
    console.error("Error fetching learning path:", e)
    return { kind: "error", error: e }
  }
}

export async function CreateLearningPath(
  learningPath: Partial<LearningPathFieldsSnapshotIn>,
): Promise<GetLearningPathsResponse | GenericResponseError> {
  try {
    const res = await GenericAPIRequest("ZenoApp", "post", "/learningPath", {}, {}, learningPath)
    return { kind: "ok", data: res }
  } catch (e) {
    console.error("Error creating learning path:", e)
    return { kind: "error", error: e }
  }
}

export async function UpdateLearningPath(
  companyId: string,
  learningPathId: string,
  updatedFields: Partial<LearningPathFieldsSnapshotIn>,
): Promise<GetLearningPathsResponse | GenericResponseError> {
  try {
    const res = await GenericAPIRequest(
      "ZenoApp",
      "put",
      "/learningPath/{companyId}/{learningPathId}",
      { companyId, learningPathId },
      {},
      updatedFields,
    )
    return { kind: "ok", data: res }
  } catch (e) {
    console.error("Error updating learning path:", e)
    return { kind: "error", error: e }
  }
}

export async function DeleteLearningPath(
  companyId: string,
  learningPathId: string,
  user_id: string,
): Promise<GenericNoDataReturnedResponse | GenericResponseError> {
  try {
    await GenericAPIRequest(
      "ZenoApp",
      "delete",
      "/learningPath/{companyId}/{learningPathId}",
      { companyId, learningPathId },
      {},
      { user_id },
    )
    return { kind: "ok", data: null }
  } catch (e) {
    console.error("Error deleting learning path:", e)
    return { kind: "error", error: e }
  }
}

export async function UpdateModule(
  manager_id: string,
  activity_id: string,
  updatedFields: Partial<ModuleListFieldsSnapshotIn>,
): Promise<GenericNoDataReturnedResponse | GenericResponseError> {
  try {
    const res = await GenericAPIRequest(
      "ZenoApp",
      "put",
      "/modules/{manager_id}/{activity_id}",
      { manager_id, activity_id },
      {},
      updatedFields,
    )
    return { kind: "ok", data: res }
  } catch (e) {
    console.error("Error updating module:", e)
    return { kind: "error", error: e }
  }
}

export async function AddAssignments(assignments: Assignment[]): Promise<AssignmentResponse | GenericResponseError> {
  try {
    const res = await GenericAPIRequest("ZenoApp", "post", "/assignments", {}, {}, assignments)
    return { kind: "ok", data: res }
  } catch (e) {
    console.error("Error creating assignment:", e)
    return { kind: "error", error: e }
  }
}

export async function UpdateAssignment(
  targetUserId: string,
  invokingUserId: string,
  type: "Module" | "LearningPath",
  targetId: string,
  updates: Partial<Assignment> & { addAttempt?: Attempt },
): Promise<AssignmentResponse | GenericResponseError> {
  try {
    const requestBody = { userId: targetUserId, user_id: invokingUserId, type, targetId, updates }
    const res = await GenericAPIRequest("ZenoApp", "put", "/assignments", {}, {}, requestBody)
    return { kind: "ok", data: res }
  } catch (e) {
    console.error("Error updating assignment:", e)
    return { kind: "error", error: e }
  }
}

export async function GetAssignmentsByTarget(
  targetId: string,
  assignmentType: "Module" | "LearningPath",
  userId: string,
): Promise<AssignmentResponse | GenericResponseError> {
  try {
    const res = await GenericAPIRequest(
      "ZenoApp",
      "get",
      "/assignments/target/{targetId}",
      { targetId },
      { assignmentType, user_id: userId },
      {},
    )
    return { kind: "ok", data: res }
  } catch (e) {
    console.error("Error getting assignments:", e)
    return { kind: "error", error: e }
  }
}

export async function GetAssignmentsByManager(
  managerId: string,
  userId: string,
): Promise<AssignmentResponse | GenericResponseError> {
  try {
    const res = await GenericAPIRequest(
      "ZenoApp",
      "get",
      "/assignments/manager/{managerId}",
      { managerId },
      { user_id: userId },
      {},
    )
    return { kind: "ok", data: res }
  } catch (e) {
    console.error("Error getting assignments:", e)
    return { kind: "error", error: e }
  }
}

export async function CreateModuleV2(
  moduleData: Partial<ModuleV2FieldsSnapshotIn>,
): Promise<ModuleV2Response | GenericResponseError> {
  try {
    // Matches your new /modules (POST) route
    const res = await GenericAPIRequest("ZenoApp", "post", "/modulesv2", {}, {}, moduleData)
    return { kind: "ok", data: res }
  } catch (e) {
    console.error("Error creating module:", e)
    return { kind: "error", error: e }
  }
}

export async function GetModulesV2(
  companyId: string,
  userId: string,
): Promise<ModuleV2Response | GenericResponseError> {
  try {
    const res = await GenericAPIRequest("ZenoApp", "get", "/modulesv2/{companyId}", { companyId }, { user_id: userId })
    return { kind: "ok", data: res }
  } catch (e) {
    console.error("Error fetching modules:", e)
    return { kind: "error", error: e }
  }
}

export async function GetModuleV2(
  companyId: string,
  moduleId: string,
  userId: string,
): Promise<ModuleV2Response | GenericResponseError> {
  try {
    const res = await GenericAPIRequest(
      "ZenoApp",
      "get",
      "/modulesv2/{companyId}/{moduleId}",
      { companyId, moduleId },
      { user_id: userId },
    )
    return { kind: "ok", data: res }
  } catch (e) {
    console.error("Error fetching module:", e)
    return { kind: "error", error: e }
  }
}

export async function UpdateModuleV2(
  companyId: string,
  moduleId: string,
  updatedFields: Partial<ModuleV2FieldsSnapshotIn>,
): Promise<ModuleV2Response | GenericResponseError> {
  try {
    const res = await GenericAPIRequest(
      "ZenoApp",
      "put",
      "/modulesv2/{companyId}/{moduleId}",
      { companyId, moduleId },
      {},
      updatedFields,
    )
    return { kind: "ok", data: res }
  } catch (e) {
    console.error("Error updating module:", e)
    return { kind: "error", error: e }
  }
}

export async function DeleteModuleV2(
  companyId: string,
  moduleId: string,
  userId: string,
): Promise<GenericNoDataReturnedResponse | GenericResponseError> {
  try {
    await GenericAPIRequest(
      "ZenoApp",
      "delete",
      "/modulesv2/{companyId}/{moduleId}",
      { companyId, moduleId },
      {},
      { user_id: userId },
    )
    return { kind: "ok", data: null }
  } catch (e) {
    console.error("Error deleting module:", e)
    return { kind: "error", error: e }
  }
}

export async function CreatePersona(
  personaData: Partial<PersonaFieldsSnapshotIn>,
): Promise<PersonaResponse | GenericResponseError> {
  try {
    const res = await GenericAPIRequest("ZenoApp", "post", "/personas", {}, {}, personaData)
    return { kind: "ok", data: res }
  } catch (e) {
    console.error("Error creating persona:", e)
    return { kind: "error", error: e }
  }
}

export async function GetPersonas(
  companyId: string,
  userId: string,
): Promise<PersonaResponse | GenericResponseError> {
  try {
    const res = await GenericAPIRequest("ZenoApp", "get", "/personas/{companyId}", { companyId }, { user_id: userId })
    return { kind: "ok", data: res }
  } catch (e) {
    console.error("Error fetching personas:", e)
    return { kind: "error", error: e }
  }
}

export async function GetSinglePersona(
  companyId: string,
  personaId: string,
  userId: string,
): Promise<PersonaResponse | GenericResponseError> {
  try {
    const res = await GenericAPIRequest("ZenoApp", "get", "/personas/{companyId}/{personaId}", { companyId, personaId }, { user_id: userId })
    return { kind: "ok", data: res }
  } catch (e) {
    console.error("Error fetching persona:", e)
    return { kind: "error", error: e }
  }
}

export async function UpdatePersona(
  companyId: string,
  personaId: string,
  updatedFields: Partial<PersonaFieldsSnapshotIn>,
): Promise<PersonaResponse | GenericResponseError> {
  try {
    const res = await GenericAPIRequest(
      "ZenoApp",
      "put",
      "/personas/{companyId}/{personaId}",
      { companyId, personaId },
      {},
      updatedFields,
    )
    return { kind: "ok", data: res }
  } catch (e) {
    console.error("Error updating persona:", e)
    return { kind: "error", error: e }
  }
}

export async function CreatePersonaInstance(
  personaInstanceData: Partial<PersonaFieldsSnapshotIn>,
): Promise<any | GenericResponseError> {

  try {
    const res = await GenericAPIRequest("ZenoApp", "post", "/personaInstances", {}, {}, personaInstanceData)
    return { kind: "ok", data: res }
  } catch (e) {
    console.error("Error creating persona instance:", e)
    return { kind: "error", error: e }
  }
}

export async function getAllPersonaInstancesByUserId(
  userId: string,
): Promise<any | GenericResponseError> {
  try {

    const res = await GenericAPIRequest("ZenoApp", "get", "/personaInstances/{userId}", { userId }, { user_id: userId })
    return { kind: "ok", data: res }
  } catch (e) {
    console.error("Error fetching personas:", e)
    return { kind: "error", error: e }
  }
}
