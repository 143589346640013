import React from "react"
import PlayButton from "../Buttons/playButton"

const ModuleTable = ({ moduleName, completionDist, onTestModuleClick, assigned, isAdminConsole }) => {
  return (
    <header className="pt-4 pb-6">
      {/* Header Section */}
      <div className="flex flex-col items-start justify-between gap-x-8 gap-y-4  py-4 sm:flex-row sm:items-center rounded">
        <div>
          <div className="flex items-center gap-x-3">
            <h1 className="flex gap-x-3 text-base leading-7">
              <span className="font-semibold text-grey">{moduleName}</span>
            </h1>
          </div>
          <p className="mt-2 text-xs leading-6 text-gray-400">{`${moduleName} Summary`}</p>
        </div>
        {!isAdminConsole ? (
          <PlayButton
            buttonLabel={assigned ? "Start module" : "Test module"}
            buttonFunction={onTestModuleClick}></PlayButton>
        ) : null}
      </div>

      {/* Metrics Section */}
      <div className="grid grid-cols-1  sm:grid-cols-2 lg:grid-cols-5">
        {[
          { label: "Passed", count: completionDist.Passed, color: "bg-green" },
          { label: "Needs Review", count: completionDist.Pending, color: "bg-yellow" },
          { label: "In Progress", count: completionDist["In Progress"], color: "bg-pareBlue" },
          { label: "Overdue", count: completionDist.Overdue, color: "bg-red-500" },
          { label: "Not Started", count: completionDist["Not Started"], color: "bg-gray-500" },
        ].map((metric, index) => (
          <div key={index} className={`border-t border-white/5 px-4 py-6 ${index > 0 ? "sm:border-l" : ""} `}>
            <p className="text-sm font-medium leading-6 text-gray-400 flex">
              <div className={`flex-none rounded-full p-1 flex items-center justify-center`}>
                <div className={`h-2 w-2 ${metric.color} rounded-full bg-current`}></div>
              </div>
              {metric.label}
            </p>
            <p className="mt-2 flex items-baseline gap-x-2">
              <span className="text-4xl font-semibold tracking-tight text-grey">{metric.count}</span>
            </p>
          </div>
        ))}
      </div>
    </header>
  )
}

export default ModuleTable
