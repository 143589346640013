import React from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/solid'

export default function ModuleCreationProgressTracker({
  isContextCreationDone,
  isRubricCreationDone,
}) {
  return (
    <div className="flex flex-col items-center w-[5vw] max-w-xl mx-auto space-y-4 px-4">
      {/* Step 1: Context Created */}
      <div className="flex flex-col items-center">
        <div
          className={`w-4 h-4 flex items-center justify-center ${
            isContextCreationDone ? 'bg-pareBlue' : 'bg-gray-300'
            } rounded-full transition-colors duration-300`}
        >
          {/* You can add a checkmark icon here if desired */}
        </div>
        <span
          className={`mt-2 text-xs text-center ${
            isContextCreationDone ? 'text-gray-600' : 'text-gray-300'
            }`}
        >
          Scenario
        </span>
      </div>

      {/* Arrow Icon */}
      <ChevronDownIcon
        className={`w-5 h-5 ${
          isContextCreationDone ? 'text-gray-600' : 'text-gray-300'
          }`}
      />

      {/* Step 2: Rubric Created */}
      <div className="flex flex-col items-center">
        <div
          className={`w-4 h-4 flex items-center justify-center ${
            isRubricCreationDone ? 'bg-pareBlue' : 'bg-gray-300'
            } rounded-full transition-colors duration-300`}
        >
          {/* You can add a checkmark icon here if desired */}
        </div>
        <span
          className={`mt-2 text-xs text-center ${
            isRubricCreationDone ? 'text-gray-600' : 'text-gray-300'
            }`}
        >
          Rubric
        </span>
      </div>

      {/* Arrow Icon */}
      <ChevronDownIcon
        className={`w-5 h-5 ${
          isRubricCreationDone ? 'text-gray-600' : 'text-gray-300'
          }`}
      />

      {/* Step 3: Ready */}
      <div className="flex flex-col items-center">
        <div
          className={`w-4 h-4 flex items-center justify-center ${
            isRubricCreationDone ? 'bg-pareBlue' : 'bg-gray-300'
            } rounded-full transition-colors duration-300`}
        >
          {/* You can add a checkmark icon here if desired */}
        </div>
        <span
          className={`mt-2 text-xs text-center ${
            isRubricCreationDone ? 'text-gray-600' : 'text-gray-300'
            }`}
        >
          Ready
        </span>
      </div>
    </div>
  );
}

