import React from "react"

const AssignmentTable = ({ assignmentsList, moduleCount, onRowClick }) => {
  // Ensure assignmentsList is always an array
  const safeAssignmentsList = Array.isArray(assignmentsList) ? assignmentsList : []

  if (safeAssignmentsList.length === 0) {
    return (
      <div className="mt-2 flow-root bg-white shadow sm:rounded-lg">
        <div className="p-4 text-center text-gray-500 text-sm">
          No users are currently assigned to this learning path.
        </div>
      </div>
    )
  }

  // Map status to display styles
  const statusStyles = {
    Assigned: "bg-gray-100 text-gray-600",
    "In Progress": "bg-blue-100 text-blue-600",
    "Not Passed": "bg-red-100 text-red-600",
    "Pending Review": "bg-yellow-100 text-yellow-600",
    Completed: "bg-green-100 text-green-600",
  }

  return (
    <div className="flow-root bg-white">
      <div className="overflow-x-auto">
        <div className="block min-w-full align-middle">
          <table className="min-w-full divide-y divide-gray-300">
            {/* Table Header */}
            <thead>
              <tr>
                <th scope="col" className="px-7 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Name
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Status
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Completion
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Due Date
                </th>
              </tr>
            </thead>

            {/* Table Body */}
            <tbody className="divide-y divide-gray-200 bg-white">
              {safeAssignmentsList.map((assignment, idx) => {
                const status = assignment.status || "Assigned"
                const completedModules = assignment.progress?.moduleStatuses
                  ? Object.values(assignment.progress.moduleStatuses).filter(
                      moduleStatus => moduleStatus === "Completed",
                    ).length
                  : 0
                return (
                  <tr
                    key={idx}
                    className="group hover:bg-gray-100 cursor-pointer"
                    onClick={() => onRowClick(assignment)}>
                    {/* User Name and Assigned By */}
                    <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm">
                      <div className="flex items-center">
                        <div className="ml-4">
                          <div className="font-medium text-gray-900">{assignment.userName}</div>
                          <div className="mt-1 text-gray-500">Assigned by: {assignment.assignedBy}</div>
                        </div>
                      </div>
                    </td>

                    {/* Assignment Status */}
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      <span
                        className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${statusStyles[status]}`}>
                        {status}
                      </span>
                    </td>

                    {/* Completion Status */}
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      {`${completedModules}/${moduleCount}`}
                    </td>

                    {/* Completion Count */}
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      {new Date(assignment.dueDate).toLocaleDateString("en-US", {
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                      })}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default AssignmentTable
