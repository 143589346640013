import React, { useState } from "react"
import Avatar from "../Global/avatar"

const ActivityTeamViewTableEntry = ({
  assignment,
  getAttempts,
  overdueChecker,
  handleDueDateChange,
  sendReminder,
  isAdminConsole,
}) => {
  const [currentDueDate, setCurrentDueDate] = useState(assignment.dueDate)
  const [reminded, setReminded] = useState(false)
  const handleClick = () => {
    if (!isAdminConsole) {
      getAttempts(assignment.userId, assignment.userName)
    }
  }

  const handleRemindClick = (e, userName, userEmail, displayName, dueDate) => {
    if (!reminded) {
      sendReminder(e, userName, userEmail, displayName, dueDate)
      setReminded(true)
    }
  }

  return (
    <tr
      key={assignment.userId}
      className={`group hover:bg-gray-100 ${!isAdminConsole ? "cursor-pointer" : ""}`}
      onClick={handleClick}>
      <td class="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div class="flex items-center">
          <div class="h-11 w-11 flex-shrink-0">
            <Avatar name={assignment.userName} profilePicture={assignment.profilePicture} size="lg" />
          </div>
          <div class="ml-4">
            <div class="font-medium text-gray-900">{assignment.userName}</div>
            <div class="mt-1 text-gray-500">{assignment.userEmail}</div>
          </div>
        </div>
      </td>
      <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
        {assignment.status === "Assigned" && overdueChecker(currentDueDate) ? (
          <span class="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-500 ">
            Overdue
          </span>
        ) : assignment.status === "Assigned" && !overdueChecker(currentDueDate) ? (
          <span class="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-500 ">
            Not Started
          </span>
        ) : assignment.status === "Pending Review" ? (
          <span class="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-500 ">
            In Review
          </span>
        ) : assignment.status === "Not Passed" || assignment.status === "In Progress" ? (
          <span class="inline-flex items-center rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-pareBlue ">
            In Progress
          </span>
        ) : (
          <span class="inline-flex items-center rounded-md bg-green_light px-2 py-1 text-xs font-medium text-green ">
            Passed
          </span>
        )}
      </td>
      <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
        <div class="mt-1 text-gray-500">
          <input
            type="date"
            className="border-none outline-none group-hover:bg-gray-100 cursor-pointer"
            value={currentDueDate}
            onChange={e => {
              handleDueDateChange(assignment.userId, e.target.value)
              setCurrentDueDate(e.target.value)
            }}
            onClick={e => {
              e.stopPropagation()
            }}></input>
        </div>
      </td>

      <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{assignment.attempts?.length ?? 0}</td>
      <td class="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
        <button
          class="text-pareBlue hover:text-indigo-900"
          onClick={e =>
            handleRemindClick(e, assignment.userName, assignment.userEmail, assignment.displayName, currentDueDate)
          }>
          {reminded ? "Reminded" : "Remind"}
        </button>
      </td>
    </tr>
  )
}

export default ActivityTeamViewTableEntry
