import React, { useState, useEffect } from 'react'
import RolePlayCards from "../LearningCenter/rolePlayCards";

const sampleRolePlays = [
  {
    title: 'Cold Calling Techniques',
    objective: 'Mastering the opening, delivering value propositions, handling rejection, and securing next steps.',
  },
  {
    title: 'Elevator Pitch',
    objective: 'Delivering concise, relevant pitches, capturing interest, and ending with a strong call to action.',
  },
  {
    title: 'Handling Gatekeepers',
    objective: 'Effective communication with gatekeepers to reach decision makers.',
  },
]

export default function InsightsTab() {
  const [rolePlays, setRolePlays] = useState(sampleRolePlays);

  return (
    <div className="px-4 py-5 sm:px-0 sm:py-0">
      <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
        <RolePlayCards rolePlays={rolePlays} />
      </dl>
    </div>
  )
}
