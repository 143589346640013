import React, { Fragment, useState } from "react"
import Avatar from "../Global/avatar"
import AnalysisDrawer from "./analysisDrawer"
import MiniGraph from "../Homedash/miniChart"


const GroupedTeamAnalysisTable = ({
  teamMemberList
}) => {

  const [analysisDrawerOpen, setAnalysisDrawerOpen] = useState(false)
  const [analysisDrawerData, setAnalysisDrawerData] = useState({})

  const managers = teamMemberList.filter(member => member.type === "Manager")

  const getUsersForManager = manager => ({
    teamMembers: teamMemberList.filter(member => member.managerEmail === manager.userEmail)
  })



  return (
    <div className="mt-8 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                  Name
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Group
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  TPR
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Efficiency Index
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Competency 1
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Competency 2
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Competency 3
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Competency 4
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {/* Manager Teams Section */}
              {managers.map(manager => {
                const { teamMembers } = getUsersForManager(manager)
                return (
                  <Fragment key={manager.userId}>
                    <tr className="border-t border-gray-200 ">
                      <th
                        colSpan={8}
                        className="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                        {manager.name}'s Team
                      </th>
                    </tr>
                    {teamMembers.map((member, idx) => (
                      <UserRow
                        key={member.userEmail}
                        user={member}
                        setAnalysisDrawerOpen={setAnalysisDrawerOpen}
                        setAnalysisDrawerData={setAnalysisDrawerData}
                      />
                    ))}
                  </Fragment>
                )
              })}
            </tbody>
          </table>
          <AnalysisDrawer
            open={analysisDrawerOpen}
            setOpen={setAnalysisDrawerOpen}
            analysisDrawerData={analysisDrawerData}></AnalysisDrawer>
        </div>
      </div>
    </div>
  )
}

// Separate component for a user row
const UserRow = ({ user, setAnalysisDrawerOpen, setAnalysisDrawerData }) => {

  const handleAnalysisDrawerOpen = () => {
    setAnalysisDrawerOpen(true)
    setAnalysisDrawerData({ name: user.name })

  }
  return (
    <tr className="hover:bg-gray-100 cursor-pointer"
      onClick={handleAnalysisDrawerOpen}>
      <td className="whitespace-nowrap flex items-center py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
        <Avatar name={user.name} profilePicture={user.profilePicture} size="lg" />
        <div className="ml-4">
          <div>{user.name}</div>
          <div className="mt-1 text-gray-500">{user.email}</div>
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">N/A</td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        N/A
      </td>
      <td className="whitespace-nowrap text-sm text-gray-500">
        {/* <MiniGraph
          data={null}
          userData={null}
          label={"RVI"}
          fieldKey={"value"}
        /> */}
        N/A
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        N/A
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        N/A
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        N/A
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        N/A
      </td>
    </tr>
  )
}

export default GroupedTeamAnalysisTable
