import { FlagIcon, RectangleStackIcon } from "@heroicons/react/20/solid"
import ProgressionModal from "../../components/LearningPath/progressionModal"

export const AssignmentTypeLabels = {
  Activity: "Activity",
  SkillChallenge: "Skill Challenge",
  LearningPath: "Learning Path",
}

export const Kanban = ({
  loading,
  columnConfig,
  groupedAssignments,
  handleAssignmentClick,
  overdueChecker,
  showProgressionModal,
  selectedAssignment,
  availableModules,
  setShowProgressionModal,
}) => {
  return (
    <div className="h-screen px-8">
      {loading ? (
        <div className="text-center text-gray-500">Loading assignments...</div>
      ) : (
        <div className="grid lg:grid-cols-3 gap-5">
          {columnConfig.map(({ title, key }) => (
            <div key={key} className="bg-white rounded px-4 py-2 shadow">
              {/* Column Header */}
              <div className="flex justify-between items-center mb-2">
                <h2 className="text-lg font-semibold text-gray-700">{title}</h2>
                <p className="text-gray-400 text-sm">{groupedAssignments[key]?.length || 0}</p>
              </div>

              {/* Cards */}
              <div className="space-y-2">
                {groupedAssignments[key]?.map(assignment => (
                  <div
                    key={`${assignment.userId}-${assignment.displayName}`}
                    className="p-3 mb-2 border rounded shadow-sm bg-gray-50 hover:bg-gray-300 cursor-pointer"
                    onClick={() => handleAssignmentClick(assignment)}>
                    <div className="flex justify-between">
                      <h3 className="text-sm font-semibold text-gray-700">{assignment.displayName}</h3>
                      {assignment.type === "Activity" ? (
                        <FlagIcon aria-hidden="true" className="h-5 w-4 text-pareBlue" />
                      ) : assignment.type === "LearningPath" ? (
                        <RectangleStackIcon aria-hidden="true" className="h-5 w-4 text-pareBlue" />
                      ) : null}
                    </div>
                    <p className="text-xs text-gray-500 mb-2">Assigned to: {assignment.userName}</p>
                    <p className="text-xs text-gray-500 mb-2">Due Date: {assignment.dueDate}</p>

                    {/* Type Tag */}
                    <span
                      className={`text-xs px-2 py-1 rounded mr-2 ${
                        assignment.type === "Activity"
                          ? "bg-green-200 text-green-800"
                          : assignment.type === "SkillChallenge"
                          ? "bg-purple-200 text-purple-800"
                          : assignment.type === "LearningPath"
                          ? "bg-yellow-200 text-yellow-800"
                          : "bg-gray-200 text-gray-800"
                      }`}>
                      {AssignmentTypeLabels[assignment.type]}
                    </span>

                    {/* Status Tag */}
                    <span
                      className={`text-xs px-2 py-1 rounded mr-2 ${
                        assignment.status === "Assigned"
                          ? "bg-blue-200 text-blue-800"
                          : assignment.status === "In Progress"
                          ? "bg-purple-200 text-purple-800"
                          : assignment.status === "Not Passed"
                          ? "bg-red-200 text-red-800"
                          : assignment.status === "Pending Review"
                          ? "bg-yellow-200 text-yellow-800"
                          : assignment.status === "Completed"
                          ? "bg-green-200 text-green-800"
                          : "bg-gray-200 text-gray-800"
                      }`}>
                      {assignment.status}
                    </span>

                    {/* Overdue Tag */}
                    {overdueChecker(assignment.dueDate) ? (
                      <span className="text-xs px-2 py-1 rounded bg-red-200 text-red-800">Overdue</span>
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}

      {showProgressionModal && selectedAssignment && (
        <ProgressionModal
          isOpen={showProgressionModal}
          onClose={() => setShowProgressionModal(false)}
          assignment={selectedAssignment}
          modules={availableModules}
        />
      )}
    </div>
  )
}
