'use client'

import { useState } from 'react'
import AdminConsoleSidebar from "../../components/AdminConsole/adminConsoleSidebar"
import ContentFolderSection from "../../components/AdminConsole/ContentTab/contentFoldersSection"
import { useStores } from "../../models/root-store"


export default function AdminConsoleContentPage() {

  const { userStore } = useStores()
  const { userId, name, company, profPicSignedUrl } = userStore.user || {}
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [currentSection, setCurrentSection] = useState('Folders')

  const renderContentSection = () => {

    return <ContentFolderSection></ContentFolderSection>
  }

  return (
    <>
      <div className="w-full">
        <AdminConsoleSidebar
          setCurrentSection={setCurrentSection}
          currentTab={"Content"}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}></AdminConsoleSidebar>

        <div className="w-full">
          <main className="py-4 lg:pl-72 sm:pl-0 flex flex-1">
            {renderContentSection()}
          </main>
        </div>
      </div>
    </>
  )
}
