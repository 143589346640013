import React, { useEffect, useState, useContext } from "react"
import { useNavigate, useParams } from "react-router-dom"
import SingleOptionPopUp from "../../components/PopUps/SingleOptionPopUp"
import RubricEditPop from "../../components/PopUps/RubricEditPop"
import ModuleDetails from "../../components/ActivityTeamViewPage/moduleDetails"
import ModuleTable from "../../components/ActivityTeamViewPage/moduleTable"
import ModuleBreadcrumb from "../../components/ActivityTeamViewPage/moduleBreadcrumb"
import AssignedLearnersTable from "../../components/ActivityTeamViewPage/assignedLearnersTable"
import ModuleLeaderboard from "../../components/ActivityTeamViewPage/moduleLeaderboard"
import { GetAssignmentsByTarget, UpdateAssignment } from "../../api/routes"
import { Tabs, TabsList, TabsTrigger } from "../../@shadcn_components/ui/tabs"
import { MediaPermissionsError, MediaPermissionsErrorType, requestMediaPermissions } from "mic-check"
import { GenericAPIRequest } from "../../api/helpers"
import { NotificationContext } from "../../contexts/notificationContext"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"
import { toJS } from "mobx"
import ActivityTeamViewPageSkeleton from "../../components/ActivityTeamViewPage/skeletons"
import EmptyState from "../../components/Global/emptyState"
import AddButton from "../../components/Buttons/addButton"
import AssignmentModal from "../../components/LearningPath/assignmentModal"
import ModuleAttempts from "../../components/ActivityTeamViewPage/moduleAttempts"
import {
  generateCompletionDistribution,
  generateLeaderboardRankings,
} from "../../components/ActivityTeamViewPage/atvUtilities"
import DoubleOptionPopUp from "../../components/PopUps/DoubleOptionPopUp"


const ActivityTeamViewPage = () => {
  const { triggerNotification } = useContext(NotificationContext)
  const { userStore, teamStore, moduleListStoreV2, attemptListStore } = useStores()
  const { userId, type, company } = userStore.user || {}
  const navigate = useNavigate()
  const [leaderboardRankings, setLeaderboardRankings] = useState([])
  const [viewAttempts, setViewAttempts] = useState(false)
  const [attemptsArray, setAttemptsArray] = useState([])
  const [attemptsLoading, setAttemptsLoading] = useState(false)
  const [attemptedName, setAttemptedName] = useState("")
  const [currentTab, setCurrentTab] = useState("Overview")
  const [completionDist, setCompletionDist] = useState({})
  const [attemptData, setAttemptData] = useState({})
  const [pageLoading, setPageLoading] = useState(true)
  const [reminderSent, setReminderSent] = useState(false)
  const [showRubricPopUp, setRubricPopUp] = useState({ show: false, mode: null })
  const [showAssignModal, setShowAssignModal] = useState(false)
  const [assigned, setAssigned] = useState(false)
  const [attemptedUserId, setAttemptedUserId] = useState(null)
  const [startAttemptButtonClicked, setStartAttemtButtonClicked] = useState(false)
  const { moduleId } = useParams()
  const moduleInfo = toJS(moduleListStoreV2.getModuleById(moduleId)) || {}
  const [currentRubric, setCurrentRubric] = useState(moduleInfo.rubric || {})
  const [assignments, setAssignments] = useState([])

  const teamList = toJS(teamStore.teamInfo ?.team) || []

  const getAttempts = async (targetUserId, name) => {
    const targetAssignment = assignments.find(x => x.userId === targetUserId)
    const attempts = targetAssignment.attempts
    if (attempts) {
      setAttemptedName(name)
      setViewAttempts(true)
      setAttemptsLoading(true)

      const attemptCalcs = attempts.reduce(
        (acc, x) => {
          if (x.status == "Passed") {
            acc.passed = true
          }
          if (x.attemptTime > acc.lastAttemptDate) {
            acc.lastAttemptDate = x.attemptTime
          }
          acc.sum += x.duration
          acc.num += 1
          return acc
        },
        { num: 0, sum: 0, passed: false, lastAttemptDate: 0 },
      )
      setAttemptData(attemptCalcs)
      setAttemptedUserId(targetUserId)
      if (!attemptListStore.userAttempts) {
        await attemptListStore.adaptAttemptsToStore(attempts, true, userId)
      } else if (
        attemptListStore.isAllAttemptsByUserIdFetched(attempts) ||
        attemptListStore.isAttemptBeingProcessed()
      ) {
        await attemptListStore.adaptAttemptsToStore(attempts, false, userId)
      }
      const fetchedAttempts = toJS(attemptListStore.getAttempts(attempts))
      setAttemptsArray(fetchedAttempts)
      setAttemptsLoading(false)
    } else {
      triggerNotification("error", "No saved attempts to view")
    }
  }

  const checkPermissions = async () => {
    try {
      await requestMediaPermissions()
      return true
    } catch (err) {
      const { type, name, message } = err
      if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
        // browser does not have permission to access camera or microphone
        triggerNotification("error", name, message)
        return false
      } else if (type === MediaPermissionsErrorType.UserPermissionDenied) {
        // user didn't allow app to access camera or microphone
        triggerNotification("error", name, message)
        return false
      } else if (type === MediaPermissionsErrorType.CouldNotStartVideoSource) {
        // camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)
        // (mostly Windows specific problem)
        triggerNotification("error", name, message)
        return false
      } else {
        // not all error types are handled by this library
        triggerNotification("error", name, message)
        return false
      }
    }
  }

  const startModule = async () => {
    if (await checkPermissions()) {
      const moduleObj = moduleInfo
      const chatRoomPayload = {
        ...moduleObj,
        assigned,
      }
      navigate("/chatRoomPage", { state: chatRoomPayload })
    }
  }

  const handleDueDateChange = async (targetUserId, newDueDate) => {
    try {
      if (overdueChecker(newDueDate)) {
        triggerNotification("error", "Error changing due date", `Due Date cannot be in the past`)
        return
      }
      await UpdateAssignment(targetUserId, userId, "Module", moduleId, {
        dueDate: newDueDate,
      })
      setAssignments(prevAssignments => {
        const updatedArray = prevAssignments.map(assignment => {
          if (assignment.userId === targetUserId) {
            return { ...assignment, dueDate: newDueDate }
          }
          return assignment
        })
        const newCompletionDist = generateCompletionDistribution(updatedArray, overdueChecker)
        setCompletionDist(newCompletionDist)

        return updatedArray
      })
      triggerNotification("success", "Successfully changed due date", `Successfully modified due date to ${newDueDate}`)
    } catch (err) {
      triggerNotification("error", "Error changing due date", `Please retry changing due date`)
    }
  }

  const fetchTeam = async () => {
    if (!teamStore.teamInfo) {
      await teamStore.getTeam(userId, type, company, userId)
    }
  }

  // Fetch user modules if not already loaded
  const fetchModule = async () => {
    if (!moduleListStoreV2.getModuleById(moduleId)) {
      await moduleListStoreV2.getModule(userStore.user.company, moduleId, userStore.user.userId)
    }
  }

  const fetchAssignments = async () => {
    try {
      const allAssignments = await GetAssignmentsByTarget(moduleId, "Module", userId)
      const filteredAssignments =
        userStore.user.type == "Admin"
          ? allAssignments.data
          : allAssignments.data.filter(a => a.managerId == userStore.user.userId)

      setAssignments(filteredAssignments || [])

      const leaderboard = generateLeaderboardRankings(filteredAssignments)
      setLeaderboardRankings(leaderboard)

      const distribution = generateCompletionDistribution(filteredAssignments, overdueChecker)
      setCompletionDist(distribution)
    } catch (error) {
      triggerNotification("error", "Error fetching assignments", error.message)
    }
  }
  const handleAssign = () => setShowAssignModal(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([fetchTeam(), fetchModule(), fetchAssignments()])
        setAssigned(assignments.find(a => a.userId == userId && a.status != "In Progress"))

        // call assignemnts api, check if curr signed in user has assignment and status != in progress || pending
      } catch (error) {
        console.error("Error fetching data:", error)
      } finally {
        // Ensure page loading is set to false after both fetches or if already populated
        setPageLoading(false)
      }
    }

    fetchData()
  }, [pageLoading])

  const handleBackToTeamViewClick = () => {
    setViewAttempts(false)
  }

  const overdueChecker = dueDate => {
    const stringToDate = new Date(dueDate)
    const epochDueDate = stringToDate.getTime()
    const today = new Date().toDateString()
    const epochToday = new Date(today).getTime()
    return epochToday > epochDueDate
  }

  const handleStartNewAttemptClick = () => {
    setStartAttemtButtonClicked(true)
  }

  const attemptDisclaimerPopUp = startAttemptButtonClicked ? (
    <DoubleOptionPopUp
      header="You are about to jump in the roleplay"
      body={`If you are ready start the module, click start module to be sent to the meeting room\n\n NOTE: The voice you are about to here is AI-generated and not a human voice`}
      leftButtonLabel="Cancel"
      leftButtonFunction={() => setStartAttemtButtonClicked(false)}
      rightButtonFunction={startModule}
      rightButtonLabel="Start module"></DoubleOptionPopUp>
  ) : null

  const sendReminder = async (e, name, email, moduleName, dueDate) => {
    e.stopPropagation()
    if (true) {
      setReminderSent(true)
      try {
        const res = await GenericAPIRequest(
          "ZenoApp",
          "post",
          "/sendReminder",
          {},
          {},
          {
            user_id: userId,
            name,
            email,
            testName: moduleName,
            dueDate,
          },
        )
        triggerNotification("success", "Successfully sent reminder", `Sent reminder to ${name}`)
      } catch (e) {
        console.error("Error updating module:", e)
        triggerNotification("error", "Error sending reminder", `Please retry sending the reminder`)
      }
    } else {
      triggerNotification("error", "Reminder already sent", `Reminder was already sent to ${name}`)
    }
  }

  const closeRubricPopUp = () => setRubricPopUp({ show: false, mode: null })

  const updateRubric = async () => {
    const res = await moduleListStoreV2.updateModule(userStore.user.company, moduleInfo.moduleId, {
      user_id: userId,
      rubric: {
        id: moduleInfo.rubric.id || "",
        fileName: moduleInfo.rubric.fileName || "",
        content: currentRubric.trim(),
      },
    })
    if (res) {
      triggerNotification("success", "Update Successful", "Rubric has been successfully updated.")
    } else {
      triggerNotification("error", "Error updating rubric", "There was an issue updating the rubric. Please try again.")
    }
    closeRubricPopUp()
  }

  const rubricPopUp =
    showRubricPopUp.show && showRubricPopUp.mode === "view" ? (
      <SingleOptionPopUp
        header="Custom Rubric"
        body={moduleInfo.rubric.content}
        buttonLabel="Close"
        buttonFunction={closeRubricPopUp}></SingleOptionPopUp>
    ) : showRubricPopUp.show && showRubricPopUp.mode === "edit" ? (
      <RubricEditPop
        header="Custom Rubric"
        body={moduleInfo.rubric.content}
        leftButtonLabel="Cancel"
        leftButtonFunction={closeRubricPopUp}
        rightButtonLabel="Save"
        rightButtonFunction={updateRubric}
        setCurrentRubric={setCurrentRubric}></RubricEditPop>
    ) : null

  return (
    <>
      {pageLoading ? (
        <ActivityTeamViewPageSkeleton></ActivityTeamViewPageSkeleton>
      ) : (
          <div class="w-full h-full flex flex-col px-8 pt-6">
            <>
              <div className="mb-8">
                {viewAttempts ? (
                  <ModuleAttempts
                    moduleInfo={moduleInfo}
                    attemptedUserId={attemptedUserId}
                    attemptedName={attemptedName}
                    handleBackToTeamViewClick={handleBackToTeamViewClick}
                    attemptsLoading={attemptsLoading}
                    attemptData={attemptData}
                    attemptsArray={attemptsArray}
                  />
                ) : (
                    <div>
                      <ModuleBreadcrumb basePath="/inboxPage" baseLabel="Inbox" currentPage={moduleInfo.displayName} />
                      <ModuleTable
                        moduleName={moduleInfo.displayName}
                        completionDist={completionDist}
                        onTestModuleClick={handleStartNewAttemptClick}
                        assigned={assigned}
                      />
                      <div class="flex flex-col items-start justify-between py-2 sm:flex-row sm:items-center rounded">
                        <div>
                          <Tabs
                            defaultValue={currentTab}
                            className="w-[100%]"
                            onValueChange={str => {
                              setCurrentTab(str)
                              setViewAttempts(false)
                            }}>
                            <TabsList>
                              <TabsTrigger value="Overview">Overview</TabsTrigger>
                              <TabsTrigger value="Assigned Learners">Assigned learners</TabsTrigger>
                              <TabsTrigger value="Leaderboard">Leaderboard</TabsTrigger>
                            </TabsList>
                          </Tabs>
                        </div>
                        <AddButton buttonLabel="Assign Learners" buttonFunction={handleAssign}></AddButton>
                      </div>
                      {currentTab === "Overview" ? (
                        <ModuleDetails moduleInfo={moduleInfo} setRubricPopUp={setRubricPopUp} />
                      ) : currentTab === "Assigned Learners" ? (
                        assignments.length !== 0 ? (
                          <AssignedLearnersTable
                            assignments={assignments}
                            getAttempts={getAttempts}
                            overdueChecker={overdueChecker}
                            handleDueDateChange={handleDueDateChange}
                            sendReminder={sendReminder}
                            testName={moduleInfo.displayName}
                            isAdminConsole={false}
                          />
                        ) : (
                            <EmptyState
                              header="No assigned learners"
                              subHeader="Assign learners to see their attempts"
                              buttonLabel="Assign"
                              buttonFunction={handleAssign}
                              icon="user"></EmptyState>
                          )
                      ) : leaderboardRankings.length === 0 ? (
                        <EmptyState
                          header="No assigned learners"
                          subHeader="Assign learners to see leaderboard activity"
                          buttonLabel="Assign"
                          buttonFunction={handleAssign}
                          icon="user"></EmptyState>
                      ) : (
                              <ModuleLeaderboard leaderboardRankings={leaderboardRankings}></ModuleLeaderboard>
                            )}
                    </div>
                  )}
              </div>
            </>
            {rubricPopUp}
            {showAssignModal && (
              <AssignmentModal
                isOpen={showAssignModal}
                onClose={() => setShowAssignModal(false)}
                teamList={teamList}
                targetId={moduleId}
                targetType={moduleInfo.moduleType}
                targetAdditionalInfo={moduleInfo}
                assigningUserInfo={userStore.user}
                onAssignmentCompleted={fetchAssignments}
              />
            )}
            {attemptDisclaimerPopUp}
          </div>
        )}
    </>
  )
}

export default observer(ActivityTeamViewPage)
