import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';

// Bottom Section Skeleton (75% of viewport)
export const AttemptsSkeleton = () => (
  <div className="w-full">
    <Skeleton height="50vh" />
  </div>
);

export const ActivityTeamViewAttemptsSkeleton = () => (
  <div className="w-full h-[50vh] flex flex-col gap-4">
    <AttemptsSkeleton />
  </div>
);

export default ActivityTeamViewAttemptsSkeleton;