import React, { useRef } from 'react'
import { SpeakerWaveIcon } from '@heroicons/react/24/solid'

const voices = {
  'Sam': '/audioSamples/Ash_tts-1-hd.mp3',
  'Bob': '/audioSamples/Echo_tts-1-hd.mp3',
  'Jennifer': '/audioSamples/Alloy_tts-1-hd.mp3',
  'Bridgette': '/audioSamples/Sage_tts-1-hd.mp3',
  'Margot': '/audioSamples/Coral_tts-1-hd.mp3',
  'Alexa': '/audioSamples/Shimmer_tts-1-hd.mp3'
};

export default function VoiceSamplerButton({ voice }) {
  const selectedVoice = voices[voice]
  const audioRef = useRef(new Audio(selectedVoice));
  audioRef.current.src = selectedVoice;

  const handlePlay = () => {
    audioRef.current.play().catch((error) => {
      console.error('Error playing audio:', error);
    });
  };

  return (
    <div onClick={handlePlay}>
      <SpeakerWaveIcon aria-hidden="true" className="size-6 ml-4 mt-2 cursor-pointer" />
    </div>
  )
}
