import React from 'react'
import AgentTableEntry from "./agentTableEntry"


const AgentTable = () => {

  return (
    <div className="mt-2 flow-root w-full">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300">
            {/* Table Header */}
            <thead className="border-b border-gray-200">
              <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                  Agent Type
              </th>
              </tr>
            </thead>

            {/* Table Body */}
            <tbody className="divide-y divide-gray-200 bg-white">
              <AgentTableEntry></AgentTableEntry>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default AgentTable