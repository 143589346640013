import React from "react"
import { useNavigate } from "react-router-dom"
import { CalendarIcon, ClockIcon, ChatBubbleLeftRightIcon } from '@heroicons/react/20/solid'
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../../@shadcn_components/ui/sheet"

const AttemptThumbnail = ({ attempt, moduleId, attemptedUserId, order }) => {
  let navigate = useNavigate()
  const video = attempt.videoUrl

  let ready = false
  if (attempt.isAnalysisDone) {
    ready = true
  }

  const toRecordingDetails = () => {
    if (ready) {
      navigate(`/moduleAnalysisPage/${moduleId}/${attemptedUserId}/${attempt.attemptId}`)
    }
  }

  const fancyTimeFormat = (duration) => {
    const hrs = ~~(duration / 3600);
    const mins = ~~((duration % 3600) / 60);
    const secs = ~~duration % 60;

    let ret = "";

    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;

    return ret;
  }

  const initials =
    attempt.displayName.split(" ")[0].toUpperCase().charAt(0) +
    attempt.displayName.split(" ")[1].toUpperCase().charAt(0)

  return (ready ? (
    <div className="w-[230px] h-[230px] m-[10px] rounded-[5px] relative box-border border border-gray-150 cursor-pointer hover:border-pareBlue border-[1px]" onClick={toRecordingDetails}>
      <div class="bg-black rounded-tl-[5px] rounded-tr-[5px] overflow-hidden flex-1 aspect-[16/9] relative">
        <video src={video} class="w-full h-full"></video>
        <div class="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 pointer-events-none"></div>
        <div class={`absolute bottom-2 right-2 p-[5px] min-w-[60px] text-center rounded-[5px] text-[9px] font-[50] text-white inline-block ${
          attempt.status === "Pending Review"
            ? "bg-[rgba(255,199,0)]"
            : attempt.status === "Passed"
              ? "bg-[rgba(57,202,34)]"
              : "bg-[rgba(255,94,94)]"
          }`}>{attempt.status}</div>
      </div>
      <div className="absolute left-[5%] top-[60%] flex flex-row items-center justify-center hover:cursor-pointer font-semibold text-gray-800">
        <div className="w-[30px] h-[30px] bg-gray-500 text-white flex justify-center items-center rounded-full mr-2">{initials}</div>
        <h1 class="flex items-center justify-center hover:cursor-pointer font-semibold text-gray-800">{`Attempt #${order}`}</h1>
      </div>
      <div className="flex flex-row justify-between absolute left-[49.5px] top-[75%] w-[131px]">
        <div
          className=" flex items-center justify-center hover:cursor-pointer">
          <CalendarIcon aria-hidden="true" className="h-5 w-4 text-[#adadad] mr-1" />
          <label className="text-[0.625rem] font-normal font-[400] text-[#adadad]">{`${new Date(attempt.attemptTime).toLocaleDateString()}`}</label>
        </div>
        <div
          className="flex items-center justify-center hover:cursor-pointer">
          <ClockIcon aria-hidden="true" className="h-5 w-4 text-[#adadad] mr-1" />
          <label className="text-[0.625rem] font-normal font-[400] text-[#adadad]">{`${fancyTimeFormat(attempt.duration)}`}</label>
        </div>
      </div>
      <div className="absolute left-[49.5px] top-[88%] flex items-center justify-center hover:cursor-pointer">
        <ChatBubbleLeftRightIcon aria-hidden="true" className="h-5 w-4 text-[#adadad] mr-1" />
        {attempt.comments ? (
          <div className="text-[0.625rem] font-normal font-[400] text-[#adadad] flex hover:font-[600]" onClick={e => e.stopPropagation()}>
            <Sheet>
              <SheetTrigger>View Comments</SheetTrigger>
              <SheetContent>
                <SheetHeader>
                  <SheetTitle>Comments</SheetTitle>
                  <SheetDescription>{attempt.comments}</SheetDescription>
                </SheetHeader>
              </SheetContent>
            </Sheet>
          </div>
        ) : (
            <div className="text-[0.625rem] font-normal font-[400] text-[#adadad] flex">No Comments</div>
          )}
      </div>
    </div>
  ) : (
      <div className="w-[230px] h-[230px] m-[10px] rounded-[5px] relative box-border border border-gray-150">
        <div className="bg-gray-500 rounded-tl-[5px] rounded-tr-[5px] overflow-hidden flex-1 aspect-[16/9] relative"></div>
        <div className="absolute left-[15%] top-[75%] flex items-center justify-center hover:cursor-pointer">
          <div class="font-[500] flex justify-center border-transparent text-pareBlue align-center text-[12px]">Analysis in progress...</div>
        </div>
      </div>
    )
  )
}

export default AttemptThumbnail
