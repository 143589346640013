import { ArrowRightCircleIcon } from '@heroicons/react/20/solid'

export default function EnterButton({ buttonLabel, buttonFunction }) {

  return (
    <>
      <button
        type="button"
        className="inline-flex items-center gap-x-2 rounded-md px-2.5 py-1.5 text-sm font-semibold text-gray-900 hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
        onClick={buttonFunction}
      >
        {buttonLabel}
        <ArrowRightCircleIcon aria-hidden="true" className="-mr-0.5 size-5" />
      </button>
    </>
  )
}
