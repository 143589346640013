import { ArrowRightCircleIcon, RectangleStackIcon, FlagIcon, UserCircleIcon, SparklesIcon, WrenchIcon } from '@heroicons/react/20/solid'

export default function EmptyState({
  header,
  subHeader,
  buttonLabel,
  buttonFunction,
  icon,
  buttonIcon,
  removeButton
}) {

  let selectedIcon
  let buttonIconType

  switch (icon) {
    case "learningpath":
      selectedIcon = <RectangleStackIcon aria-hidden="true" className="mx-auto size-12 text-pareBlue" />;
      break;
    case "user":
      selectedIcon = <UserCircleIcon aria-hidden="true" className="mx-auto size-12 text-pareBlue" />;
      break;
    case "agent":
      selectedIcon = <SparklesIcon aria-hidden="true" className="mx-auto size-12 text-pareBlue" />;
      break;
    default:
      selectedIcon = <FlagIcon aria-hidden="true" className="mx-auto size-12 text-pareBlue" />;
      break;
  }

  switch (buttonIcon) {
    case "build":
      buttonIconType = <WrenchIcon aria-hidden="true" className="-mr-0.5 size-5" />
      break;
    default:
      buttonIconType = <ArrowRightCircleIcon aria-hidden="true" className="-mr-0.5 size-5" />
      break;
  }

  return (
    <div className="text-center">
      {selectedIcon}
      <h3 className="mt-2 text-sm font-semibold text-gray-900">{header}</h3>
      <p className="mt-1 text-sm text-gray-500">{subHeader}</p>
      {removeButton ? null
        : <div className="mt-6">
          <button
            type="button"
            className="inline-flex items-center gap-x-2 rounded-md bg-pareBlue px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={buttonFunction}
          >
            {buttonLabel}
            {buttonIconType}
          </button>
        </div>}
    </div>
  )
}
