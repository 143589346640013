import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import 'chart.js/auto';

// Register chart.js components
Chart.register(...registerables);

const usageChart = ({ data }) => {
  // Prepare the data for the chart

  const chartData = {
    labels: Object.values(data).map(item => item.timestamp === 'N/A' ? item.timestamp : `${(new Date(item.timestamp)).toDateString()}`),  // Use the timestamp for the x-axis labels
    datasets: [
      {
        label: 'DAU',  // Label for the dataset
        data: data.map(item => parseInt(item.value)),  // Values for the y-axis
        backgroundColor: 'rgba(48, 69, 255, 0.2)',  // Bar color
        borderColor: 'rgba(48, 69, 255, 1)',  // Bar border color
        borderWidth: 1,  // Bar border width
      },
    ],
  };

  // Chart options
  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return null
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
          stepSize: 1,
          callback: function (value) {
            if (Number.isInteger(value)) {
              return value;
            }
            return null
          },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          usePointStyle: true, // Use dots for legend points
          pointStyle: 'circle',
        },
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
          }
        },
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        titleFont: { size: 16 },
        bodyFont: { size: 14 },
        padding: 10,
        displayColors: false, // Remove the color box in tooltip
      },
    },
    maintainAspectRatio: false,
    responsive: true,
  };

  return (
    <div className="h-full w-full">
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default usageChart;
