import React, { useState, useEffect } from 'react'
import Avatar from "../Global/avatar"
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'

export default function SideBarProfileBanner({ name, email, profilePicture, signOutFunction, navigateToSettings }) {


  return (
    <>
      <Menu as="div" className="relative mt-auto w-full inline-block text-left">
        <div>
          <MenuButton className="flex items-center w-full bg-gray-100 text-gray-400 hover:bg-gray-200" onClick={(event) => event.stopPropagation()}>
            <button type="button" className="w-full line-clamp-1" >
              <a
                className="flex items-center gap-x-2 px-1 py-3 text-sm/6 font-semibold text-gray-900 "
              >
                <Avatar name={name} profilePicture={profilePicture} size="lg" />
                <span className="sr-only">Your profile</span>
                <div className="flex flex-col">
                  <div aria-hidden="true" className="text-[12px] text-gray-700 flex items-end">{name}</div>
                  <div aria-hidden="true" className="text-[10px] font-light text-gray-400 flex items-start justify-start" >{email}</div>
                </div>

              </a>
            </button>
          </MenuButton>
        </div>

        <MenuItems
          transition
          className="absolute bottom-[100%] mb-2 right-0 z-10 mt-2 w-36 origin-bottom-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
        >
          <div className="py-1">
            <MenuItem >
              <div
                onClick={(e) => { e.stopPropagation(); navigateToSettings() }}
                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none cursor-pointer"
              >
                Settings
            </div>
            </MenuItem>
            <MenuItem >
              <a
                href="mailto:hello@pare-ai.com"
                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
              >
                Help
            </a>
            </MenuItem>
            <MenuItem >
              <div
                onClick={(e) => { e.stopPropagation(); signOutFunction() }}
                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none cursor-pointer"
              >
                Sign out
            </div>
            </MenuItem>
          </div>
        </MenuItems>
      </Menu >
    </>
  )
}
