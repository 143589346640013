import React, { useEffect, useState } from "react"
import {
  ControlBar,
  GridLayout,
  LiveKitRoom,
  ParticipantTile,
  RoomAudioRenderer,
  useTracks,
  BarVisualizer,
  useVoiceAssistant,
  useLocalParticipant,
  useRoomContext,
} from "@livekit/components-react"
import { useNavigate, useParams } from "react-router-dom"
import "@livekit/components-styles"
import { Track, RoomEvent } from "livekit-client"
import SingleOptionPopUp from "../../components/PopUps/SingleOptionPopUp"
import { useStores } from "../../models/root-store"
import { quantum } from "ldrs"
import Timer from "../../components/Timer/Timer"
import { GenericAPIRequest } from "../../api/helpers"


quantum.register()
const pareLogo = require("./ChatPageIcons/PareLogo_new.png")

const PlaygroundChatRoomPage = () => {
  const { personaId, instanceId } = useParams()
  const navigate = useNavigate()
  const { userStore } = useStores()
  const { userId, firstName, name, company } = userStore.user || {}
  const [roomConfig, setRoomConfig] = useState({ serverUrl: null, token: null, roomName: null, egressId: null })
  const [displayDisconnectPopUp, setDisplayDisconnectPopUp] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)
  const [hangUpSignaled, setHangUpSiglned] = useState(false)
  const [transcript, setTranscript] = useState([])
  const [timerExceeded, setTimerExceeded] = useState(false)

  useEffect(() => {
    const createRoom = async () => {
      const payload = {
        user_id: userId,
        company,
        firstName: firstName,
        agent_type: "multimodal",
        personaId,
        instanceId
      }
      const LKPayload = await GenericAPIRequest("ZenoApp", "post", "/getPlaygroundLKToken", {}, {}, payload)
      setRoomConfig(LKPayload)
      setPageLoading(false)
    }

    createRoom()
  }, [])


  const signalTimerExceeded = () => {
    setTimerExceeded(true)
  }
  const handleHangUp = () => {
    setHangUpSiglned(true)
  }
  const clearConvoInfo = () => {
    sessionStorage.setItem("simulationReady", "false")
  }
  const handleOnLeave = () => {
    setDisplayDisconnectPopUp(true)
  }


  const dontSave = () => {
    clearConvoInfo()
    navigate("/playground")
  }

  const disconnectPopUp =
    displayDisconnectPopUp && timerExceeded ?
      <SingleOptionPopUp
        header="You ran out of time!"
        body={"You exceeded the max time (30 min)"}
        buttonLabel="Exit Playground"
        buttonFunction={dontSave}></SingleOptionPopUp>
      : displayDisconnectPopUp && hangUpSignaled ?
        <SingleOptionPopUp
          header="The prospect hung up the phone."
          body={"The prospect hung up the phone."}
          buttonLabel="Exit Playground"
          buttonFunction={dontSave}></SingleOptionPopUp>
        : displayDisconnectPopUp ?
          <SingleOptionPopUp
            header="Nice job!"
            body={"Nice job roleplaying, your call has concluded."}
            buttonLabel="Exit Playground"
            buttonFunction={dontSave}></SingleOptionPopUp>
          : null

  return (
    <>
      {pageLoading ? (
        <div class="h-full xl:pl-0 w-full bg-pareBlue flex items-center justify-center">
          <div className="w-[30%] h-[30%] flex flex-col items-center justify-center">
            <p class="text-white semi-bold text-lg leading-7">Generating Conversation</p>
            <div className="QuantumLoaderDiv">
              <l-quantum size="60" speed="1.75" color="white"></l-quantum>
            </div>
          </div>
        </div>
      ) : (
          <LiveKitRoom
            video={true}
            connect={true}
            audio={true}
            token={roomConfig.token}
            serverUrl={roomConfig.serverUrl}
            onDisconnected={handleOnLeave}
            // Use the default LiveKit theme for nice styles.
            data-lk-theme="default"
            style={{ height: "100vh" }}>
            <img src={pareLogo} className="w-3/4 mb-4 md:w-10 rounded-xl mt-4 ml-0 md:ml-4 md:mb-4" alt="logo" />
            <MyVideoConference setTranscript={setTranscript} handleHangUp={handleHangUp} />
            <RoomAudioRenderer />
            <CustomControlBar timeLimit={30} signalTimerExceeded={signalTimerExceeded} />
          </LiveKitRoom>
        )}
      {disconnectPopUp}
    </>
  )
}

function SimpleVoiceAssistant() {
  const { state, audioTrack } = useVoiceAssistant()

  return (
    <>
      <div className="h-full">
        {!audioTrack ? (
          <p className="text-center">{state}</p>
        ) : (
            <BarVisualizer state={state} barCount={7} trackRef={audioTrack} style={{}} />
          )}
      </div>
    </>
  )
}
function MyVideoConference({ setTranscript, handleHangUp }) {
  const { localParticipant } = useLocalParticipant()
  const Room = useRoomContext()
  const [transcriptions, setTranscriptions] = useState({})

  const handleScriptUpdate = utterance => {
    setTranscript(speakerTurns => [...speakerTurns, utterance])
  }

  useEffect(() => {
    const updateTranscriptions = (segments, participant, publication) => {
      setTranscriptions(prev => {
        const newTranscriptions = { ...prev }
        for (const segment of segments) {
          if (segment.final) {
            const utterance = {
              text: segment.text,
              user: null,
              start: new Date(segment.firstReceivedTime).toISOString(),
              end: new Date(segment.lastReceivedTime).toISOString(),
            }
            if (participant ?.isAgent) {
              utterance.user = "bot"
              console.log(segment.text)
              if (segment.text === "Goodbye." || segment.text === "Goodbye!") {
                handleHangUp()
                Room.disconnect()
              }
            } else {
              utterance.user = "user"
            }
            handleScriptUpdate(utterance)
            newTranscriptions[segment.id] = segment
          }
        }
        return newTranscriptions
      })
    }

    const enableMedia = async () => {
      console.log("EnableMedia")
      if (localParticipant) {
        console.log("localParticipant")
        if (Room.state === "connected") {
          await localParticipant.setCameraEnabled(true)
          await localParticipant.setMicrophoneEnabled(true)
        } else {
          console.warn("Room is not connected yet.")
        }
      }
    }

    Room.on(RoomEvent.TranscriptionReceived, updateTranscriptions)

    // if (!localParticipant.isCameraEnabled || !localParticipant.isMicrophoneEnabled) {
    enableMedia()
    // }

    return () => {
      // Disable the camera when the component unmounts
      if (localParticipant) {
        localParticipant.setCameraEnabled(false)
        localParticipant.setMicrophoneEnabled(false)
      }
      Room.off(RoomEvent.TranscriptionReceived, updateTranscriptions)
    }
  }, [Room, localParticipant])

  const tracks = useTracks(
    [
      { source: Track.Source.Camera, withPlaceholder: true },
      // { source: Track.Source.ScreenShare, withPlaceholder: false },
    ],
    { onlySubscribed: false },
  )
  const humanTracks = tracks.filter(x => x.participant.identity == "human")

  return (
    <GridLayout
      tracks={humanTracks}
      style={{
        height: "calc(70vh - var(--lk-control-bar-height))",
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: "10px",
        marginTop: "10vh",
      }}>
      <>
        <ParticipantTile />
        <SimpleVoiceAssistant />
      </>
    </GridLayout>
  )
}

const CustomControlBar = ({ timeLimit, signalTimerExceeded }) => {
  const Room = useRoomContext()
  const endRoom = () => {
    signalTimerExceeded()
    Room.disconnect()
  }
  return (
    <>
      <Timer initialMinute={timeLimit} initialSeconds={0} endRoom={endRoom}></Timer>
      <ControlBar
        controls={{
          leave: true,
          screenShare: false,
          microphone: false,
          camera: false,
        }}></ControlBar>
    </>
  )
}

export default PlaygroundChatRoomPage
