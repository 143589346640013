import { types, flow, SnapshotIn, Instance } from "mobx-state-tree"
import { AddAssignments, GetAssignmentsByManager, GetAssignmentsByTarget, UpdateAssignment } from "../../api/routes" // <-- Adjust to your real routes

/** Transcript line in an attempt */
export const TranscriptModel = types.model("Transcript", {
  start: types.string,
  end: types.string,
  text: types.string,
  user: types.string,
})

/** Single attempt on a module or learning path */
export const AttemptModel = types.model("Attempt", {
  attemptId: types.identifier,
  moduleId: types.string,
  displayName: types.maybeNull(types.string),
  duration: types.number,
  attemptTime: types.number,
  gradingreport: types.string,
  score: types.string,
  status: types.string,
  isAnalysisDone: types.boolean,
  recordingS3Location: types.string,
  comments: types.maybeNull(types.string),
  transcript: types.array(TranscriptModel),
})

export const ProgressModel = types.model("Progress", {
  // A map from moduleId (string) -> status (string)
  moduleStatuses: types.map(types.string),
})

export const AssignmentModel = types.model("Assignment", {
  userId: types.string,
  userEmail: types.string,
  userName: types.string,
  moduleId: types.maybeNull(types.string),
  learningPathId: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  progress: types.maybeNull(ProgressModel),
  attempts: types.optional(types.array(AttemptModel), []),
  learningPathAttempts: types.maybeNull(types.map(types.array(AttemptModel))),
  displayName: types.string,
  status: types.string, // e.g. "Assigned" | "In Progress" | "Completed" ...
  assignedDate: types.number,
  assignedBy: types.string,
  dueDate: types.number,
  type: types.string, // 'Activity' | 'SkillChallenge' | 'LearningPath'
  companyId: types.string,
  managerId: types.string,
})

const AssignmentListModel = types.model("AssignmentListModel", {
  assignmentList: types.array(AssignmentModel),
})

export type AssignmentListSnapshotIn = SnapshotIn<typeof AssignmentListModel>

export const AssignmentStoreModel = types
  .model("AssignmentStore", {
    // All user assignments
    userAssignments: types.maybeNull(AssignmentListModel),
  })
  .actions(self => ({
    /* --------------------------------------
       1) Get all assignments for a target
    -------------------------------------- */
    getAssignmentsByTarget: flow(function* getAssignmentByTarget(
      targetId: string,
      assignmentType: "Module" | "LearningPath",
      userId: string,
    ) {
      try {
        const res = yield GetAssignmentsByTarget(targetId, assignmentType, userId)
        if (res.kind === "ok") {
          const newAssignments = res.data.map((asn: any) => AssignmentModel.create(asn))
          if (!self.userAssignments) {
            self.userAssignments = AssignmentListModel.create({
              assignmentList: newAssignments,
            })
          } else {
            newAssignments.forEach(newAsn => {
              const existing = self.userAssignments.assignmentList.find(
                oldAsn => oldAsn.moduleId === newAsn.moduleId && oldAsn.userId === newAsn.userId,
              )
              if (!existing) {
                self.userAssignments.assignmentList.push(newAsn)
              } else {
                // nothing for now
              }
            })
          }
          return true
        }
      } catch (e) {
        console.error("[getAssignment] Error:", e)
      }
      return false
    }),

    /* --------------------------------------
       2) Get all assignments for a manager
    -------------------------------------- */
    getAssignmentsByManager: flow(function* getAssignmentsByManager(managerId: string, userId: string) {
      try {
        const res = yield GetAssignmentsByManager(managerId, userId)
        if (res.kind === "ok") {
          const newAssignments = res.data.map((asn: any) => AssignmentModel.create(asn))
          if (!self.userAssignments) {
            self.userAssignments = AssignmentListModel.create({
              assignmentList: newAssignments,
            })
          } else {
            newAssignments.forEach(newAsn => {
              const existing = self.userAssignments.assignmentList.find(
                oldAsn => oldAsn.moduleId === newAsn.moduleId && oldAsn.userId === newAsn.userId,
              )
              if (!existing) {
                self.userAssignments.assignmentList.push(newAsn)
              } else {
                // nothing for now
              }
            })
          }
          return true
        }
      } catch (e) {
        console.error("[getAssignments] Error:", e)
      }
      return false
    }),

    /* --------------------------------------
       3) Create a new assignment
    -------------------------------------- */
    createAssignments: flow(function* createAssignments(assignmentData) {
      try {
        const response = yield AddAssignments(assignmentData)
        if (response.kind === "ok") {
          const newAssignments = response.data.map((asn: any) => AssignmentModel.create(asn))
          if (!self.userAssignments) {
            self.userAssignments = AssignmentListModel.create({
              assignmentList: newAssignments,
            })
          } else {
            self.userAssignments.assignmentList.push(...newAssignments)
          }
          return true
        }
      } catch (e) {
        console.error("[createAssignment] Error:", e)
      }
      return false
    }),

    /* --------------------------------------
       4) Delete an assignment TODO implement
    -------------------------------------- */
    // deleteAssignment: flow(function* deleteAssignment(userId: string, assignmentId: string) {
    //   try {
    //     const response = yield DeleteAssignmentAPI(userId, assignmentId);
    //     if (response.kind === "ok") {
    //       // Remove from MST
    //       const idx = self.userAssignments?.assignmentList.findIndex((asn) => {
    //         // Use some ID or combined key
    //         // e.g. if assignmentId is unique
    //         return asn.moduleId === assignmentId || asn.learningPathId === assignmentId;
    //         // or asn.someIdentifier === assignmentId
    //       });
    //       if (idx !== undefined && idx > -1 && self.userAssignments) {
    //         self.userAssignments.assignmentList.splice(idx, 1);
    //       }
    //       return true;
    //     }
    //   } catch (e) {
    //     console.error("[deleteAssignment] Error:", e);
    //   }
    //   return false;
    // }),

    /* --------------------------------------
       5) Update an assignment 
    -------------------------------------- */
    updateAssignment: flow(function* updateAssignment(
      targetUserId: string,
      invokingUserId: string,
      type: "Module" | "LearningPath",
      targetId: string,
      updatedFields: any,
    ) {
      try {
        const response = yield UpdateAssignment(targetUserId, invokingUserId, type, targetId, updatedFields)
        if (response.kind === "ok") {
          const idx = self.userAssignments?.assignmentList.findIndex(asn => {
            return asn.moduleId === targetId || asn.learningPathId === targetId
          })
          if (idx !== undefined && idx > -1 && self.userAssignments) {
            self.userAssignments.assignmentList[idx] = AssignmentModel.create(response.data)
          }
          return true
        }
      } catch (e) {
        console.error("[updateAssignment] Error:", e)
      }
      return false
    }),
  }))
  .views(self => ({
    getAssignmentByModule(moduleId: string) {
      if (!self.userAssignments) return null
      return self.userAssignments.assignmentList.find(asn => asn.moduleId === moduleId) || null
    },

    getAllAssignments() {
      if (!self.userAssignments) return []
      return self.userAssignments.assignmentList
    },

    getAssignmentsByStatus(status: string) {
      if (!self.userAssignments) return []
      return self.userAssignments.assignmentList.filter(asn => asn.status === status)
    },

    getOverdueAssignments() {
      if (!self.userAssignments) return []
      const today = new Date().toDateString()
      const epochToday = new Date(today).getTime()

      return self.userAssignments.assignmentList.filter(asn => {
        return epochToday > asn.dueDate // dueDate is numeric
      })
    },

    getAssignmentsByType(assignmentType: "Activity" | "SkillChallenge" | "LearningPath") {
      if (!self.userAssignments) return []
      return self.userAssignments.assignmentList.filter(asn => asn.type === assignmentType)
    },

    getAllAttemptsForAssignment(moduleOrLpId: string) {
      if (!self.userAssignments) return []
      const asn = self.userAssignments.assignmentList.find(
        x => x.moduleId === moduleOrLpId || x.learningPathId === moduleOrLpId,
      )
      if (!asn) return []

      // If it's a module assignment, just return asn.attempts
      // If it's a learning path, you might need to merge learningPathAttempts
      // or return them based on whichever module is relevant.
      // For simplicity, we demonstrate combining them:
      const moduleAttempts = asn.attempts || []
      if (!asn.learningPathAttempts) return moduleAttempts

      // If there are LP attempts, flatten them
      const lpAttemptsArray = Array.from(asn.learningPathAttempts.values()).flat()
      return [...moduleAttempts, ...lpAttemptsArray]
    },

    getStatusCounts() {
      if (!self.userAssignments) return {}

      return self.userAssignments.assignmentList.reduce((acc, asn) => {
        const st = asn.status
        acc[st] = (acc[st] || 0) + 1
        return acc
      }, {} as Record<string, number>)
    },
  }))

// MST instance type
export type AssignmentStore = Instance<typeof AssignmentStoreModel>
