// teamTable.jsx
import React from "react"
import Avatar from "../Global/avatar"
import GenericDropdown from "../Global/genericDropdown"

const TeamTable = ({
  teamMemberList,
  inviteeList,
  pageLoading,
  openDeletePopUp,
  deleteInvitee,
  resendInvite,
  currentUserName,
}) => {
  return (
    <div className="mt-8 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          {pageLoading ? (
            <div className="flex-1 px-8 flex flex-row content-center justify-center">
              <l-grid size="120" speed="1.4" color="#3045FF" className="mt-50" />
            </div>
          ) : (
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    Name
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Title
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Status
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Last Login
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {teamMemberList.map((teamMember, i) => (
                  <tr key={`${teamMember.user_id}_${i}`}>
                    <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                      <div className="flex items-center">
                        <Avatar name={teamMember.name} profilePicture={teamMember.profilePicture} size="lg" />
                        <div className="ml-4">
                          <div className="font-medium text-gray-900">{teamMember.name}</div>
                          <div className="mt-1 text-gray-500">{teamMember.email}</div>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      {teamMember.type === "Manager" ? (
                        <>
                          <div className="text-gray-900">Sales Rep</div>
                          <div className="mt-1 text-gray-500">{teamMember.type}</div>
                        </>
                      ) : (
                        <div className="text-gray-900">{teamMember.type}</div>
                      )}
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      <span className="inline-flex items-center rounded-md bg-green_light px-2 py-1 text-xs font-medium text-green">
                        Active
                      </span>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      {new Date(teamMember.lastLoginDatetime).toDateString()}
                    </td>
                    <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      {teamMember.name !== currentUserName ? (
                        <GenericDropdown
                          options={[
                            { label: "Delete User", onClick: () => openDeletePopUp(teamMember), isDelete: true },
                          ]}
                        />
                      ) : (
                        <span className="text-gray-400">Cannot delete yourself</span>
                      )}
                    </td>
                  </tr>
                ))}
                {inviteeList.map((invitee, i) => (
                  <tr key={`${invitee.userEmail}_${i}`}>
                    <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                      <div className="flex items-center">
                        <Avatar name={invitee.userEmail} size="lg" />
                        <div className="ml-4">
                          <div className="mt-1 text-gray-500">{invitee.userEmail}</div>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      {invitee.type === "Manager" ? (
                        <>
                          <div className="text-gray-900">Sales Rep</div>
                          <div className="mt-1 text-gray-500">{invitee.type}</div>
                        </>
                      ) : (
                        <div className="text-gray-900">{invitee.type}</div>
                      )}
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      <span className="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-500">
                        Invited
                      </span>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">N/A</td>
                    <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <GenericDropdown
                        options={[
                          { label: "Resend Invite", onClick: () => resendInvite(invitee) },
                          { label: "Revoke Invite", onClick: () => deleteInvitee(invitee), isDelete: true },
                        ]}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  )
}

export default TeamTable
