import React from "react"
import { useNavigate, useLocation, matchPath } from "react-router-dom"
import SideBarItem from "./SideBarItem"
import { useStores } from "../../models/root-store"
import { observer } from "mobx-react-lite"
import SideBarProfileBanner from "./SideBarProfileBanner"

const pareLogo = require("./SideBarIcons/PareLogo_new.png")

function SideBar() {
  const { userStore } = useStores()
  const { type, userEmail, name, profilePicture } = userStore.user || {}
  const navigate = useNavigate()
  const { pathname } = useLocation()

  if (!userStore.user) return null

  const isManagerOrAdmin = type === "Manager" || type === "Admin"

  const signOutFunction = async () => {
    await userStore.logout()
    navigate("/")
  }

  const ROUTE_TO_SIDEBAR_INFO = {
    "/homepage": {
      sideBarPageLabel: locationState => "Home",
    },
    "/settings": {
      sideBarPageLabel: locationState => "Settings",
    },
    "/managerTeam": {
      sideBarPageLabel: locationState => "Team",
    },
    "/managerDev": {
      sideBarPageLabel: locationState => "Development",
    },
    "/activityCreationPage": {
      sideBarPageLabel: locationState => "Development",
    },
    "/moduleAnalysisPage": {
      isManager: userStore.user.type === "Manager" || userStore.user.type === "Admin",
      sideBarPageLabel: locationState => "NOTHING?",
    },
    "/assignment/:moduleId": {
      isManager: userStore.user.type === "Manager" || userStore.user.type === "Admin",
      sideBarPageLabel: locationState => "Development",
    },
    "/assignmentsPage": {
      isManager: userStore.user.type === "Manager" || userStore.user.type === "Admin",
      sideBarPageLabel: locationState => "Development",
    },
    "/playground": {
      sideBarPageLabel: locationState => "Playground",
    },
    "/inboxPage": {
      sideBarPageLabel: locationState => "Inbox",
    },
    "/learningPaths/:learningPathId": {
      sideBarPageLabel: locationState => "learningPaths",
    },
    "/moduleAnalysisPage/:moduleId/:attemptedUserId/:attemptId": {
      sideBarPageLabel: locationState => "moduleAnalysisPage",
    },
  }

  let currPageInfo = null
  let currPageName = ""

  // Iterate through ROUTE_TO_SIDEBAR_INFO to find a matching route
  for (const pathPattern in ROUTE_TO_SIDEBAR_INFO) {
    const match = matchPath({ path: pathPattern, end: true }, pathname)
    if (match) {
      currPageInfo = ROUTE_TO_SIDEBAR_INFO[pathPattern]
      currPageName = currPageInfo.sideBarPageLabel()
      break
    }
  }

  if (currPageInfo == null) {
    return <div></div>
  }

  // Doing this here to avoid nested inline decisions
  const homeSelected = currPageName === "Home"
  const teamSelected = currPageName === "Team"
  const playgroundSelected = currPageName === "Playground"
  const inboxSelected = currPageName === "Inbox"

  return (
    <div className="bg-white border border-gray-300 border-t-transparent h-full flex-none flex flex-col items-center md:items-start w-16 md:w-48">
      <img src={pareLogo} className="w-3/4 mb-4 md:w-10 rounded-xl mt-4 ml-0 md:ml-4 md:mb-4" alt="logo" />
      <div className="w-full">
        <div className="flex flex-col justify-start items-start ml-4 mr-2">
          <SideBarItem
            label="Dashboard"
            icon={"home"}
            onClick={homeSelected ? null : () => navigate("/homepage")}
            isShaded={homeSelected}
          />
          <SideBarItem
            label={isManagerOrAdmin ? "Inbox" : "Assignments"}
            icon={"inbox"}
            onClick={inboxSelected ? null : () => navigate("/inboxPage")}
            isShaded={inboxSelected}
          />
          {isManagerOrAdmin ? (
            <SideBarItem
              label={"Team"}
              icon={"team"}
              onClick={teamSelected ? null : () => navigate("/managerTeam")}
              isShaded={teamSelected}
            />
          ) : (
              <SideBarItem
                label={"Playground"}
                icon={"playground"}
                onClick={playgroundSelected ? null : () => navigate("/playground")}
                isShaded={playgroundSelected}
              />
            )}
        </div>
      </div>
      <SideBarProfileBanner
        name={name}
        email={userEmail}
        profilePicture={profilePicture}
        navigateToSettings={() => navigate("/settings")}
        signOutFunction={signOutFunction}></SideBarProfileBanner>
    </div>
  )
}

export default observer(SideBar)
