import React from "react"
import ActivityTeamViewTableEntry from "./ActivityTeamViewTableEntry"

const AssignedLearnersTable = ({
  assignments,
  getAttempts,
  overdueChecker,
  handleDueDateChange,
  sendReminder,
  isAdminConsole,
}) => {
  return (
    <div className="mt-2 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300">
            {/* Table Header */}
            <thead className="border-b border-gray-200">
              <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                  Name
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Status
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Due Date
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Attempts
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>

            {/* Table Body */}
            <tbody className="divide-y divide-gray-200 bg-white">
              {assignments.map((assignment, i) => (
                <ActivityTeamViewTableEntry
                  key={i}
                  assignment={assignment}
                  getAttempts={getAttempts}
                  overdueChecker={overdueChecker}
                  handleDueDateChange={handleDueDateChange}
                  sendReminder={sendReminder}
                  isAdminConsole={isAdminConsole}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default AssignedLearnersTable
