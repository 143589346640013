import React, { useState, useEffect, useContext } from "react"
import TeamAnalysisTable from "../../components/TeamAnalysisPage/teamAnalysisTable"
import GroupedTeamAnalysisTable from "../../components/TeamAnalysisPage/groupedTeamAnalysisTable"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"
import { toJS } from "mobx"
import { Breadcrumb, BreadcrumbItem, BreadcrumbList } from "../../@shadcn_components/ui/breadcrumb"
import TeamPageSkeleton from "../../components/TeamPage/skeletons"
import { NotificationContext } from "../../contexts/notificationContext"
import { GenericAPIRequest } from "../../api/helpers"
import PareIntelligenceAlert from "../../components/TeamAnalysisPage/pareIntelAlert"


const TeamAnalysisPage = () => {
  const { userStore, teamStore } = useStores()
  const { userId, company, type, name } = userStore.user || {}
  const teamMemberList = toJS(teamStore.teamInfo ?.team) || []
  const inviteeList = toJS(teamStore.teamInfo ?.inviteeList) || []
  const [pageLoading, setPageLoading] = useState(true)

  useEffect(() => {
    const fetchTeam = async () => {
      await teamStore.getTeam(userId, type, company, userId)
      setPageLoading(false)
    }
    // const fetchCompetencyProfile = async () => {
    //   try {
    //     const token = (await Auth.currentSession()).getAccessToken().getJwtToken();

    //     if (isAdminOrManager) {
    //       // Fetch profiles for managers/admins
    //       const profiles = await GenericAPIRequest("DataIntegration",
    //        "get", 
    //        `/competencyProfileDataByManager`, 
    //        {userEmail, user_id:userStore.user.userId}, 
    //        {}, {})
    //       setProfiles(profiles);
    //       const emails = profiles.map(profile => profile.userEmail);
    //       setUserEmails(emails);
    //       setSelectedUserEmail(emails[0]);
    //       setSelectedProfile(profiles[0]);
    //       initializeCharts(profiles[0]);

    //     } else {
    //       // Fetch individual user profile for non-admins/managers
    //       const profile = await GenericAPIRequest("DataIntegration",
    //        "get", 
    //        `/competencyProfileData`, 
    //        {userEmail, user_id:userStore.user.userId}, 
    //        {}, {})
    //       if (profile) {
    //         setSelectedProfile(profile);
    //         setSelectedUserEmail(profile.userEmail);
    //         initializeCharts(profile);
    //       }
    //     }

    //   } catch (error) {
    //     console.error("Error fetching competency profiles:", error);
    //   } finally {
    //     setPageLoading(false)
    //   }
    // }


    if (!teamStore.teamInfo) {
      fetchTeam()
    } else {
      setPageLoading(false)
    }
  }, [])


  if (pageLoading) {
    return <TeamPageSkeleton />
  }

  return (
    <>
      <div className="w-full pt-8">
        <div className="mb-8 px-8">
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>My Team</BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </div>
        <div className="px-4 sm:px-6 lg:px-8 w-full">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">Team Tracking</h1>
              <p className="mt-2 mb-4 text-sm text-gray-700">Ensure your reps are pacing towards goals</p>
            </div>
            <PareIntelligenceAlert></PareIntelligenceAlert>
          </div>

          {type === "Admin" ? (
            <GroupedTeamAnalysisTable
              teamMemberList={teamMemberList}
              pageLoading={pageLoading}
            />
          ) : (
              <TeamAnalysisTable
                teamMemberList={teamMemberList}
                pageLoading={pageLoading}
              />
            )}
        </div>
      </div>
    </>
  )
}
export default observer(TeamAnalysisPage)
