import React from "react"
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react"
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid"

const ActionDropdown = ({ actions }) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      {/* Dropdown Button */}
      <div>
        <MenuButton
          className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none"
          onClick={e => e.stopPropagation()} // Prevents event bubbling
        >
          <span className="sr-only">Open options</span>
          <EllipsisVerticalIcon aria-hidden="true" className="h-5 w-5" />
        </MenuButton>
      </div>

      {/* Dropdown Menu */}
      <Transition
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <MenuItems
          static
          className="absolute right-0 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
          <div className="py-1">
            {actions.map((action, index) => (
              <MenuItem key={index}>
                {({ active }) => (
                  <button
                    type="button"
                    onClick={e => {
                      e.stopPropagation() // Prevents event bubbling
                      action.onClick(e)
                    }}
                    className={`${
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                      } block w-full text-left px-4 py-2 text-sm`}>
                    {action.label}
                  </button>
                )}
              </MenuItem>
            ))}
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  )
}

export default ActionDropdown
