import { DocumentPlusIcon } from '@heroicons/react/20/solid'

export default function PublishButton({ buttonLabel, buttonFunction, functionInProgress, functionInProgressLabel }) {

  if (functionInProgress) {
    return (<>
      <button
        disabled
        type="button"
        className="inline-flex items-center gap-x-2 rounded-md bg-pareBlue px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        {functionInProgressLabel}
        <DocumentPlusIcon aria-hidden="true" className="-mr-0.5 size-5" />
      </button>
    </>)
  }

  return (
    <>
      <button
        type="button"
        className="inline-flex items-center gap-x-2 rounded-md bg-pareBlue px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        onClick={buttonFunction}
      >
        {buttonLabel}
        <DocumentPlusIcon aria-hidden="true" className="-mr-0.5 size-5" />
      </button>
    </>
  )
}
