import React, { useState } from 'react'
import ModuleLibraryPage from "../../../../pages/ModuleLibraryPage/ModuleLibraryPage"

const ModulesSubSection = ({ }) => {

  return (
    <div class="flex flex-1 flex-col items-center justify-center gap-x-8 gap-y-4 py-4 sm:flex-row sm:items-center rounded">
      <ModuleLibraryPage></ModuleLibraryPage>
    </div>
  )
}

export default ModulesSubSection
