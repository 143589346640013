import React, { useState, useEffect, useContext } from "react"
import { Auth, API } from "aws-amplify"
import { useStores } from "../../models/root-store" // Import MobX store
import { observer } from "mobx-react-lite"
import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid"
import { NotificationContext } from "../../contexts/notificationContext"

const CreateEditLearningPathModal = observer(({ onClose, learningPath, onSave }) => {
  const isEditMode = Boolean(learningPath)
  const { moduleListStoreV2, learningPathStore, userStore } = useStores()
  const availableModules = moduleListStoreV2.userModules ?.moduleList || []

  const [formState, setFormState] = useState({
    displayName: learningPath ?.displayName || "",
    description: learningPath ?.description || "",
    moduleIds: learningPath ?.moduleIds || [],
  })

  const [saving, setSaving] = useState(false)
  const [selectedModules, setSelectedModules] = useState([])
  const [searchQuery, setSearchQuery] = useState("")
  const [filteredModules, setFilteredModules] = useState([])
  const { triggerNotification } = useContext(NotificationContext)

  // Fetch modules if not already loaded
  useEffect(() => {
    if (!moduleListStoreV2.userModules) {
      moduleListStoreV2.getModules(userStore.user.company, userStore.user.userId)
    } else {
      if (isEditMode && learningPath ?.moduleIds) {
        // Find the modules that are part of the learning path
        const modulesInPath = availableModules.filter(module => learningPath.moduleIds.includes(module.moduleId))
        setSelectedModules(modulesInPath)
      }
    }
  }, [
      moduleListStoreV2.userModules,
      isEditMode,
      learningPath,
      availableModules,
      moduleListStoreV2,
      userStore.user.type,
      userStore.user.company,
      userStore.user.userId,
    ])

  useEffect(() => {
    const filtered = availableModules.filter(module => {
      const displayName = module.displayName || ""
      return displayName.toLowerCase().includes(searchQuery.toLowerCase())
    })
    setFilteredModules(filtered)
  }, [searchQuery, availableModules])

  const handleChange = e => {
    setFormState(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const handleSave = async () => {
    try {
      if (selectedModules.length === 0) {
        triggerNotification(
          "error",
          "Please add modules to create a module",
        )
        return false
      }
      setSaving(true)
      const companyId = userStore.user.company
      let response
      if (isEditMode) {
        // Update existing learning path
        response = await learningPathStore.updateLearningPath(userStore.user.company, learningPath.learningPathId, {
          ...formState,
          moduleIds: selectedModules.map(module => module.moduleId),
          companyId,
          createdBy: userStore.user.name,
          user_id: userStore.user.userId,
        })
      } else {
        // Create new learning path
        response = await learningPathStore.createLearningPath({
          ...formState,
          moduleIds: selectedModules.map(module => module.moduleId),
          companyId,
          createdBy: userStore.user.name,
          user_id: userStore.user.userId,
        })
      }
      onSave(response)
      if (response) {
        triggerNotification(
          "success",
          isEditMode ? "Learning Path Updated" : "Learning Path Created",
          `The learning path "${formState.displayName}" has been successfully ${isEditMode ? "updated" : "created"}.`,
        )
      } else {
        triggerNotification("error", "Error", "An error occurred while saving the learning path.")
      }
    } catch (error) {
      console.error("Error saving learning path:", error)
      triggerNotification("error", "Error", "An error occurred while saving the learning path.")
    } finally {
      setSaving(false)
    }
  }

  // Handle module selection
  const handleSelectModule = module => {
    if (!selectedModules.find(m => m.moduleId === module.moduleId)) {
      setSelectedModules(prevModules => [...prevModules, module])
    }
  }

  // Handle module removal
  const handleRemoveModule = moduleId => {
    setSelectedModules(prevModules => prevModules.filter(module => module.moduleId !== moduleId))
  }

  // Sorting selected modules based on the order they were added
  const sortedSelectedModules = selectedModules

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded p-6 w-9/12 max-w-5xl overflow-y-auto max-h-screen">
        <h2 className="text-xl font-semibold mb-4">{isEditMode ? "Edit Learning Path" : "Create Learning Path"}</h2>
        {/* Modal Content */}
        <div className="grid grid-cols-2 gap-6">
          {/* Left Side */}
          <div className="col-span-1">
            {/* Name and Description */}
            <div className="mb-4">
              <label className="block text-gray-700">Display Name</label>
              <input
                type="text"
                name="displayName"
                className="w-full px-3 py-2 border rounded"
                value={formState.displayName}
                onChange={handleChange}
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Description</label>
              <textarea
                name="description"
                className="w-full px-3 py-2 border rounded"
                value={formState.description}
                onChange={handleChange}
              />
            </div>
            {/* Search Bar */}
            <div className="mb-4">
              <h3 className="text-lg font-medium text-gray-900 mb-2">Available Modules</h3>
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search modules..."
                  className="w-full px-3 py-2 border rounded pl-10"
                  value={searchQuery}
                  onChange={e => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
            {/* Module Selection List */}
            <div className="overflow-y-auto max-h-96">
              <ModuleSelectionList
                modules={filteredModules}
                onSelectModule={handleSelectModule}
                selectedModules={selectedModules}
              />
            </div>
          </div>
          {/* Right Side */}
          <div className="col-span-1 flex flex-col h-full">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Learning Path Modules</h3>
            <div className="flex-1 overflow-y-auto">
              {sortedSelectedModules.length > 0 ? (
                <Feed modules={sortedSelectedModules} onRemoveModule={handleRemoveModule} />
              ) : (
                  <p className="text-sm text-gray-500">No modules added to the learning path.</p>
                )}
            </div>
          </div>
        </div>
        {/* Actions */}
        <div className="mt-6 flex justify-end">
          <button className="px-4 py-2 mr-2 text-gray-700" onClick={onClose} disabled={saving}>
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            onClick={handleSave}
            disabled={saving}>
            {saving ? "Saving..." : "Save"}
          </button>
        </div>
      </div>
    </div>
  )
})

// Feed component
const Feed = ({ modules, onRemoveModule }) => {
  return (
    <div className="flow-root">
      <ul role="list" className="-mb-8">
        {modules.map((module, idx) => (
          <li key={module.moduleId}>
            <div className="relative pb-8">
              {idx !== modules.length - 1 ? (
                <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" />
              ) : null}
              <div className="relative flex space-x-3">
                <div>
                  <span className="flex h-8 w-8 items-center justify-center rounded-full bg-blue-500 ring-8 ring-white">
                    <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                  </span>
                </div>
                <div className="min-w-0 flex-1 pt-1.5 flex justify-between">
                  <div>
                    <p className="text-sm font-medium text-gray-900">{module.displayName}</p>
                    <p className="text-sm text-gray-500">{module.description}</p>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="text-red-600 hover:text-red-800"
                      onClick={() => onRemoveModule(module.moduleId)}>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

// ModuleSelectionList component
const ModuleSelectionList = ({ modules, onSelectModule, selectedModules }) => {
  return (
    <div>
      {modules.length > 0 ? (
        <ul className="space-y-2">
          {modules.map(module => {
            const isSelected = selectedModules.some(m => m.moduleId === module.moduleId)
            return (
              <li key={module.moduleId}>
                <button
                  type="button"
                  className={`w-full text-left px-4 py-2 border rounded ${
                    isSelected ? "bg-gray-200 cursor-not-allowed" : "hover:bg-gray-100"
                    }`}
                  onClick={() => !isSelected && onSelectModule(module)}
                  disabled={isSelected}>
                  <p className="text-sm font-medium text-gray-900">{module.displayName}</p>
                  <p className="text-sm text-gray-500">{module.description}</p>
                </button>
              </li>
            )
          })}
        </ul>
      ) : (
          <p className="text-sm text-gray-500">No modules found.</p>
        )}
    </div>
  )
}

export default CreateEditLearningPathModal
