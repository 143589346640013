import { Assignment } from "@/src/api/nonStoreTypes"

export function generateLeaderboardRankings(assignments: Assignment[]) {
  return assignments.map(assignment => {
    const attempts = assignment.attempts || []
    const numericScores = attempts
      .map(attempt => parseFloat(attempt.score) || 0)
      .filter(score => score !== Number.MIN_SAFE_INTEGER)
    const attemptsNum = attempts.length

    // Average score
    const overallScore = attemptsNum > 0 ? numericScores.reduce((acc, curr) => acc + curr, 0) / attemptsNum : 0

    // Highest score
    const highestScore = numericScores.reduce((acc, curr) => (curr > acc ? curr : acc), 0)

    return {
      name: assignment.userName,
      email: assignment.userEmail,
      attemptsNum,
      overallScore,
      highestScore,
    }
  })
}

export function generateCompletionDistribution(assignments: Assignment[], overdueChecker) {
  return assignments.reduce(
    (acc, assignment) => {
      // If there's a non-empty status, treat it as "Assigned"
      // (or invert logic depending on your actual rules)
      const currentStatus = assignment.status ?? "Assigned"
      if (currentStatus === "Assigned") {
        if (overdueChecker(assignment.dueDate)) {
          acc["Overdue"] += 1
        } else {
          acc["Not Started"] += 1
        }
      } else if (currentStatus === "Completed") {
        acc["Passed"] += 1
      } else if (currentStatus === "Not Passed" || currentStatus === "In Progress") {
        acc["In Progress"] += 1
      } else {
        acc["Pending"] += 1
      }
      return acc
    },
    {
      "Not Started": 0,
      Passed: 0,
      Pending: 0,
      "In Progress": 0,
      Overdue: 0,
    },
  )
}
