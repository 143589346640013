import { CheckIcon, XMarkIcon, ClockIcon, PlayIcon, LockClosedIcon } from "@heroicons/react/20/solid"
import { useNavigate } from "react-router-dom"

// Status icons and background colors
const ProgressIcons = {
  Assigned: <LockClosedIcon className="h-5 w-5 text-white" aria-hidden="true" />,
  "In Progress": <PlayIcon className="h-5 w-5 text-white" aria-hidden="true" />,
  "Not Passed": <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />,
  "Pending Review": <ClockIcon className="h-5 w-5 text-white" aria-hidden="true" />,
  Completed: <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />,
}

const getStatusBgColor = status => {
  switch (status) {
    case "Completed":
      return "bg-green-500 ring-8 ring-white"
    case "In Progress":
      return "bg-blue-500 ring-8 ring-white"
    case "Not Passed":
      return "bg-red-500 ring-8 ring-white"
    case "Pending Review":
      return "bg-yellow-500 ring-8 ring-white"
    default:
      return "bg-gray-400 ring-8 ring-white" // Assigned
  }
}

const ModalFeed = ({ modules }) => {
  const navigate = useNavigate()

  const handleNavigation = module => {
    if (module.status === "Assigned" || module.status === "In Progress") return
    navigate(`/assignment/${module.moduleId}`)
  }

  return (
    <div className="flow-root">
      <ul role="list" className="-mb-8">
        {modules.map((module, idx) => (
          <li key={module.moduleId}>
            <div className="relative pb-8">
              {/* Vertical Line */}
              {idx !== modules.length - 1 && (
                <span className="absolute left-7 top-12 -ml-px h-[55%] w-0.5 bg-gray-200" />
              )}

              {/* Module Entry */}
              <div
                className={`relative flex space-x-3 rounded-md p-3 cursor-pointer ${
                  module.status === "Assigned" || module.status === "In Progress"
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-gray-100"
                  }`}
                onClick={() => handleNavigation(module)}>
                {/* Status Icon */}
                <div>
                  <span
                    className={`flex h-8 w-8 items-center justify-center rounded-full ${getStatusBgColor(
                      module.status,
                    )}`}>
                    {ProgressIcons[module.status]}
                  </span>
                </div>

                {/* Module Content */}
                <div className="min-w-0 flex-1 pt-1.5 flex justify-between">
                  <p className="text-sm font-medium text-gray-900">{module.displayName}</p>
                  <p className="text-sm text-gray-500">{module.status}</p>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ModalFeed
