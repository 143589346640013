import React, { useState } from 'react'
import Tabs from "../../Global/tabs"
import ModulesSubSection from "./contentSubSections/modulesSubSection"
import PersonasSubSection from "./contentSubSections/personasSubSection"
import { useStores } from "../../../models/root-store"
import ActivityCreationPage from "../../../pages/ActivityCreationPage/ActivityCreationPage"
import ModuleLibraryPage from "../../../pages/ModuleLibraryPage/ModuleLibraryPage"
import ModuleDetail from "../ModuleDetail"
import LearningPathDetail from "../LearningPathDetail"
import LearningPaths from "../../AssignmentsPage/learningPaths"




const ContentFoldersSection = ({ }) => {

  const { userStore } = useStores()
  const { userId, company } = userStore.user || {}
  const tabNames = [
    { name: "Modules", key: "Modules" },
    { name: "Learning Paths", key: "Learning Paths" },
    { name: "Personas", key: "Personas" },
  ]
  const [currentContentSubSection, setCurrentContentSubSection] = useState("Modules")
  const [moduleId, setModuleId] = useState(null)
  const [learningPathId, setLearningPathId] = useState(null)

  const renderReportingSection = () => {

    const tabs = (
      <div class="flex flex-col items-start justify-between gap-x-8 gap-y-4 sm:flex-row sm:items-center rounded">
        <Tabs tabNames={tabNames} selectedTab={currentContentSubSection} setSelectedTab={setCurrentContentSubSection} ></Tabs>
      </div>)

    switch (currentContentSubSection) {
      case 'Modules':
        return (<>{tabs}
          <div class="flex flex-1 flex-col items-center justify-center gap-x-8 gap-y-4 py-4 sm:flex-row sm:items-center rounded">
            <ModuleLibraryPage setCurrentContentSubSection={setCurrentContentSubSection} setModuleId={setModuleId}></ModuleLibraryPage>
          </div></>)
      case 'ModuleCreation':
        return <ActivityCreationPage setCurrentContentSubSection={setCurrentContentSubSection} ></ActivityCreationPage>
      case 'ModuleDetail':
        return <ModuleDetail setCurrentContentSubSection={setCurrentContentSubSection} moduleId={moduleId} />
      case 'Learning Paths':
        return (<>{tabs}
          <div class="flex flex-1 flex-col items-center justify-center gap-x-8 gap-y-4 py-4 sm:flex-row sm:items-center rounded">
            <LearningPaths setCurrentContentSubSection={setCurrentContentSubSection} setLearningPathId={setLearningPathId}></LearningPaths>
          </div></>)
      case 'LearningPathDetail':
        return <LearningPathDetail setCurrentContentSubSection={setCurrentContentSubSection} learningPathId={learningPathId} setModuleId={setModuleId} />
      case 'Personas':
        return (<>{tabs}<PersonasSubSection></PersonasSubSection></>)
      default:
        return (<>{tabs}<ModulesSubSection userInfo={{ userId, company }} setCurrentContentSubSection={setCurrentContentSubSection}></ModulesSubSection></>)
    }
  }

  return (
    <div className=" max-w-7xl px-4 flex-1">
      {renderReportingSection()}
    </div>
  )
}

export default ContentFoldersSection
