'use client'

import { useState } from 'react'
import AdminConsoleSidebar from "../../components/AdminConsole/adminConsoleSidebar"
import AgentsSection from "../../components/AdminConsole/SettingsTab/agentsSection"
import UsersSection from "../../components/AdminConsole/SettingsTab/usersSection"
import AppsSection from "../../components/AdminConsole/SettingsTab/appsSection"
import OrganizationSection from "../../components/AdminConsole/SettingsTab/organizationSection"

import { useStores } from "../../models/root-store"


export default function AdminConsoleSettingsPage() {

  const { userStore } = useStores()
  const { userId, name, company, profPicSignedUrl } = userStore.user || {}
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [currentSection, setCurrentSection] = useState('Folders')

  const renderSettingsSection = () => {

    switch (currentSection) {
      case 'Agents':
        return <AgentsSection></AgentsSection>;
      case 'Users':
        return <UsersSection></UsersSection>;
      case 'Organization':
        return <OrganizationSection userInfo={{ userId, company }}></OrganizationSection>
      case 'Apps':
        return <AppsSection></AppsSection>
      default:
        return <AgentsSection></AgentsSection>;
    }
  }


  return (
    <>
      <div className="w-full">
        <AdminConsoleSidebar
          setCurrentSection={setCurrentSection}
          currentTab={"Settings"}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}></AdminConsoleSidebar>

        <div className="w-full">
          <main className="py-4 lg:pl-72 sm:pl-0 flex flex-1">
            {renderSettingsSection()}
          </main>
        </div>
      </div>
    </>
  )
}
