import React, { useState } from 'react'
import { LockClosedIcon } from '@heroicons/react/20/solid'


const AttributesSection = ({ }) => {

  return (
    <div className=" max-w-7xl px-4 flex-1">
      <div class="flex flex-col items-start justify-between gap-x-8 gap-y-4 py-4 sm:flex-row sm:items-center rounded">
        <div>
          <div class="flex items-center gap-x-3">
            <h1 class="flex gap-x-3 text-lg leading-7">
              <span class="font-semibold text-grey">Attribution</span>
            </h1>
          </div>
          <p class="mt-2 text-xs leading-6 text-gray-400">
            This is where we map competencies to
          </p>
        </div>
      </div>
      <div class="flex flex-1 flex-col relative top-20 items-center justify-center gap-x-8 gap-y-4 py-4 sm:flex-row sm:items-center rounded">
        <div className="text-center">
          <LockClosedIcon aria-hidden="true" className="mx-auto size-12 text-pareBlue" />
          <h3 className="mt-2 text-sm font-semibold text-gray-900">Attributions coming soon!</h3>
        </div>
      </div>
    </div>
  )
}

export default AttributesSection
