'use client'

import { useState } from 'react'
import AdminConsoleSidebar from "../../components/AdminConsole/adminConsoleSidebar"
import UsageSection from "../../components/AdminConsole/ReportingTab/usageSection"
import RecommendationsSection from "../../components/AdminConsole/ReportingTab/recommendationsSection"
import AttributesSection from "../../components/AdminConsole/ReportingTab/attributesSection"
import { useStores } from "../../models/root-store"


export default function AdminConsoleReportingPage() {

  const { userStore } = useStores()
  const { userId, name, company, profPicSignedUrl } = userStore.user || {}
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [currentSection, setCurrentSection] = useState('Folders')

  const renderReportingSection = () => {

    switch (currentSection) {
      case 'Usage':
        return <UsageSection userInfo={{ userId, company }}></UsageSection>;
      case 'Attributions':
        return <AttributesSection></AttributesSection>;
      case 'Recommended Competencies':
        return <RecommendationsSection></RecommendationsSection>
      default:
        return <UsageSection userInfo={{ userId, company }}></UsageSection>;
    }
  }

  return (
    <>
      <div className="w-full">
        <AdminConsoleSidebar
          setCurrentSection={setCurrentSection}
          currentTab={"Reporting"}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}></AdminConsoleSidebar>

        <div className="w-full">
          <main className="py-4 lg:pl-72 sm:pl-0 flex flex-1">
            {renderReportingSection()}
          </main>
        </div>
      </div>
    </>
  )
}
