import React, { useState } from "react"

export default function IntegrationCard({ name, icon, description, detailedDescription, isConnected, onDisconnect }) {
  const [showDetails, setShowDetails] = useState(false)

  return (
    <div
      className={`overflow-hidden rounded-lg shadow w-full min-w-[250px] transform transition duration-200 hover:scale-105 focus:scale-105 border-2 ${
        isConnected ? "border-green-500" : "border-gray-200"
      }`}
      tabIndex="0">
      <div className="flex flex-col h-full">
        <div className="relative px-4 py-5 sm:p-6 bg-white text-gray-600 flex flex-col items-start justify-start flex-1">
          <div className="flex items-center justify-center h-12 w-12 rounded-full bg-white mb-4">
            <img src={icon} alt={`${name} logo`} className="h-12 w-12" loading="lazy" />
          </div>
          <h3 className="text-lg font-semibold">{name}</h3>
          <p className="mt-2 text-sm text-start">{description}</p>

          {/* Learn More Button and Detailed Description */}
          <button
            onClick={() => setShowDetails(!showDetails)}
            className="text-sm text-pareBlue mt-2 focus:outline-none">
            {showDetails ? "Hide Details" : "Learn More"}
          </button>
          {showDetails && <p className="text-sm mt-2">{detailedDescription}</p>}

          {/* Connected Badge */}
          {isConnected && (
            <span className="absolute top-2 right-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
              Connected
            </span>
          )}
        </div>

        {/* Action Buttons */}
        <div className="mt-auto px-4 py-4 flex justify-start space-x-4">
          {isConnected && (
            <button
              onClick={onDisconnect}
              className="inline-flex justify-center rounded-md px-4 py-2 text-sm font-semibold bg-red_light text-white hover:bg-red_dark focus:outline-none">
              Disconnect
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
