import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid"
import { useNavigate } from "react-router-dom"

// Feed component with hover effect and inline comments
const Feed = ({ modules, isAdminConsole, setCurrentContentSubSection, setModuleId }) => {
  let navigate = useNavigate()
  const handleModuleClick = module => {
    if (!isAdminConsole) {
      navigate(`/assignment/${module.moduleId}`)
    } else {
      setModuleId(module.moduleId)
      setCurrentContentSubSection("ModuleDetail")
    }
  }
  return (
    <div className="flow-root">
      {/* Container for the list of modules */}
      <ul role="list" className="-mb-8">
        {modules.map((module, idx) => (
          <li key={module.moduleId}>
            <div className="relative pb-8">
              {/* Render a vertical line connecting list items, except the last one */}
              {idx !== modules.length - 1 ? (
                <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" />
              ) : null}
              <div
                className="relative flex space-x-3 cursor-pointer hover:bg-gray-100 rounded-md"
                onClick={() => handleModuleClick(module)}>
                {/* Icon indicating completion */}
                <div>
                  <span className="flex h-8 w-8 items-center justify-center rounded-full bg-blue-500 ring-8 ring-white">
                    <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                  </span>
                </div>
                {/* Text content for the module, including name and description */}
                <div className="min-w-0 flex-1 pt-1.5 flex justify-between">
                  <div>
                    <p className="text-sm font-medium text-gray-900">{module.displayName}</p>
                    <p className="text-sm text-gray-500">{module.description}</p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Feed
