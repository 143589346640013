import React from "react"
import ActivityTeamViewPage from "../ActivityTeamViewPage/ActivityTeamViewPage"
import AssignmentLandingPage from "../AssignmentLandingPage/AssignmentLandingPage"
import { useStores } from "../../models/root-store"

const AssignmentDetailPage = () => {
  const { userStore } = useStores()
  const { type } = userStore.user || {}
  const isAdminOrManager = type === "Admin" || type === "Manager"

  return isAdminOrManager ? <ActivityTeamViewPage /> : <AssignmentLandingPage />
}

export default AssignmentDetailPage