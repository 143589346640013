import React, { useContext, useState } from "react"
import { XMarkIcon } from '@heroicons/react/24/solid'
import { NotificationContext } from "../../contexts/notificationContext"
import { GenericAPIRequest } from "../../api/helpers"
import { v4 as uuid } from "uuid"
import { useStores } from "../../models/root-store"

export default function PersonaCreationPopUp({ setShowCreationPopUp, userId, company, createdByName }) {

  const { triggerNotification } = useContext(NotificationContext)
  const { personaStore } = useStores()
  const [saving, setSaving] = useState(false)
  const [personaInfo, setPersonaInfo] = useState({
    voice: "Sam",
    displayName: "",
    personality: "",
    background: "",
    additonalInfo: ""
  })
  const voiceOptions = {
    Sam: "Male 1",
    Bob: "Male 2",
    Jennifer: "Female 1",
    Bridgette: "Female 2",
    Margot: "Female 3",
    Alexa: "Female 4",
  }

  const validateForm = () => {
    let valid = true
    const { displayName, role, personality, background } = personaInfo
    if (displayName.trim().length < 2) {
      triggerNotification("error", "Invalid persona name", "Persona name must be longer than 1 character")
      valid = false
    }
    else if (role.trim().length < 1) {
      triggerNotification("error", "Persona role must not be empty")
      valid = false
    }
    else if (personality.trim().length < 1) {
      triggerNotification("error", "Persona personality must not be empty")
      valid = false
    }
    else if (background.trim().length < 1) {
      triggerNotification("error", "Persona background must not be empty")
      valid = false
    }

    return valid
  }

  const createPersona = async () => {
    const formStatus = validateForm()
    if (formStatus) {
      setSaving(true)
      const createdPersona = {
        ...personaInfo,
        personaId: uuid(),
        companyId: company,
        user_id: userId,
        createdById: userId,
        createdByName,
        creationDate: Date.now(),
      }

      const res = await personaStore.createPersona(createdPersona)
      if (res){
        triggerNotification("success", "Success", "Successfuly created persona")
      }else{
        triggerNotification("error", "Error when creating persona", "Please retry")
      }
      setSaving(false)
      setShowCreationPopUp(false)
    }
  }

  const handlePersonaNameChange = (e) => {
    setPersonaInfo({
      ...personaInfo,
      displayName: e.target.value
    })
  }
  const handlePersonaRoleChange = (e) => {
    setPersonaInfo({
      ...personaInfo,
      role: e.target.value
    })
  }
  const handlePersonaPersonalityChange = (e) => {
    setPersonaInfo({
      ...personaInfo,
      personality: e.target.value
    })
  }
  const handlePersonaBackgroundChange = (e) => {
    setPersonaInfo({
      ...personaInfo,
      background: e.target.value
    })
  }
  const handlePersonaVoiceChange = (e) => {
    setPersonaInfo({
      ...personaInfo,
      voice: e.target.value
    })
  }
  const handlePersonaAdditionalInfoChange = (e) => {
    setPersonaInfo({
      ...personaInfo,
      additonalInfo: e.target.value
    })
  }


  return (
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">

      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-[95vh] items-end justify-center p-4 text-center sm:items-center sm:p-0 py-4">
          <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all">

            <form>
              <div className="space-y-12 sm:space-y-16">
                <div>
                  <div className="flex justify-between w-full">
                    <div>
                      <h2 className="text-base/7 font-semibold text-gray-900">Persona Information</h2>
                      <p className="mt-1 max-w-2xl text-sm/6 text-gray-600">Fill out the persona information below to describe who the character is</p>
                    </div>
                    <button
                      type="button"
                      onClick={() => setShowCreationPopUp(false)}
                      className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-pareBlue"
                    >
                      <XMarkIcon aria-hidden="true" className="size-6" />
                    </button>
                  </div>
                  <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                      <label htmlFor="first-name" className="block text-sm/6 font-medium text-gray-900 sm:pt-1.5">
                        Persona name
                      </label>
                      <div className="mt-2 sm:col-span-2 sm:mt-0">
                        <input
                          type="text"
                          onChange={handlePersonaNameChange}
                          placeholder="Enter the persona name..."
                          className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:max-w-xs sm:text-sm/6"
                        />
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                      <label htmlFor="street-address" className="block text-sm/6 font-medium text-gray-900 sm:pt-1.5">
                        Persona role
              </label>
                      <div className="mt-2 sm:col-span-2 sm:mt-0">
                        <input
                          type="text"
                          onChange={handlePersonaRoleChange}
                          placeholder="Enter the persona's title..."
                          className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:max-w-xl sm:text-sm/6"
                        />
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                      <label htmlFor="email" className="block text-sm/6 font-medium text-gray-900 sm:pt-1.5">
                        Persona personality
                      </label>
                      <div className="mt-2 sm:col-span-2 sm:mt-0">
                        <PersonaCreationTextArea
                          placeholder={"Enter any information about the persona's personality..."}
                          onChange={handlePersonaPersonalityChange}></PersonaCreationTextArea>
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                      <label htmlFor="email" className="block text-sm/6 font-medium text-gray-900 sm:pt-1.5">
                        Persona background
                      </label>
                      <div className="mt-2 sm:col-span-2 sm:mt-0">
                        <PersonaCreationTextArea
                          placeholder={"Enter any information regarding the personas background whether it be pain points, competitors, etc..."}
                          onChange={handlePersonaBackgroundChange}></PersonaCreationTextArea>
                      </div>
                    </div>

                    {/* <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                      <label htmlFor="country" className="block text-sm/6 font-medium text-gray-900 sm:pt-1.5">
                        Persona pain points

                      </label>
                      <div className="mt-2 sm:col-span-2 sm:mt-0">
                        <div className="grid grid-cols-1 sm:max-w-xs">
                          <select
                            id="country"
                            name="country"
                            autoComplete="country-name"
                            className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                          >
                            <option>United States</option>
                            <option>Canada</option>
                            <option>Mexico</option>
                          </select>
                        </div>
                      </div>
                    </div> */}

                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                      <label htmlFor="country" className="block text-sm/6 font-medium text-gray-900 sm:pt-1.5">
                        Persona voice

              </label>
                      <div className="mt-2 sm:col-span-2 sm:mt-0">
                        <div className="grid grid-cols-1 sm:max-w-xs">
                          <select
                            id="voices"
                            name="voices"
                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                            onChange={handlePersonaVoiceChange}>
                            {Object.keys(voiceOptions).map((x, i) => (
                              <option key={i} value={x}>
                                {voiceOptions[x]}
                              </option>
                            ))}
                          </select>

                        </div>
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                      <label htmlFor="email" className="block text-sm/6 font-medium text-gray-900 sm:pt-1.5">
                        Any additional persona information
              </label>
                      <div className="mt-2 sm:col-span-2 sm:mt-0">
                        <PersonaCreationTextArea
                          placeholder={"Add any other relevant information to enhance the persona..."}
                          onChange={handlePersonaAdditionalInfoChange}></PersonaCreationTextArea>
                      </div>
                    </div>

                  </div>
                </div>

                <div>
                </div>
              </div>

              <div className="mt-6 flex items-center justify-end gap-x-6">
                <button type="button" className="text-sm/6 font-semibold text-gray-900" onClick={() => setShowCreationPopUp(false)}>
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={saving ? null : createPersona}
                  className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  {saving ? "Saving..." : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

const PersonaCreationTextArea = ({ placeholder, onChange }) => {

  return (
    <div>
      <div class="mt-2">
        <textarea
          rows="4"
          placeholder={placeholder}
          onChange={onChange}
          class="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"></textarea>
      </div>
    </div>
  )
} 
