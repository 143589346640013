import PersonaLibraryPage from "../../../../pages/PersonaLibraryPage/PersonaLibraryPage"

const PersonasSubSection = ({ }) => {

  return (
    <div class="flex flex-1 flex-col items-center justify-center gap-x-8 gap-y-4 py-4 sm:flex-row sm:items-center rounded">
      <PersonaLibraryPage></PersonaLibraryPage>
    </div>

  )
}
export default PersonasSubSection
