import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import AssignmentsPage from "../AssignmentsPage/AssignmentsPage"
import LearningPaths from "../../components/AssignmentsPage/learningPaths"
import ModuleLibraryPage from "../ModuleLibraryPage/ModuleLibraryPage"
import LearningPathsEmptyPage from "../../components/LearningPath/tempUserComp"
import Inbox from "../Inbox"
import { useStores } from "../../models/root-store"

const tabs = [
  { name: "Module Library", key: "moduleLibrary" },
  { name: "Learning Paths", key: "learningPaths" },
  { name: "Inbox", key: "inbox" },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const LearningAndModulesPage = () => {
  const { userStore } = useStores()
  const [currentTab, setCurrentTab] = useState("moduleLibrary")

  const userType = userStore.user.type

  const renderModuleLibrary = () => {
    if (userType === "Admin" || userType == "Manager") {
      return <ModuleLibraryPage />
    } else {
      return <AssignmentsPage />
    }
  }

  const renderLearningPaths = () => {
    if (userType === "Admin" || userType === "Manager") {
      return <LearningPaths />
    } else {
      return <LearningPathsEmptyPage setCurrentTab={setCurrentTab} />
    }
  }

  return (
    <div className="px-6 w-full">
      <header className="border-b border-gray-200 pb-5 sm:pb-0 mt-10">
        <h3 className="text-base font-semibold leading-6 text-gray-900">Learning & Modules</h3>
        <div className="mt-3 sm:mt-4">
          {/* Mobile dropdown */}
          <div className="sm:hidden">
            <label htmlFor="current-tab" className="sr-only">
              Select a tab
            </label>
            <select
              id="current-tab"
              name="current-tab"
              defaultValue={tabs.find(tab => tab.key === currentTab).name}
              onChange={e => setCurrentTab(tabs.find(tab => tab.name === e.target.value).key)}
              className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
              {tabs.map(tab => (
                <option key={tab.name}>{tab.name}</option>
              ))}
            </select>
          </div>

          {/* Desktop tabs */}
          <div className="hidden sm:block">
            <nav className="-mb-px flex space-x-8">
              {tabs.map(item => (
                <button
                  key={item.name}
                  onClick={() => setCurrentTab(item.key)}
                  aria-current={currentTab === item.key ? "page" : undefined}
                  className={classNames(
                    currentTab === item.key
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium",
                  )}>
                  {item.name}
                </button>
              ))}
            </nav>
          </div>
        </div>
      </header>

      {/* Content area */}
      <div className="py-5">
        {currentTab === "moduleLibrary" && renderModuleLibrary()}
        {currentTab === "learningPaths" && renderLearningPaths()}
        {currentTab === "inbox" && <Inbox />}
      </div>
    </div>
  )
}

export default observer(LearningAndModulesPage)
