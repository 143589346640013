import React, { useState, useEffect, useContext } from "react"
import { useParams } from "react-router-dom"
import { NotificationContext } from "../contexts/notificationContext"
import ManagerDevPageSkeleton from "../components/ManagerDevPage/skeletons"
import GenericHeader from "../components/LearningPath/genericHeader"
import ModuleBreadcrumb from "../components/ActivityTeamViewPage/moduleBreadcrumb"
import CreateEditLearningPathModal from "../components/AssignmentsPage/learningPathModal"
import ConfirmDeleteModal from "../components/Global/confirmDeleteModal"
import CardDescription from "../components/Global/cardDescription"
import Feed from "../components/LearningPath/feed"
import AssignmentModal from "../components/LearningPath/assignmentModal"
import AssignmentTable from "../components/LearningPath/assignmentTable"
import ProgressionModal from "../components/LearningPath/progressionModal"
import { GetAssignmentsByTarget } from "../api/routes"
import { useStores } from "../models/root-store"
import { observer } from "mobx-react-lite"
import { toJS } from "mobx"
import { RectangleStackIcon } from "@heroicons/react/20/solid"

const LearningPathDetailPage = observer(() => {
  const { learningPathId } = useParams()
  const { userStore, teamStore, moduleListStoreV2, learningPathStore } = useStores()
  const { triggerNotification } = useContext(NotificationContext)

  const learningPath = toJS(learningPathStore.getLearningPathById(learningPathId)) || {}
  const availableModules = toJS(moduleListStoreV2.userModules ?.moduleList) || []
  const teamList = toJS(teamStore.teamInfo ?.team) || []

  // Derived state for modules
  const selectedModules = availableModules.filter(module => learningPath.moduleIds ?.includes(module.moduleId))

  const [assignments, setAssignments] = useState([])
  const [loading, setLoading] = useState(true)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showAssignModal, setShowAssignModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [learningPathToDelete, setLearningPathToDelete] = useState(null)
  const [showProgressionModal, setShowProgressionModal] = useState(false)
  const [selectedAssignment, setSelectedAssignment] = useState(null)

  const learningPathDetails = [
    { label: "Created By", value: learningPath.createdBy },
    { label: "Created On", value: learningPath.creationDate, isDate: true },
    { label: "Modules", value: learningPath.moduleIds ?.length ?? 0 },
    { label: "Description", value: learningPath.description },
  ]

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        // Fetch the learning path if not already loaded
        if (!learningPathStore.getLearningPathById(learningPathId)) {
          await learningPathStore.getLearningPath(userStore.user.company, learningPathId, userStore.user.userId)
        }

        // Fetch the team if not already loaded
        if (!teamStore.teamInfo) {
          await teamStore.getTeam(
            userStore.user.userId,
            userStore.user.type,
            userStore.user.company,
            userStore.user.userId,
          )
        }

        // Fetch user modules if not already loaded
        if (!moduleListStoreV2.userModules) {
          await moduleListStoreV2.getModules(userStore.user.company, userStore.user.userId)
        }

        const allAssignments = await GetAssignmentsByTarget(learningPathId, "LearningPath", userStore.user.userId)
        const filteredAssignments =
          userStore.user.type == "Admin"
            ? allAssignments.data
            : allAssignments.data.filter(a => a.managerId == userStore.user.userId)

        setAssignments(filteredAssignments || [])
      } catch (error) {
        console.error("Error fetching data:", error)
        triggerNotification("Error", "Failed to fetch learning path info.")
      } finally {
        setLoading(false)
      }
    }

    fetchAllData()
  }, [learningPathId, learningPathStore, teamStore, moduleListStoreV2, userStore, triggerNotification])

  const confirmDelete = async () => {
    const learningPathId = learningPathToDelete.learningPathId
    setShowDeleteModal(false)
    const success = await learningPathStore.deleteLearningPath(
      userStore.user.company,
      learningPathId,
      userStore.user.userId,
    )
    if (success) {
      triggerNotification("Delete Successful", "Learning path deleted successfully.")
    } else {
      triggerNotification("Error", "An error occurred while deleting the learning path.")
    }
    setLearningPathToDelete(null)
  }

  const handleAssign = () => setShowAssignModal(true)
  const handleEdit = () => setShowEditModal(true)
  const handleDelete = () => {
    setLearningPathToDelete(learningPath)
    setShowDeleteModal(true)
  }
  const handleRowClick = assignment => {
    setSelectedAssignment(assignment)
    setShowProgressionModal(true)
  }
  const closeDeleteModal = () => {
    setShowDeleteModal(false)
    setLearningPathToDelete(null)
  }

  if (loading) {
    return <ManagerDevPageSkeleton />
  }

  return (
    <div className="p-6 w-full">
      <ModuleBreadcrumb basePath="/inboxPage" baseLabel="Learning Paths" currentPage={learningPath.displayName} />
      {/* Header Section */}
      <GenericHeader
        title={learningPath.displayName || "Untitled Learning Path"}
        subtitle={`Learning Path ID: ${learningPathId}`}
        logoSrc={RectangleStackIcon}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onAssign={handleAssign}
      />

      {/* Learning Path info card */}
      <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          <div className="lg:col-start-3 lg:row-end-1">
            <CardDescription
              title="Learning Path Details"
              subtitle="Key information about this learning path"
              details={learningPathDetails}
            />
          </div>

          {/* Assignments */}
          <div className="shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg lg:col-span-2 lg:row-span-2 lg:row-end-2 ">
            <h3 className="text-base font-semibold text-gray-900 mt-4 ml-4 mb-1">Assigned Learners</h3>
            <dt className="ml-4 max-w-2xl text-sm text-gray-500">Select a user to view detailed completion status</dt>
            <AssignmentTable
              assignmentsList={assignments}
              moduleCount={learningPath.moduleIds ?.length ?? 0}
              onRowClick={handleRowClick}
            />
          </div>

          {/* Module Feed */}
          <div className="lg:col-start-3 ">
            <div className="overflow-hidden bg-white shadow sm:rounded-lg">
              <div className="border-t border-gray-100"></div>
              <h3 className="text-base font-semibold text-gray-900 mt-4 ml-4 mb-1">Learning Path Modules</h3>
              <dt className="ml-4 max-w-2xl text-sm text-gray-500">Select a module to view and edit its content</dt>
              <div className="flex-1 m-4">
                {selectedModules.length > 0 ? (
                  <Feed modules={selectedModules} onClickModule={() => console.log("Module clicked")} />
                ) : (
                    <p className="text-sm text-gray-500">No modules added to the learning path.</p>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showEditModal && (
        <CreateEditLearningPathModal
          onClose={() => setShowEditModal(false)}
          learningPath={learningPath}
          onSave={() => setShowEditModal(false)}
        />
      )}

      {showAssignModal && (
        <AssignmentModal
          isOpen={showAssignModal}
          onClose={() => setShowAssignModal(false)}
          teamList={teamList}
          targetId={learningPathId}
          targetType="LearningPath"
          targetAdditionalInfo={learningPath}
          assigningUserInfo={userStore.user}
        />
      )}

      {showDeleteModal && learningPathToDelete && (
        <ConfirmDeleteModal
          isOpen={showDeleteModal}
          onClose={closeDeleteModal}
          onConfirm={confirmDelete}
          title="Delete Learning Path"
          message={`Are you sure you want to delete the learning path "${learningPathToDelete.displayName}"? This action cannot be undone.`}
        />
      )}

      {showProgressionModal && selectedAssignment && (
        <ProgressionModal
          isOpen={showProgressionModal}
          onClose={() => setShowProgressionModal(false)}
          assignment={selectedAssignment}
          modules={selectedModules}
        />
      )}
    </div>
  )
})

export default LearningPathDetailPage
