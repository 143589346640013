import React, { useState, useEffect, useContext } from "react"
import EmptyState from "../../components/Global/emptyState"
import ActivityBox from "../../components/ActivityBox/ActivityBox"
import WarningPopUp from "../../components/PopUps/WarningPopUp"
import SingleInputPopUp from "../../components/PopUps/SingleInputPopUp"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"
import { toJS } from "mobx"
import ManagerDevPageSkeleton from "../../components/ManagerDevPage/skeletons"
import { ArrowPathIcon } from "@heroicons/react/20/solid"
import { NotificationContext } from "../../contexts/notificationContext"
import { FlagIcon } from "@heroicons/react/20/solid"
import CreateButton from "../../components/Buttons/createButton"



const ModuleLibraryPage = ({ setCurrentContentSubSection, setModuleId }) => {
  const { triggerNotification } = useContext(NotificationContext)
  const { moduleListStoreV2, userStore } = useStores()
  const moduleList = toJS(moduleListStoreV2.userModules ?.moduleList) || []
  const [pageLoading, setPageLoading] = useState(true)
  const [searchBarInput, setSearchBarInput] = useState("")
  const [deletePopUpMenu, setDeletePopUpMenu] = useState(false)
  const [renamePopUpMenu, setRenamePopUpMenu] = useState(false)
  const [renameInput, setRenameInput] = useState("")
  const [loading, setLoading] = useState(false)
  const [popUpMenuDetails, setPopUpMenuDetails] = useState(null)
  const moduleNameList = useState(moduleList.map(x => x.displayName.toLowerCase()))

  const refresh = async () => {
    setPageLoading(true)
    await moduleListStoreV2.getModules(userStore.user.company, userStore.user.userId)
    setPageLoading(false)
  }

  useEffect(() => {
    const fetchModules = async () => {
      if (!moduleListStoreV2.userModules ?.moduleList || moduleListStoreV2.checkForInProgressModules()) {
        await moduleListStoreV2.getModules(userStore.user.company, userStore.user.userId)
      }
      setPageLoading(false)
    }

    fetchModules()
  }, [])

  const handleSearchBarChange = e => {
    e.preventDefault()
    setSearchBarInput(e.target.value)
  }

  const openMenuPopUp = convoObj => {
    setPopUpMenuDetails(convoObj)
    if (convoObj.popUpType === "delete") {
      setDeletePopUpMenu(true)
    } else if (convoObj.popUpType === "rename") {
      setRenamePopUpMenu(true)
    }
  }
  const closeMenuPopUp = () => {
    if (popUpMenuDetails.popUpType === "delete") {
      setDeletePopUpMenu(false)
    } else if (popUpMenuDetails.popUpType === "rename") {
      setRenamePopUpMenu(false)
    }
    setPopUpMenuDetails(null)
  }

  const handleRenameInputChange = event => {
    setRenameInput(event.target.value)
  }

  const handleTrainingClick = (training) => {
    if (training.isCreationDone) {
      setModuleId(training.moduleId)
      setCurrentContentSubSection("ModuleDetail")
    } else {
      triggerNotification("error", "Module is being created!", "Please wait while the module is being generated")
    }
  }

  const renameModule = async () => {
    if (renameInput.trim() === "") {
      triggerNotification("error", "Error renaming module", `Rename the conversation to a valid name`)
    } else if (moduleNameList.includes(renameInput.toLowerCase().trim())) {
      triggerNotification("error", "Error renaming module", `Module name already exists`)
    } else {
      const res = await moduleListStoreV2.updateModule(userStore.user.company, popUpMenuDetails.moduleId, {
        displayName: renameInput.trim(),
        user_id: userStore.user.userId,
      })
      if (res) {
        triggerNotification("sucesss", "Module Update successful!", `The module name was successfully updated!`)
      } else {
        triggerNotification("error", "Module Update Error", res.error.message)
      }
      setLoading(false)
      closeMenuPopUp()
      setRenameInput("")
    }
  }
  const deleteModule = async () => {
    const res = await moduleListStoreV2.deleteModule(
      userStore.user.company,
      popUpMenuDetails.moduleId,
      userStore.user.userId,
    )
    if (res) {
      triggerNotification(
        "success",
        "Successully deleted module!",
        `Successfully deleted module: ${popUpMenuDetails.displayName}`,
      )
    } else {
      triggerNotification("error", "Module Delete Error", res.error.message)
    }
    setLoading(false)
    closeMenuPopUp()
  }

  const handleCreateNewModuleClick = () => {
    setCurrentContentSubSection('ModuleCreation')
  }

  const RenamePopUpMenuDiv = renamePopUpMenu ? (
    <div className={`frostedBackground is-visible`}>
      <SingleInputPopUp
        header={`Rename "${popUpMenuDetails.displayName}"`}
        body={`Enter new assignment title to change name`}
        leftButtonFunction={closeMenuPopUp}
        leftButtonLabel="Cancel"
        rightButtonFunction={loading ? null : renameModule}
        rightButtonLabel={loading ? "Renaming..." : "Rename"}
        handleInputChange={handleRenameInputChange}></SingleInputPopUp>
    </div>
  ) : null

  const DeletePopUpMenuDiv = deletePopUpMenu ? (
    <div className={`frostedBackground is-visible`}>
      <WarningPopUp
        header={`Delete "${popUpMenuDetails.displayName}" ?`}
        body={`Are you sure you want to delete this module?`}
        leftButtonLabel="Cancel"
        leftButtonFunction={closeMenuPopUp}
        rightButtonFunction={loading ? null : deleteModule}
        rightButtonLabel={loading ? "Deleting..." : "Delete"}></WarningPopUp>
    </div>
  ) : null

  if (pageLoading) {
    return <ManagerDevPageSkeleton />
  }

  return (
    <div class="w-full h-full flex flex-col">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <div class="flex flex-row">
            <FlagIcon aria-hidden="true" className="h-5 w-4 text-pareBlue mr-1 mt-1" />
            <h1 class="font-semibold text-lg text-grey">Module Library</h1>
          </div>
          <p class="mt-1 text-xs leading-6 text-gray-400">View, edit, and create role play modules</p>
        </div>
        <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <CreateButton buttonLabel="Create Module" buttonFunction={handleCreateNewModuleClick}></CreateButton>
        </div>
      </div>
      <div class="content-center align-items flex gap-5">
        <input
          type="text"
          placeholder="Search modules..."
          className="mb-8 mt-4 rounded border-gray-300 w-[500px] h-[35px] text-sm"
          value={searchBarInput}
          onChange={handleSearchBarChange}></input>
        <button class="w-7 h-7 bg-coverborder-none cursor-pointer hover:opacity-80 mb-7 mt-5" onClick={refresh}>
          <ArrowPathIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
        </button>
      </div>
      {moduleList.length == 0 ? (
        <div className="w-full h-[60%] mt-20 flex flex-col items-center justify-center">
          <EmptyState
            header="No Existing Modules"
            subHeader="Build, design, and publish a module for your team when you’re ready"
            buttonLabel="Create module"
            buttonFunction={handleCreateNewModuleClick}
            icon="icon"></EmptyState>
        </div>
      ) : (
          <div className="w-full h-full rounded-md shadow-sm flex flex-row flex-wrap">
            {searchBarInput.length > 0
              ? moduleList
                .filter(x => x.displayName.match(new RegExp(`^${searchBarInput}.*`, "i")))
                .map((x, i) => (
                  <div key={`${x.moduleId}`}
                    onClick={() => handleTrainingClick(x)}>
                    <ActivityBox
                      moduleInfo={x}
                      handleMenuPopUp={openMenuPopUp}></ActivityBox>
                  </div>
                ))
              : moduleList.map((x, i) => (
                <div key={`${x.moduleId}`}
                  onClick={() => handleTrainingClick(x)}>
                  <ActivityBox
                    moduleInfo={x}
                    handleMenuPopUp={openMenuPopUp}></ActivityBox>
                </div>
              ))}
          </div>
        )}
      {RenamePopUpMenuDiv}
      {DeletePopUpMenuDiv}
    </div>
  )
}

export default observer(ModuleLibraryPage)
