import React, { useState } from "react"
import Avatar from "../Global/avatar"
import AnalysisDrawer from "./analysisDrawer"

const TeamAnalysisTable = ({ teamMemberList, pageLoading }) => {
  const [analysisDrawerOpen, setAnalysisDrawerOpen] = useState(false)
  const [analysisDrawerData, setAnalysisDrawerData] = useState({})

  const handleAnalysisDrawerOpen = (name) => {
    setAnalysisDrawerOpen(true)
    setAnalysisDrawerData({ name })
  }

  return (
    <div className="mt-8 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          {pageLoading ? (
            <div className="flex-1 px-8 flex flex-row content-center justify-center">
              <l-grid size="120" speed="1.4" color="#3045FF" className="mt-50" />
            </div>
          ) : (
              <>
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                        Name
                </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Group
                </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        TPR
                </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Efficiency Index
                </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Competency 1
                </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Competency 2
                </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Competency 3
                </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Competency 4
                </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {teamMemberList.map((teamMember, i) => (
                      <tr key={`${teamMember.user_id}_${i}`}
                        onClick={() => handleAnalysisDrawerOpen(teamMember.name)}>
                        <td className="whitespace-nowrap flex items-center py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                          <Avatar name={teamMember.name} profilePicture={teamMember.profilePicture} size="lg" />
                          <div className="ml-4">
                            <div>{teamMember.name}</div>
                            <div className="mt-1 text-gray-500">{teamMember.email}</div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">N/A</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          N/A
                      </td>
                        <td className="whitespace-nowrap text-sm text-gray-500">
                          {/* <MiniGraph
                          data={null}
                          userData={null}
                          label={"RVI"}
                          fieldKey={"value"}
                        /> */}
                          N/A
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          N/A
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          N/A
                      </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          N/A
                      </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          N/A
                      </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <AnalysisDrawer
                  open={analysisDrawerOpen}
                  setOpen={setAnalysisDrawerOpen}
                  analysisDrawerData={analysisDrawerData}></AnalysisDrawer>
              </>
            )}
        </div>
      </div>
    </div>
  )
}

export default TeamAnalysisTable
