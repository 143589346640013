import React from "react"
import { PencilSquareIcon, SparklesIcon, TrashIcon } from "@heroicons/react/20/solid"

const AgentTableEntry = ({ i }) => {

  return (
    <tr key={`123_${i}`} className="flex flex-row justify-between">
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0 hover:">
        <div className="flex items-center">
          <SparklesIcon aria-hidden="true" className="h-5 w-5 text-pareBlue mr-1" />
          <div className="ml-4">
            <div className="font-medium text-gray-900">{"SDRs"}</div>
            <div className="mt-1 text-gray-500">{"4 Reps"}</div>
          </div>
        </div>
      </td>
      <td className="flex flex-row py-5 pr-4 text-sm font-medium">
        <PencilSquareIcon aria-hidden="true" className="h-6 w-6 text-pareBlue mr-4 cursor-pointer" />
        <TrashIcon aria-hidden="true" className="h-6 w-6 text-pareBlue mr-1 cursor-pointer" />
      </td>
    </tr>
  )
}

export default AgentTableEntry