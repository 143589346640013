import React from "react"
import { UserIcon } from '@heroicons/react/20/solid'

const PersonaInstanceBox = ({ personaInstanceInfo, setOpenPersonaPreview, setSelectedInstanceId }) => {

  const handlePersonaBoxViewClick = () => {
    setSelectedInstanceId(personaInstanceInfo.instanceId)
    setOpenPersonaPreview(true)
  }

  return (
    <li
      key={personaInstanceInfo.personaId}
      className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow max-w-[200px]"
    >
      <div className="flex flex-1 flex-col p-2">
        {/* <img alt="" src={person.imageUrl} className="mx-auto size-32 shrink-0 rounded-full" /> */}
        <UserIcon className="mx-auto size-32 text-pareBlue shrink-0 rounded-full"></UserIcon>
        <h3 className="mt-6 text-sm font-medium text-gray-900 line-clamp-1">{personaInstanceInfo.displayName}</h3>
        <dl className="mt-1 flex grow flex-col justify-between">
          <dd className="text-sm text-gray-500 line-clamp-1">{personaInstanceInfo.role}</dd>
          <dd className="mt-3 gap-x-2 w-full flex flex-row justify-center">
            <span className={`inline-flex items-center rounded-full bg-blue-50 px-2 py-1 text-xs font-medium text-pareBlue ring-1 ring-inset ring-blue-600/20`}>
              {personaInstanceInfo.difficulty}
            </span>
            <span className="inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-600/20 line-clamp-1">
              {personaInstanceInfo.callType}
            </span>
          </dd>
        </dl>
      </div>
      <div>
        <div className="-mt-px flex divide-x divide-gray-200">
          <div className="-ml-px flex w-0 flex-1 cursor-pointer" onClick={handlePersonaBoxViewClick}>
            <a
              className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900 hover:text-pareBlue"
            >
              {/* <PhoneIcon aria-hidden="true" className="size-5 text-gray-400" /> */}
              View
            </a>
          </div>
        </div>
      </div>
    </li>

  )
}

export default PersonaInstanceBox
